export const REPORT_CONSOLIDATED_REQUEST = 'REPORT_CONSOLIDATED_REQUEST';
export const REPORT_CONSOLIDATED_REQUEST_SUCCESS = 'REPORT_CONSOLIDATED_REQUEST_SUCCESS';
export const REPORT_CONSOLIDATED_REQUEST_FAILURE = 'REPORT_CONSOLIDATED_REQUEST_FAILURE';
export const CLEAR_REPORT_CONSOLIDATED_DATA = 'CLEAR_REPORT_CONSOLIDATED_DATA';

// Report Jurisdiction Analytic 
export const REPORT_JURISDICTION_ANALYTIC_REQUEST = 'REPORT_JURISDICTION_ANALYTIC_REQUEST';
export const REPORT_JURISDICTION_ANALYTIC_REQUEST_SUCCESS = 'REPORT_JURISDICTION_ANALYTIC_REQUEST_SUCCESS';
export const REPORT_JURISDICTION_ANALYTIC_REQUEST_FAILURE = 'REPORT_JURISDICTION_ANALYTIC_REQUEST_FAILURE';
export const CLEAR_REPORT_JURISDICTION_ANALYTIC_DATA = 'CLEAR_REPORT_JURISDICTION_ANALYTIC_DATA';

// Report Organism Analytic 
export const REPORT_ORGANISM_ANALYTIC_REQUEST = 'REPORT_ORGANISM_ANALYTIC_REQUEST';
export const REPORT_ORGANISM_ANALYTIC_REQUEST_SUCCESS = 'REPORT_ORGANISM_ANALYTIC_REQUEST_SUCCESS';
export const REPORT_ORGANISM_ANALYTIC_REQUEST_FAILURE = 'REPORT_ORGANISM_ANALYTIC_REQUEST_FAILURE';
export const CLEAR_REPORT_ORGANISM_ANALYTIC_DATA = 'CLEAR_REPORT_ORGANISM_ANALYTIC_DATA';

// Report Jurisdiction Total 
export const REPORT_JURISDICTION_TOTAL_REQUEST = 'REPORT_JURISDICTION_TOTAL_REQUEST';
export const REPORT_JURISDICTION_TOTAL_REQUEST_SUCCESS = 'REPORT_JURISDICTION_TOTAL_REQUEST_SUCCESS';
export const REPORT_JURISDICTION_TOTAL_REQUEST_FAILURE = 'REPORT_JURISDICTION_TOTAL_REQUEST_FAILURE';
export const CLEAR_REPORT_JURISDICTION_TOTAL_DATA = 'CLEAR_REPORT_JURISDICTION_TOTAL_DATA';

// Report Organization Total
export const REPORT_ORGANIZATION_TOTAL_REQUEST = 'REPORT_ORGANIZATION_TOTAL_REQUEST';
export const REPORT_ORGANIZATION_TOTAL_REQUEST_SUCCESS = 'REPORT_ORGANIZATION_TOTAL_REQUEST_SUCCESS';
export const REPORT_ORGANIZATION_TOTAL_REQUEST_FAILURE = 'REPORT_ORGANIZATION_TOTAL_REQUEST_FAILURE';
export const CLEAR_REPORT_ORGANIZATION_TOTAL_DATA = 'CLEAR_REPORT_ORGANIZATION_TOTAL_DATA';

// Report Crossed
export const REPORT_CROSSED_REQUEST = 'REPORT_CROSSED_REQUEST';
export const REPORT_CROSSED_REQUEST_SUCCESS = 'REPORT_CROSSED_REQUEST_SUCCESS';
export const REPORT_CROSSED_REQUEST_FAILURE = 'REPORT_CROSSED_REQUEST_FAILURE';
export const CLEAR_REPORT_CROSSED_DATA = 'CLEAR_REPORT_CROSSED_DATA';

// Report Instrument Legal Modify
export const REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST = 'REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST';
export const REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST_SUCCESS = 'REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST_SUCCESS';
export const REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST_FAILURE = 'REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST_FAILURE';
export const CLEAR_REPORT_INSTRUMENT_LEGAL_MODIFY_DATA = 'CLEAR_REPORT_INSTRUMENT_LEGAL_MODIFY_DATA';

// Report Instrument Legal Modify Direct download
export const REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST = 'REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST';
export const REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST_SUCCESS = 'REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST_SUCCESS';
export const REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST_FAILURE = 'REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST_FAILURE';

// Report all affectations by administrative document 
export const REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST = 'REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST';
export const REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS = 'REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS';
export const REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE = 'REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE';
export const CLEAR_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT = 'CLEAR_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT';

// Report all affectations by administrative document 
export const REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST = 'REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST';
export const REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST_SUCCESS = 'REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST_SUCCESS';
export const REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST_FAILURE = 'REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST_FAILURE';

// Report movement report - Report Credit Execution 
export const REPORT_MOVEMENT_REQUEST = 'REPORT_MOVEMENT_REQUEST';
export const REPORT_MOVEMENT_REQUEST_SUCCESS = 'REPORT_MOVEMENT_REQUEST_SUCCESS';
export const REPORT_MOVEMENT_REQUEST_FAILURE = 'REPORT_MOVEMENT_REQUEST_FAILURE';
export const CLEAR_REPORT_MOVEMENT = 'CLEAR_REPORT_MOVEMENT';

// Report Credit Modify - Report Credit Execution 
export const REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST = 'REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST';
export const REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST_SUCCESS = 'REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST_SUCCESS';
export const REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST_FAILURE = 'REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST_FAILURE';
export const CLEAR_REPORT_CREDIT_MODIFY_CREDIT_EXECUTION = 'CLEAR_REPORT_CREDIT_MODIFY_CREDIT_EXECUTION';

// Report Table Totals - Report Credit Execution 
export const REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST = 'REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST';
export const REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST_SUCCESS = 'REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST_SUCCESS';
export const REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST_FAILURE = 'REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST_FAILURE';
export const CLEAR_REPORT_TABLE_TOTALS_CREDIT_EXECUTION = 'CLEAR_REPORT_TABLE_TOTALS_CREDIT_EXECUTION';

// Report Budget status - Report Credit Execution 
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST = 'REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST';
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST_SUCCESS = 'REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST_SUCCESS';
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST_FAILURE = 'REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST_FAILURE';
export const CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION = 'CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION';

// Report Works Budget Analytical Record - Report Credit Execution 
export const REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST = 'REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST';
export const  REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST_SUCCESS = ' REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST_SUCCESS';
export const  REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST_FAILURE = ' REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST_FAILURE';
export const CLEAR_REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION = 'CLEAR_REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION';

// Report Movements Status - Report Credit Execution  
export const REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST = 'REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST';
export const REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST_SUCCESS = 'REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST_SUCCESS';
export const REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST_FAILURE = 'REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST_FAILURE';
export const CLEAR_REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION = 'CLEAR_REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION';

// Report Delayed Files - Report Credit Execution  
export const REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST = 'REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST';
export const REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST_SUCCESS = 'REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST_SUCCESS';
export const REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST_FAILURE = 'REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST_FAILURE';
export const CLEAR_REPORT_DELAYED_FILES_CREDIT_EXECUTION = 'CLEAR_REPORT_DELAYED_FILES_CREDIT_EXECUTION';


// Report balance status payment list - Report Credit Execution  
export const REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST = 'REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST';
export const REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST_SUCCESS = 'REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST_SUCCESS';
export const REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST_FAILURE = 'REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST_FAILURE';
export const CLEAR_REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION = 'CLEAR_REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION';

// Report Budget Analytical Accounting - Report Credit Execution 
export const REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST = 'REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST';
export const  REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST_SUCCESS = ' REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST_SUCCESS';
export const  REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST_FAILURE = ' REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST_FAILURE';
export const CLEAR_REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION = 'CLEAR_REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION';

// Report Balance Status 
export const REPORT_BALANCE_STATUS_REQUEST = 'REPORT_BALANCE_STATUS_REQUEST';
export const REPORT_BALANCE_STATUS_REQUEST_SUCCESS = 'REPORT_BALANCE_STATUS_REQUEST_SUCCESS';
export const REPORT_BALANCE_STATUS_REQUEST_FAILURE = 'REPORT_BALANCE_STATUS_REQUEST_FAILURE';
export const CLEAR_BALANCE_STATUS = 'CLEAR_BALANCE_STATUS';


// Report Budget status generated - Report Credit Execution 
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST = 'REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST';
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST_SUCCESS = 'REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST_SUCCESS';
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST_FAILURE = 'REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST_FAILURE';
export const CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED = 'CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED';

// Report Budget status generated - Report Credit Execution 
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST = 'REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST';
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST_SUCCESS = 'REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST_SUCCESS';
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST_FAILURE = 'REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST_FAILURE';
export const CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION_LIST_GENERATED = 'CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION_LIST_GENERATED';

// Report delivery orders - Report Request Fund
export const REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST = 'REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST';
export const REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST_SUCCESS = 'REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST_SUCCESS';
export const REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST_FAILURE = 'REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST_FAILURE';
export const CLEAR_REPORT_DELIVERY_ORDERS_FUND_REQUEST = 'CLEAR_REPORT_DELIVERY_ORDERS_FUND_REQUEST';

// Report payment order administrative service fund request - Report Request Fund
export const REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST = 'REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST';
export const REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST_SUCCESS = 'REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST_SUCCESS';
export const REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST_FAILURE = 'REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST_FAILURE';
export const CLEAR_REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST = 'CLEAR_REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST';

// Report service direction fund request - Report Request Fund
export const REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST = 'REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST';
export const REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_SUCCESS = 'REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_SUCCESS';
export const REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_FAILURE = 'REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_FAILURE';
export const CLEAR_REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST = 'CLEAR_REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST';

// Report service direction fund request - Report Request Fund
export const REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST = 'REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST';
export const REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_SUCCESS = 'REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_SUCCESS';
export const REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_FAILURE = 'REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_FAILURE';

// Report fund request - Report Request Fund
export const REPORT_FUND_REQUEST_REQUEST = 'REPORT_FUND_REQUEST_REQUEST';
export const REPORT_FUND_REQUEST_REQUEST_SUCCESS = 'REPORT_FUND_REQUEST_REQUEST_SUCCESS';
export const REPORT_FUND_REQUEST_REQUEST_FAILURE = 'REPORT_FUND_REQUEST_REQUEST_FAILURE';
export const CLEAR_REPORT_FUND_REQUEST_REQUEST = 'CLEAR_REPORT_FUND_REQUEST_REQUEST';

// Report provider fund request - Report Request Fund
export const REPORT_PROVIDER_FUND_REQUEST_REQUEST = 'REPORT_PROVIDER_FUND_REQUEST_REQUEST';
export const REPORT_PROVIDER_FUND_REQUEST_REQUEST_SUCCESS = 'REPORT_PROVIDER_FUND_REQUEST_REQUEST_SUCCESS';
export const REPORT_PROVIDER_FUND_REQUEST_REQUEST_FAILURE = 'REPORT_PROVIDER_FUND_REQUEST_REQUEST_FAILURE';
export const CLEAR_REPORT_PROVIDER_FUND_REQUEST_REQUEST = 'CLEAR_REPORT_PROVIDER_FUND_REQUEST_REQUEST';

// Report fund request bank account
export const REPORT_FUND_REQUEST_BANK_ACCOUNT_REQUEST = 'REPORT_FUND_REQUEST_BANK_ACCOUNT_REQUEST';
export const REPORT_FUND_REQUEST_BANK_ACCOUNT_SUCCESS = 'REPORT_FUND_REQUEST_BANK_ACCOUNT_SUCCESS';
export const REPORT_FUND_REQUEST_BANK_ACCOUNT_FAILURE = 'REPORT_FUND_REQUEST_BANK_ACCOUNT_FAILURE';
export const CLEAR_REPORT_FUND_REQUEST_BANK_ACCOUNT_REQUEST = 'CLEAR_REPORT_FUND_REQUEST_BANK_ACCOUNT_REQUEST';

// Delete Report
export const REPORT_DELETE_REQUEST = 'REPORT_DELETE_REQUEST';
export const REPORT_DELETE_SUCCESS = 'REPORT_DELETE_SUCCESS';
export const REPORT_DELETE_FAILURE = 'REPORT_DELETE_FAILURE';
export const CLEAR_REPORT_DELETE_REQUEST = 'CLEAR_REPORT_DELETE_REQUEST';

// Report new fund request - Report Request Fund
export const REPORT_NEW_FUND_REQUEST_REQUEST = 'REPORT_NEW_FUND_REQUEST_REQUEST';
export const REPORT_NEW_FUND_REQUEST_REQUEST_SUCCESS = 'REPORT_NEW_FUND_REQUEST_REQUEST_SUCCESS';
export const REPORT_NEW_FUND_REQUEST_REQUEST_FAILURE = 'REPORT_NEW_FUND_REQUEST_REQUEST_FAILURE';
export const CLEAR_REPORT_NEW_FUND_REQUEST_REQUEST = 'CLEAR_REPORT_NEW_FUND_REQUEST_REQUEST';

//Loading

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING'

// Connectivity CCPM status
export const GET_CONNECTIVITY_STATUS_REQUEST = 'GET_CONNECTIVITY_STATUS_REQUEST';
export const GET_CONNECTIVITY_STATUS_REQUEST_SUCCESS = 'GET_CONNECTIVITY_STATUS_REQUEST_SUCCESS';
export const GET_CONNECTIVITY_STATUS_REQUEST_FAILURE = 'GET_CONNECTIVITY_STATUS_REQUEST_FAILURE';

// Report Determination Passive Residues
export const REPORT_DETERMINATION_PASSIVE_RESIDUES = 'REPORT_DETERMINATION_PASSIVE_RESIDUES';
export const REPORT_DETERMINATION_PASSIVE_RESIDUES_SUCCESS = 'REPORT_DETERMINATION_PASSIVE_RESIDUES_SUCCESS';
export const REPORT_DETERMINATION_PASSIVE_RESIDUES_FAILURE = 'REPORT_DETERMINATION_PASSIVE_RESIDUES_FAILURE';
export const CLEAR_REPORT_DETERMINATION_PASSIVE_RESIDUES = 'CLEAR_REPORT_DETERMINATION_PASSIVE_RESIDUES';

// Report Determination Passive Residues
export const REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES = 'REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES';
export const REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES_SUCCESS = 'REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES_SUCCESS';
export const REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES_FAILURE = 'REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES_FAILURE';
export const CLEAR_REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES = 'CLEAR_REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES';

// Get Accrual basis report
export const GET_ACCRUAL_BASIS_REPORT_REQUEST = 'GET_ACCRUAL_BASIS_REPORT_REQUEST';
export const GET_ACCRUAL_BASIS_REPORT_REQUEST_SUCCESS = 'GET_ACCRUAL_BASIS_REPORT_REQUEST_SUCCESS';
export const GET_ACCRUAL_BASIS_REPORT_REQUEST_FAILURE = 'GET_ACCRUAL_BASIS_REPORT_REQUEST_FAILURE';

// Report Compensation Direct download
export const REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST = 'REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST';
export const REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST_SUCCESS = 'REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST_SUCCESS';
export const REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST_FAILURE = 'REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST_FAILURE';