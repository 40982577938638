import axios from 'axios';
import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, buildURLQuery, httpPost, httpPut, httpDelete } from './httpServices';

const API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;
const PROVISION_ORDER_URI = `${API_BASE_URI}/provision-orders`;

// GET Provision Order
export const getProvisionOrder = async (accessToken, params) => {
	let queryString = "";
	let URL = "";
	const options = buildDefaultOptions(accessToken);
	let response;

	if(params != undefined) {
		const setBuildURLQuery = {
			page: params?.page || 0,
			size: params?.size || 10,
			sort: params?.sort || 'id,asc',
			administrativeServiceId: params?.administrativeServiceId,
			name: params?.name,
			type: params?.type
		};		
		queryString = buildURLQuery(setBuildURLQuery);
		URL = `${PROVISION_ORDER_URI}?${queryString}`;
	} else {
		URL = `${PROVISION_ORDER_URI}`;
	}

	try {
		response = await httpGet(URL, options);
	} catch (error) {
		return error;
	}

	return response;
};

// EDIT Provision Order
export const editProvisionOrder = async (accessToken, id, params) => {
	const URL = `${PROVISION_ORDER_URI}/${id}`
	const options = buildDefaultOptions(accessToken);
	let response;
	try {
		response = await httpPut(URL, params, options);
	} catch (error) {
		return error?.response || error;
	}
	return response;
};

//GET BY ID
export const getProvisionOrderId = async (accessToken, id) => {
	const URL = `${PROVISION_ORDER_URI}/${id}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	} catch (error) {
		return error;
	}

	return response;
}


// POST Provision Order
export const postProvisionOrder = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);
	let response;

	try {
		response = await httpPost(PROVISION_ORDER_URI, params, options);
	} catch (error) {
		return error?.response || error;
	}
	return response;
};

// DELETE Provision Order
export const deleteProvisionOrder = async (accessToken, id) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${PROVISION_ORDER_URI}/${id}`;
	let response;
	try {
		response = await httpDelete(URL, options);
	} catch (error) {
		return error?.response || error;
	}
	return response;
};
