import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import {
	Container,
	Card,
	Table,
	Button,
	Spinner,
	Alert,
	Row,
	Col,
	FormControl,
	Form
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faExclamationTriangle,
	faSearch,
	faUsers,
	faGlobeAmericas,
	faEdit,
	faTrash,
	faBroom,
	faFilter
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { setAdministrativeServiceToEdit } from "src/redux/administrativeService/administrativeServiceActions";

import PageTitle from "src/components/general/PageTitle";
import {
	SERVICE_ADMINISTRATIVED_NEW,
	SERVICE_ADMINISTRATIVE_ORGANISM,
	SERVICE_ADMINISTRATIVE_USER,
	SERVICE_ADMINISTRATIVE_EDIT,
	SERVICE_ADMINISTRATIVE_DETAILS,
} from 'src/utils/constants';

import { getUserPermissionsServices } from "src/redux/login/loginReducer";

import ActionIcon from "src/components/general/ActionIcon";
import {
	getAdministrativeServiceListData,
	getAdministrativeServiceListIsFetching,
} from "src/redux/administrativeService/administrativeServiceReducer";
import { clearListAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import { isNotEmptyArray } from "src/services/validationService";
import {
	setAdministrativeServicesDetailData,
	setAdministrativeServicesOrganismData,
	setAdministrativeServicesUsersData,
} from "src/redux/administrativeService/administrativeServiceActions";
import {
	tryListAdministrativeServices,
	tryDeleteAdministrativeService,
} from "src/redux/administrativeService/administrativeServiceActionCreator";
import { clearListAllAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import { questionDeleteAdministrativeService, seeMore } from "src/utils/label";
import { useForm } from 'react-hook-form';
import AppLoading from 'src/components/common/AppLoading';

const ServiceAdministrativeListPage = () => {
	const dispatch = useDispatch();
	const { handleSubmit, register, reset } = useForm();

	// Permissions
	const servicesPermissions = useSelector((state) =>
		getUserPermissionsServices(state)
	);

	// Services
	const servicesList = useSelector(
		(state) => getAdministrativeServiceListData(state)?.records
	);
	const servicesListPaginate = useSelector((state) =>
		getAdministrativeServiceListData(state)
	);

	const servicesListMetadata = servicesListPaginate?.metadata;
	const totalListRecords = servicesListMetadata?.total;
	const hasMoreServices =
		servicesListMetadata?.total >
		(servicesListMetadata?.page + 1) * servicesListMetadata?.pageSize;

	const servicesListIsFetching = useSelector((state) =>
		getAdministrativeServiceListIsFetching(state)
	);
	const hasServicesList = isNotEmptyArray(servicesList);

	const loadServices = () => {
		const params = {
			page: 0,
			size: 10,
			sort: "code,asc" || "",
		};
		dispatch(clearListAllAdministrativeServicesData());
		dispatch(tryListAdministrativeServices(params));

	};

	useEffect(loadServices, []);

	const onClickLoadMore = () => {
		const params = {
			page: servicesListMetadata?.page,
			size: totalListRecords,
			sort: "code,asc" || "",
		};
		dispatch(tryListAdministrativeServices(params));
	};

	const onClickLoadLess = () => {
		const params = {
			page: servicesListMetadata?.page,
			size: 10,
			sort: "code,asc" || "",
		};
		dispatch(tryListAdministrativeServices(params));
	};

	const onClickNewAffectation = () => {
		dispatch(push(SERVICE_ADMINISTRATIVED_NEW));
	};

	const onClickDetailService = (service) => {
		dispatch(setAdministrativeServicesDetailData(service));
		dispatch(setAdministrativeServicesOrganismData(service));
		dispatch(push(SERVICE_ADMINISTRATIVE_DETAILS));
	};

	const onClickEditAdministrativeService = (service) => {
		dispatch(setAdministrativeServiceToEdit(service));
		dispatch(push(SERVICE_ADMINISTRATIVE_EDIT));
	};
	const onClickUserService = (service) => {
		dispatch(setAdministrativeServicesUsersData(service));
		dispatch(push(SERVICE_ADMINISTRATIVE_USER));
	};

	const onClickDeleteAdministrativeService = (service) => {
		let nameAdministrativeService = service.name;
		swal({
			title: "Confirmación",
			text:
				"¿" +
				questionDeleteAdministrativeService +
				nameAdministrativeService +
				"?",
			icon: "warning",
			buttons: ["Cancelar", "Aceptar"],
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(tryDeleteAdministrativeService(service.id));
				dispatch(clearListAdministrativeServicesData());
				dispatch(tryListAdministrativeServices());
			} else {
				/*   swal('El usuario esta a salvo!'); */
			}
		});
	};

	const onClickOrganismService = (service) => {
		dispatch(setAdministrativeServicesOrganismData(service));
		dispatch(push(SERVICE_ADMINISTRATIVE_ORGANISM));
	};

	const onSubmitForm = (data) => {
		if (!servicesListIsFetching) {
				dispatch(clearListAllAdministrativeServicesData());
				dispatch(tryListAdministrativeServices(data));
			}
	}
	const clearFilters = () => {
		reset()
		dispatch(clearListAllAdministrativeServicesData());
		const params = {
			page: servicesListMetadata?.page,
			size: 10,
			sort: "code,asc" || "",
		};
		dispatch(tryListAdministrativeServices(params));
	};

	return (
		<Container fluid>
			<Card>
				<PageTitle text="Servicios" />

				<Container fluid>
					<Card className="mb-3">
						<Card.Header className="h6">Gestión de servicios</Card.Header>
						<Card.Body>
							{servicesPermissions?.canCreate ? (
								<Button
									size="sm"
									className="mb-3"
									variant="success"
									onClick={onClickNewAffectation}
								>
									<FontAwesomeIcon icon={faPlus} className="mr-1" />
									Nuevo Servicio
								</Button>
							) : null}
							<Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off'>
							<Table striped bordered hover size="sm" >
								<thead>
									<tr>
										<th className="text-center align-middle" width='8%'>Código</th>
										<th className="text-center align-middle" width='41%'>Descripción</th>
										<th className="text-center align-middle" width='18%'>Abreviatura</th>
										<th className="text-center align-middle" width='18%'>Tipo</th>
										<th className="text-center align-middle" width='15%'>Acción</th>
									</tr>
									<tr className="secondary">
										<th className="text-center">
											<FormControl
												size="sm"
												type="number"
												name="code"
												placeholder={""}
												ref={register}

											/>
										</th>
										<th className="text-center">
											<FormControl
												size="sm"
												type="text"
												name="name"
												placeholder={""}
												ref={register}
											/>
										</th>

										<th></th>

										<th></th>

										<th className='text-center align-middle'>
											<div className='d-flex justify-content-around'>
												<ActionIcon
													size="lg"
													id="search-button"
													className="btn-primary search-button text-white-color"
													toolTipText="Filtrar"
													icon={faFilter}
													type='submit'
													onSubmit={onSubmitForm}

													/>
												<ActionIcon
													size="lg"
													id="clean-filter"
													className="btn-primary clean-filter text-white-color"
													toolTipText="Limpiar filtros"
													icon={faBroom}
													type='reset'
													onClick={()=> clearFilters()}
													/>
											</div>
                                    </th>
									</tr>
								</thead>
								<tbody className="text-black-color">
									{hasServicesList ? (
										<>
											{servicesList?.map((service) => (
												<tr key={service.id}>
													<td className="text-center align-middle">
														{service?.code}
													</td>
													<td className="text-center align-middle">
														{service?.name}
													</td>
													<td className="text-center align-middle">
														{service?.shortName}
													</td>
													<td className="text-center align-middle">
														{service?.administrativeServiceType?.name}
													</td>
													<td className="text-center align-middle px-2 m-0 table-responsive btn-group" >
														{servicesPermissions?.canView ? (
															<ActionIcon
																size="lg"
																id="credit-query"
																toolTipText="Detalle del servicio"
																icon={faSearch}
																onClick={() => onClickDetailService(service)}
															/>
														) : null}
														{servicesPermissions?.canUpdate ? (
															<ActionIcon
																size="lg"
																id="credit-query"
																toolTipText="Editar servicio"
																icon={faEdit}
																onClick={() =>
																	onClickEditAdministrativeService(service)
																}
															/>
														) : null}
														{servicesPermissions?.canUpdate ? (
															<ActionIcon
																size="lg"
																id="credit-query"
																toolTipText="Gestión usuarios del servicio"
																icon={faUsers}
																onClick={() => onClickUserService(service)}
															/>
														) : null}
														{servicesPermissions?.canUpdate ? (
															<ActionIcon
																size="lg"
																id="credit-query"
																toolTipText="Gestión organismos del servicio"
																icon={faGlobeAmericas}
																onClick={() => onClickOrganismService(service)}
															/>
														) : null}
														{servicesPermissions?.canDelete ? (
															<ActionIcon
																size="lg"
																id="delete"
																toolTipText="Borrar servicio"
																icon={faTrash}
																onClick={() =>
																	onClickDeleteAdministrativeService(service)
																}
															/>
														) : null}
													</td>
												</tr>
											))}
										</>
									) : (
											<tr>
												<td colSpan="13" className="text-center">
													{!servicesListIsFetching &&
															<Alert variant="info" className="mb-0">
																<FontAwesomeIcon
																	icon={faExclamationTriangle}
																	className="text-black-color mr-3"
																/>
																No hay registros
															</Alert>
														}
												</td>
											</tr>
										)}
								</tbody>
							</Table>
							</Form>
							{hasMoreServices ? (
								<Row>
									<Col className="text-center">
										<Button
											size="sm"
											className="px-5"
											disable={servicesListIsFetching}
											onClick={onClickLoadMore}
										>
											{servicesListIsFetching ? (
												<Spinner animation="border" size="sm" />
											) : (
													<>{seeMore}</>
												)}
										</Button>
									</Col>
								</Row>
							) : (
									<Row>
										<Col className="text-center">
											<Button
												size="sm"
												className="px-5"
												disable={servicesListIsFetching}
												onClick={onClickLoadLess}
												variant="danger"
											>
												{servicesListIsFetching ? (
													<Spinner animation="border" size="sm" />
												) : (
														<>{"Ver menos"}</>
													)}
											</Button>
										</Col>
									</Row>
								)}
						</Card.Body>
					</Card>
				</Container>
			</Card>
			<AppLoading isLoading={servicesListIsFetching} />
		</Container>
	);
};

export default ServiceAdministrativeListPage;
