import React, { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Form, Col, Row, Card } from 'react-bootstrap';
import DropdownList from 'react-widgets/lib/DropdownList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faChevronDown, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { organismCodeDropDownRequiredMsg } from 'src/utils/label';
import FormFieldError from 'src/components/general/FormFieldError';
import { useFormContext, useForm } from 'react-hook-form';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import TicketTable from 'src/components/pages/OrderPay/tables/TicketTable';
import ActionIcon from 'src/components/general/ActionIcon';
import { isNotEmptyArray } from 'src/services/validationService';
import { singleNumberFormatter } from 'src/utils/utils';
import { getOrderTypeDate } from 'src/redux/orderPay/orderPayReducer';


const ModalCertificadoServicios = props => {
    const { organismList, messagesDropDown, codeOrganism, handleOrganismOnChange, tipo, onlyRead } = props;
	
	const [ticket, setTicket] = useState("");
	const [amount, setAmount] = useState("");
	const [finalDate, setFinalDate] = useState("");
	const initialDate = useSelector(state => getOrderTypeDate(state));
	
	const receiptDate = 'Debe ingresar una fecha';
    const dateStartRequiredMsg = 'Debe ingresar una fecha de inicio';
    const dateEndRequiredMsg = 'Debe ingresar una fecha final';
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );
    const today = moment().format("YYYY-MM-DD");
    const minDate = moment(globalSelectedPeriod?.year.toString()).subtract(2, 'years').format("YYYY-MM-DD");

	const selectedOrganism = organismList?.find((item) => item.id == props?.data?.codeOrganism);

	//Para reutilizar modal en detalle de OP
	let register, errors, getValues;
	if(!props.data || !onlyRead){
		register = useFormContext().register;
		errors = useFormContext().errors;
		getValues = useFormContext().getValues;
	}else{
		register = useForm().register;
		errors = useForm().errors;
		getValues = useForm().getValues;
	}

	const ticketHandler = () => {
		props?.ticketObj?.setTicketData(prev => [...prev, {ticket, amount: singleNumberFormatter(amount)}])
		setTicket("");
		setAmount("");
		delete errors?.ticketAmount;
	}

	const organismDropDownObjValidation = { required: organismCodeDropDownRequiredMsg };
    const dateStartValidationObj = {
		required: dateStartRequiredMsg,
		validate: () => {
			const inicio = getValues()?.periodStart;
			const fin = getValues()?.periodEnd;
			if (inicio && fin && inicio > fin){
				return 'La fecha de inicio es superior a la fecha final';
			}else if ( inicio && (inicio < minDate || inicio > today) ){
				return 'Fecha inválida.';
			}
		}
    };
    const dateEndValidationObj = { 
		required: dateEndRequiredMsg,
		validate: () => {
			const inicio = getValues()?.periodStart;
			const fin = getValues()?.periodEnd;
			if (inicio && fin && inicio > fin){
				return 'La fecha de inicio es superior a la fecha final';
			}else if ( fin && (fin < minDate || fin > today) ){
				return 'Fecha inválida.';
			}
		}
	};
	const receiptDateObjValidation = { 
		required: receiptDate,
		validate: () => {
			if (initialDate < finalDate) {
				return 'La fecha del recibo es superior a la fecha de la orden de pago';
			}
		}
	};
	const descriptionValidation = { required: 'Debe ingresar una descripción' };

	const onSelectDate = () => {
		setFinalDate(getValues()?.receiptDate);
		register(receiptDateObjValidation)
    };

	useEffect(() => {
		return () => {
			!props?.data && handleOrganismOnChange();
			props?.ticketObj?.setTicketData([]);
		}
	}, [])

	return <>
	<Form.Group as={Row}>
		<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
			<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
			Organismo:
		</Form.Label>
        <Col sm='5'>
            <DropdownList filter
				valueField='id'
                placeholder='Seleccione un organismo...'
                data={organismList}
                allowCreate='onFilter'
                textField='nameToShow'
                name='codeOrganism'
                as='select'
				disabled={onlyRead}
				defaultValue={props?.data && selectedOrganism?.id}
                onChange={ value => handleOrganismOnChange(value?.id)}
                messages={ messagesDropDown }
                selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
                searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>} 
                >
            </DropdownList>
			<Form.Control hidden ref={register(organismDropDownObjValidation)} name='codeOrganism' value={codeOrganism} />
			<FormFieldError errors={errors?.codeOrganism} />
        </Col>
	</Form.Group>

	<Form.Group as={Row}>
		<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
			<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
			Fecha:
		</Form.Label>
		<Col sm='5'>
			<Form.Control
				type='date'
				disabled={onlyRead}
				name='receiptDate'
				defaultValue={props?.data?.receiptDate}
				ref={register(receiptDateObjValidation)}
				onChange={onSelectDate}
			/>
			<FormFieldError errors={errors?.receiptDate} />
		</Col>
	</Form.Group>

	<Form.Group as={ Row }>
		<Form.Label className='d-flex mandatory-label' column sm='4'>
			<FontAwesomeIcon icon={ faAsterisk } size='xs' className='mr-1 text-danger mandatory-asterisk' />
			Descripción:
		</Form.Label>
		<Col sm='5'>
			<Form.Control
				name='description'
				disabled={onlyRead}
				defaultValue={props?.data?.description}
				ref={register(descriptionValidation)}
			/>
			<FormFieldError errors={errors?.description} />
		</Col>
	</Form.Group>

	<Form.Group as={Row}>
        <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
            <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
            Factura:
        </Form.Label>
        <Col sm='2'>
            <Form.Control
				name='ticket'
				disabled={onlyRead}
				defaultValue={props?.data?.ticket}
				onChange={(e) => setTicket(e.target.value)}
				value={ticket}
            />
        </Col>
		<Form.Label className='text-right d-flex mandatory-label' column sm='1'>
			<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
			Importe
		</Form.Label>
			<Col sm='2'>
				<NumberFormat
					className="form-control text-right"
					name='amount'
					disabled={onlyRead}
					inputmode="numeric"
					value={amount}
					placeholder='ej: $1.234,56'
					defaultValue={props?.data?.amount}
					onChange={(e) => setAmount(e.target.value)}
					thousandSeparator='.' decimalSeparator=','
					decimalScale='2'
				/>
			</Col>
			<ActionIcon className="ml-2" size='lg' toolTipText="Cargar Factura" disabled={!ticket || !amount} icon={faPlus} onClick={ticketHandler}/>
	</Form.Group>
		<Form.Group as={Row}>
			<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
				&nbsp;
			</Form.Label>
			<Col sm='5'>
				<FormFieldError errors={errors?.ticketAmount} />
				<Form.Control hidden ref={register({ required: "Debe ingresar una factura" })} name='ticketAmount' value={props?.ticketObj?.ticketData} />
			</Col>
		</Form.Group>
		{ 	(props?.data || isNotEmptyArray(props?.ticketObj?.ticketData)) &&
				<TicketTable
					ticketObj={props?.ticketObj}
					data={props?.data}
					onlyRead={onlyRead}
				/>
		}
	<Card variant='success' className='my-3'>
        <Card.Header>
             <h6 className='mb-0 text-center text-white'>
				Período
            </h6>
        </Card.Header>
	</Card>
	<Form.Group as={Row}>
		<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
			<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
			Inicio:
		</Form.Label>
		<Col sm='4'>
			<Form.Control
				type='date'
				name='periodStart'
				disabled={onlyRead}
				defaultValue={props?.data?.periodStart}
				ref={register(dateStartValidationObj)}
			/>
			<FormFieldError errors={errors?.periodStart} />
		</Col>
	</Form.Group>
	<Form.Group as={Row}>
		<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
			<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
			Final:
		</Form.Label>
		<Col sm='4'>
			<Form.Control
				type='date'
				name='periodEnd'
				disabled={onlyRead}
				defaultValue={props?.data?.periodEnd}
				ref={register(dateEndValidationObj)}
			/>
			<FormFieldError errors={errors?.periodEnd} />
		</Col>
	</Form.Group>
	<Form.Control hidden ref={register} name='type' value={tipo} />

</>};

export default ModalCertificadoServicios;