import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Button, Table, Alert, FormControl, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';
import ActionIcon from 'src/components/general/ActionIcon';
import { DELIVERY_ORDER_LIST } from 'src/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSave, faFilter, faExclamationTriangle, faBroom, faChevronDown, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { formatterPeso, mFormat, containsIdValidation, checkContainsArr } from 'src/utils/utils'
import axios from 'axios';
import moment from 'moment';
import DropdownList from 'react-widgets/lib/DropdownList';
import { getFundRequestsListData, getFundRequestsListIsFetching } from 'src/redux/fundRequests/fundRequestsReducer'
import AppLoading from 'src/components/common/AppLoading';
import { tryListFundRequests } from 'src/redux/fundRequests/fundRequestsActionCreator'
import { clearListFundRequestsData } from 'src/redux/fundRequests/fundRequestsActions';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { tryPostDeliveryOrderMassive, tryListDeliveryOrder } from 'src/redux/deliveryOrder/deliveryOrderActionCreator'
import { getDeliveryOrderMassiveCreateIsFetching } from 'src/redux/deliveryOrder/deliveryOrderReducer'
import { showError, showSuccess } from 'src/redux/globalData/globalDataActionCreator';
import { tryGetAdministrativeDocumentAvailables } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { AdministrativeServiceData, administrativeService } from 'src/utils/administrativeServiceUtils';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const DeliveryOrderNewPageMassive = () => {
	const dispatch = useDispatch();
	const { handleSubmit, reset } = useForm();

	const [arrItems, setArrItems] = useState([]);
	const todayDate = moment(new Date()).format('YYYY-MM-DD');
	const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state));
	const [flag, setFlag] = useState(false);
	const administrativeServiceData = AdministrativeServiceData();

	const creditLabelsRefContainer = useRef(null);
	const checkSelectedValidation = (item) => {
		return (
			!item.propsDeliveryNumber ||
			!item.propsDeliveryOrderDate
		);
	};

	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);

	const [servicio, setServicio] = useState();
	const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };
	const deliveryOrderMassiveCreateIsFetching = useSelector(state => getDeliveryOrderMassiveCreateIsFetching(state));

	//Last delivery order
	const [lastDeliveryOrderNumber, setLastDeliveryOrderNumber] = useState('');

	//Pedidios de fondos
	const foundRequestList = useSelector(state => getFundRequestsListData(state)?.records);
	const foundRequestListIsFitching = useSelector(state => getFundRequestsListIsFetching(state))

	const defaultParams = {
		state: "ACTIVO_PENDIENTE_DE_AUTORIZACION",
		number: "",
		year: "",
		description: "",
		size: 50
	};

	const defaultParamsDeliveryOrderList = {
		page: 0,
        size: 10,
        sort: 'number,desc',
        year: periodSelected?.year
    };

	const loadAllData = (params) => {
		dispatch(clearListFundRequestsData());
		dispatch(tryListFundRequests(params));
	}

	const updateStateForValidation = (e, item, type) => {
		setFlag(!flag);
		item.propsDeliveryOrderDate = todayDate;
		switch (type) {
			case "propsAdministrativeDocumentYear":
				item.propsAdministrativeDocumentYear = e.target.value
				break;
			case "propsDeliveryNumber":
				item.propsDeliveryNumber = e.target.value
				break;
			case "propsDeliveryOrderDate":
				item.propsDeliveryOrderDate = e.target.value
				break;
			default:
				break;
		}
	}

	useEffect(() => {
		dispatch(tryListDeliveryOrder(defaultParamsDeliveryOrderList)).then(response => {
			setLastDeliveryOrderNumber(response?.data?.records?.[0]?.number || undefined);
		});
		loadAllData(defaultParams);
	}, []);

	
	const onSelectCheck = (pf, target) => {
		if (!target.checked){
			setArrItems(arrItems.filter(item => item.fundRequest.id !== pf.id));
			return;
		}
		let flags = true;
		newFilterObject = {
			sort:"id,asc",
			codeOrganism: pf.administrativeDocument?.codeOrganism,
			number: pf.administrativeDocument?.number,
			year: pf.administrativeDocument?.year
		};
		newFilterObject2 = {
			...defaultParams,
			number: pf.propsDeliveryNumber,
		};
		const allPromises = [];
        allPromises.push(dispatch(tryGetAdministrativeDocumentAvailables(newFilterObject)));
        allPromises.push(dispatch(tryListDeliveryOrder(newFilterObject2)));
        Promise.all(allPromises)
            .then(results => {
				if (results[1]?.data?.records.length > 0) {
					showError(dispatch, 'Ya existe una orden con ese número.');
					flags = false;
				}
				if (checkContainsArr(arrItems, pf.propsDeliveryNumber)) {
					showError(dispatch, 'Ya existe una orden con ese número en el listado previo.');
					flags = false;
				}
				if (flags) {
					handleArrItems(pf);
				}else {
					target.checked = false;
				}
            });
	}
	const handleArrItems = pf => {
		arrItems?.some(obj => obj.fundRequest.id === pf.id) ?
		setArrItems(arrItems.filter(item => item.fundRequest.id !== pf.id)) :
		setArrItems((prev) => [...prev, {
			number: pf.propsDeliveryNumber,
			deliveryOrderDate: pf.propsDeliveryOrderDate,
			description: "",
			year: periodSelected.year,
			fundRequest: {
				id: pf.id,
				fundRequestDate: pf.fundRequestDate,
				number: pf.number,
				administrativeServiceId: pf.administrativeServiceId,
				description: pf.description,
				details: pf.details,
				amount: pf.amount 
			},
			administrativeDocument: {
				codeOrganism: pf.administrativeDocument?.codeOrganism,
				number: pf.administrativeDocument?.number,
				year: pf.administrativeDocument?.year
			}
		}])
	}

	//filter
	let newFilterObject;
	let newFilterObject2;

	//Cancel Request
	const CancelToken = axios.CancelToken;
	let cancelSource = CancelToken.source();

	const [filterObject, setFilterObject] = useState({});

	const clearFilters = () => {
		setFilterObject({});
		reset();
		setServicio('');
		loadAllData(defaultParams)
	};

	const onSubmitForm = () => {
		if (!foundRequestListIsFitching) {
			cancelSource.cancel();
			cancelSource = CancelToken.source();
			newFilterObject = {
				...defaultParams,
				administrativeServiceId: servicio,
			};
			setFilterObject(newFilterObject);
			dispatch(tryListFundRequests(newFilterObject));
		}
	}

	const onClickCancelButton = () => {
		redirectToList();
	};
	const redirectToList = () => { dispatch(push(DELIVERY_ORDER_LIST)) };

	const onClickSubmitButton = () => {
		if (arrItems.length > 0) {
			const clearParams = arrItems;
			dispatch(tryPostDeliveryOrderMassive(clearParams)).then(
				response => {
					if (response?.status == 201) {
						showSuccess(dispatch, 'Se creo correctamente las ordenes de entrega');
						dispatch(push(DELIVERY_ORDER_LIST));
					} else {
						showError(dispatch, 'Error al crear las ordenes de entrega. ');
					}
				}
			);
		} else {
			showError(dispatch, 'Debe seleccionar al menos un pedido de fondo');
		}
	}

	return <>
		<Modal show={show} onHide={handleClose} dialogClassName="modal-xxl">
			<Modal.Header closeButton>
				<Modal.Title>Ordenes Masivas Seleccionadas</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className='mt-4 text-black-color' >
					<Table striped bordered hover size='sm' responsive>
						<thead>
							<tr ref={creditLabelsRefContainer}>
								<th className='text-white-color text-center align-middle' >Nro de  Expediente</th>
								<th className='text-white-color text-center align-middle' width="10%">Servicio Administrativo</th>
								<th className='text-white-color text-center align-middle' width="20%">Descripción</th>
								<th className='text-white-color text-center align-middle px-4'>Nro Pedido de fondo</th>
								<th className='text-white-color text-center align-middle px-4'>Fecha Pedido Fondo</th>
								<th className='text-white-color text-center align-middle' width="10%">Importe</th>
								<th className='text-white-color text-center align-middle' width="10%">Nro Orden</th>
								<th className='text-white-color text-center align-middle' width="10%">Fecha Orden</th>
							</tr>
						</thead>
						<tbody className='text-black-color'>
							{arrItems?.length > 0 ?
								arrItems?.map(item =>
									<tr id={item?.id} key={item?.id}>
										<td className='text-center align-middle'>
											{item?.administrativeDocument?.codeOrganism} - {item?.administrativeDocument?.number} / {item?.administrativeDocument?.year}
										</td>
										<td className='text-left align-middle'>
											{administrativeService(item?.fundRequest?.administrativeServiceId, administrativeServiceData)}
										</td>
										<td className='text-center align-middle'>
											{item?.fundRequest?.description}
										</td>
										<td className='text-center align-middle'>
											{item?.fundRequest?.number}
										</td>
										<td className='text-center align-middle'>
											{mFormat(item?.fundRequest?.fundRequestDate)}
										</td>
										<td className='align-middle text-right'>
											{formatterPeso.format(item?.fundRequest?.amount)}
										</td>
										<td className='text-center align-middle'>
											{item?.number}
										</td>
										<td className='text-center align-middle'>
											{mFormat(item?.deliveryOrderDate)}
										</td>
									</tr>)
								:
								<tr>
									<td colSpan='9' className='text-center'>
										<Alert variant='info' className='mb-0'>
											<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
											No hay registros
										</Alert>
									</td>
								</tr>
							}
						</tbody>
					</Table>
					<div className='d-flex justify-content-around mt-4 mb-3'>
						<Button type='button' variant='danger' size='lg' onClick={handleClose}>
							Cancelar
						</Button>
						<Button className='text-white-color' onClick={onClickSubmitButton} variant='success' size='lg'  >
							Generar Ordenes Entrega
							<FontAwesomeIcon className='ml-3' icon={faSave} />
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>

				<Container fluid>
					<Card className='mb-5'>
						<Card.Header className='d-flex justify-content-between'>
							<h1 className="h6 mt-1 mb-0">Nueva Orden de Entrega Masiva</h1>
							<a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/safi2-responsable-entrega-fondos/#2-orden-de-entrega-masiva'>
								<FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
								<small>Ver manual de uso</small> 
							</a>
						</Card.Header>
						<Card.Body>
							<Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off'>
								<Table fluid striped hover size='sm' responsive>
									<thead>
										<tr ref={creditLabelsRefContainer}>
											<th className='text-center align-middle py-3' width="8%">Nro de  Expediente</th>
											<th className='text-center align-middle' width="20%">Servicio Administrativo</th>
											<th className='text-center align-middle' width="20%">Descripción</th>
											<th className='text-center align-middle' width="8%">Nro Pedido fondo</th>
											<th className='text-center align-middle' width="10%">Fecha Pedido Fondo</th>
											<th className='text-center align-middle' width="10%">Importe</th>
											<th className='text-center align-middle' width="8%">Nro Orden</th>
											<th className='text-center align-middle' width="8%">Fecha Orden</th>
											<th className='text-center align-middle' width="8%">Acciones</th>
										</tr>
										<tr className='secondary'>
											<th></th>
											<th>
												<DropdownList
													placeholder='Seleccione un servicio...'
													data={administrativeServiceData}
													allowCreate='onFilter'
													name='administrativeServiceId'
													textField='displayName'
													valueField='id'
													filter='contains'
													value={servicio}
													className='w-100 text-black-color'
													onChange={value => setServicio(value.id)}
													id='administrativeServiceId'
													messages={messagesDropDown}
													selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
													searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />} >
												</DropdownList>
											</th>
											<th></th>
											<th></th>
											<th></th>
											<th></th>
											<th className='text-center align-middle'>
												<FontAwesomeIcon className="text-white-color" size='lg' title={"Ultima Orden de Entrega Generada: Nro " + lastDeliveryOrderNumber} icon={faQuestionCircle} />
											</th>
											<th></th>
											<th className='text-center align-middle'>
												<div className='d-flex justify-content-around'>
													<ActionIcon
														size="lg"
														id="search-button"
														className="btn-primary search-button text-white-color"
														toolTipText="Filtrar"
														icon={faFilter}
														type='submit'
														onSubmit={onSubmitForm}
													/>
													<ActionIcon
														size="lg"
														id="clean-filter"
														className="btn-primary clean-filter text-white-color"
														toolTipText="Limpiar filtros"
														icon={faBroom}
														type='reset'
														onClick={() => clearFilters()}
													/>
												</div>
											</th>
										</tr>
									</thead>
									<tbody className='text-black-color'>
										{foundRequestList?.length > 0 ?
											foundRequestList.map(item => 
												<tr id={item.id} key={item.id}>
													<td className='text-center align-middle maxTruncate'>
														{item?.administrativeDocument?.codeOrganism + ' - ' + item?.administrativeDocument?.number + ' / ' +  item?.administrativeDocument?.year}
													</td>
													<td className='text-center align-middle maxTruncate'>
														{administrativeService(item?.administrativeServiceId, administrativeServiceData)}
													</td>
													<td className='text-center align-middle maxTruncate'>
														{item.description}
													</td>
													<td className='text-center align-middle truncate maxTruncate'>
														{item.number}
													</td>
													<td className='text-center align-middle truncate maxTruncate'>
														{mFormat(item.fundRequestDate)}
													</td>
													<td className='text-center align-middle truncate maxTruncate'>
														{formatterPeso.format(((item?.amount)))}
													</td>
													<td className='text-center align-middle truncate maxTruncate'>
														<FormControl placeholder="Nro de Orden" disabled={containsIdValidation(arrItems, item)} className='text-center' name='deliveryNumber' onChange={(e) => updateStateForValidation(e, item, "propsDeliveryNumber")} />
													</td>
													<td className='text-center align-middle truncate maxTruncate'>
														<FormControl 
															placeholder="04/10/2022" 
															defaultValue={todayDate}
															type="date" 
															disabled={containsIdValidation(arrItems, item)} 
															className='text-center' 
															max={todayDate} 
															min={item.fundRequestDate} 
															name='deliveryOrderDate' 
															onChange={(e) => updateStateForValidation(e, item, "propsDeliveryOrderDate")} 
														/>
													</td>
													<td className='text-center align-middle truncate maxTruncate'>
														<Form.Check
															inline
															disabled={checkSelectedValidation(item)}
															onClick={(e) => onSelectCheck(item, e.target)}
															label="Seleccionar"
														/>
													</td>
												</tr>)
												:
												<tr>
													<td colSpan='9' className='text-center'>
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
															No hay registros
														</Alert>
													</td>
												</tr>
										}
									</tbody>
								</Table>
							</Form>
							<div className='mt-4 d-flex justify-content-around text-white-color'>
								<Button className='text-white-color' variant='primary' size='lg' onClick={onClickCancelButton}>
									Volver
								</Button>
								<Button className='text-white-color' onClick={handleShow} disabled={arrItems.length==0} variant='success' size='lg'  >
									Previsualizar Ordenes de Entrega
									<FontAwesomeIcon className='ml-3' icon={faSearch} />
								</Button>
							</div>
						</Card.Body>
					</Card>
					<AppLoading isLoading={foundRequestListIsFitching || deliveryOrderMassiveCreateIsFetching} />
				</Container>
	</>
};

export default DeliveryOrderNewPageMassive;