import React, { useEffect, useState } from 'react';
import { useFormContext, useForm } from 'react-hook-form';
import { Form, Col, Row, Card, Alert, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { config } from 'src/env.js';
import DropdownList from 'react-widgets/lib/DropdownList';
import ActionIcon from 'src/components/general/ActionIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faAsterisk, faChevronDown, faSearch, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from 'src/components/general/FormFieldError';
import { getAdministrativeDocumentYearsOptions, singleNumberFormatter } from 'src/utils/utils';
import { numberRequiredOrderPayMsg, organismCodeDropDownRequiredMsg } from 'src/utils/label';
import { useSelector } from 'react-redux';
import { getOrderTypeDate } from 'src/redux/orderPay/orderPayReducer';

const ModalCertificacionObraPublica = (props) => {
	const { 
		organismList, messagesDropDown, codeOrganism, 
		handleOrganismOnChange, onlyRead,
		setIsReciptAmountValid
	} = props;
	const selectedOrganism = organismList?.find((item) => item.id == props?.data?.codeOrganism);
	const [item, setItem] = useState({ ticket: '', amount: '' });
	const yearsOptions = getAdministrativeDocumentYearsOptions();
	const initialDate = useSelector(state => getOrderTypeDate(state));
	const [finalDate, setFinalDate] = useState("");
	const tickets = onlyRead ? props?.data?.tickets : props?.ticketObj?.ticketData;
	const totalTickets = tickets.reduce((total, currentItem) => total = total + parseFloat(currentItem.amount), 0);
	const amounts = onlyRead ? props?.data?.breakDownWorkCertificate : props?.breakDownWorkCertificateObj?.amountData;
	const totalAmounts = amounts?.reduce((total, currentItem) => total = total + parseFloat(currentItem.amount), 0);
	const LEGAL_INSTRUMENT_TYPES = config.appSettings.LEGAL_INSTRUMENTS_TYPES;
	
	const codeExpRequiredMsg = 'Debe ingresar un código';
	const correlativeExpRequiredMsg = 'Debe ingresar un código correlativo';
	const yearExpRequiredMsg = 'Debe ingresar un año';
	const yearRequiredMsg = 'Debe seleccionar un año.';
	const constructionSiteRequiredMsg = 'Debe ingresar una obra';
	const locationRequiredMsg = 'Debe ingresar una localidad';
	const receiptDate = 'Debe ingresar una fecha';
	const legalInstrumentValidationMsg = 'Debe seleccionar el tipo.'

	const correlativeExpValidationObj = { required: correlativeExpRequiredMsg };
	const yearExpValidationObj = { required: yearExpRequiredMsg };
	const authorizeLegalInstrumentTypeValidationObj = { validate: value => (value == "Tipo") ? legalInstrumentValidationMsg : undefined };
	const granteLegalInstrumentTypeValidationObj = { validate: value => (value == "Tipo") ? legalInstrumentValidationMsg : undefined };
	const authorityNumberYearValidationObj = { validate: value => (value == "") ? yearRequiredMsg : undefined };
	const grantedNumberYearValidationObj = { validate: value => (value == "") ? yearRequiredMsg : undefined };
	const numberValidationObj = { required: numberRequiredOrderPayMsg };
	const constructionSiteValidationObj = { required: constructionSiteRequiredMsg };
	const locationValidationObj = { required: locationRequiredMsg };
	const organismDropDownObjValidation = { required: organismCodeDropDownRequiredMsg };

	//Para reutilizar modal en detalle de OP
	let register, getValues, setValue, errors, triggerValidation;
	if(!props.data || !onlyRead){
		register = useFormContext().register;
		errors = useFormContext().errors;
		getValues = useFormContext().getValues;
		setValue = useFormContext().setValue;
		triggerValidation = useFormContext().triggerValidation;
	}else{
		register = useForm().register;
		errors = useForm().errors;
		getValues = useForm().getValues;
		setValue = useForm().setValue;
		triggerValidation = useForm().triggerValidation;
	}

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setItem(o => ({
			...o,
			[name]: value
		}))
	}

	const addTicket = () => {
		props?.ticketObj?.setTicketData(prev => [...prev, item])
		setItem({ ticket: '', amount: '' });
		setValue('ticket', '');
		setValue('amount', '');
		delete errors.tieneRegistros
	};

	const [concept, setConcept] = useState("");
	const [amount, setAmount] = useState("");
	const addConcept = () => {
		props?.breakDownWorkCertificateObj?.setAmountData(prev => [...prev, {concept, amount: singleNumberFormatter(amount)}])
		setConcept("");
		setAmount("");
	}

	const removeTicket = index => {
		props?.ticketObj?.setTicketData(props?.ticketObj?.ticketData?.filter((item, i) => i !== index));
	};

	const removeComcept = index => {
		props?.breakDownWorkCertificateObj?.setAmountData(props?.breakDownWorkCertificateObj?.amountData?.filter((item, i) => i !== index));
	};

	const codeValidation = {
		required: codeExpRequiredMsg,
		minLength: {
			value: 4,
			message: 'El código de organismo del expediente debe estar entre los rangos 1000 y 9999.'
		},
		maxLength: {
			value: 4,
			message: 'El código de organismo del expediente debe estar entre los rangos 1000 y 9999.'
		}
	};
	const receiptDateObjValidation = { 
		required: receiptDate,
		validate: () => {
			const inicio = getValues()?.receiptDate;
			const fin = getValues()?.certificateExpiration;
			if (inicio && fin && inicio > fin) {
				return 'La fecha del certificado es superior a la fecha de vencimiento.';
			} else if (inicio && fin && (fin == inicio)) {
				return 'La fecha del certificado no puede ser igual a la fecha de vencimiento.';
			}
			if (initialDate < finalDate){
				return 'La fecha del recibo es superior a la fecha de la orden de pago';
			}
		}
	};
	const inicioObraObj = {
		required: 'Debe ingresar una fecha',
		validate: () => {
			const inicio = getValues()?.periodStart;
			const fin = getValues()?.periodEnd;
			if (inicio && fin && inicio > fin) {
				return 'La fecha de inicio es superior a la fecha final';
			} else if (inicio && fin && (fin == inicio)) {
				return 'La fecha de inicio no puede ser igual a la fecha final.';
			}
		}
	};

	const onSelectDate = v => {
		triggerValidation("receiptDate");
		setFinalDate(getValues()?.receiptDate);
		register(receiptDateObjValidation)
    };

	if ((totalTickets === totalAmounts) && totalAmounts > 0) {
		setIsReciptAmountValid(true)
	} else {
		setIsReciptAmountValid(false)
	}

    useEffect(() => {
		return () => {
			props?.ticketObj?.setTicketData([]);
			props?.breakDownWorkCertificateObj?.setAmountData([]);
		}
	}, [])

	return <>
			<Form.Group as={Row}>
				<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
					<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Organismo:
				</Form.Label>
				<Col sm='4'>
					<DropdownList filter
						valueField='id'
						placeholder='Seleccione un organismo...'
						data={organismList}
						allowCreate='onFilter'
						textField='nameToShow'
						name='codeOrganism'
						as='select'
						disabled={onlyRead}
						defaultValue={props?.data && selectedOrganism?.id}
						onChange={ value => handleOrganismOnChange(value?.id)}
						messages={ messagesDropDown }
						selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
						searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>} 
						>
					</DropdownList>
					<Form.Control hidden ref={register(organismDropDownObjValidation)} name='codeOrganism' value={codeOrganism} />
					<FormFieldError errors={errors?.codeOrganism} />
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
					<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Número de Certificado:
				</Form.Label>
				<Col sm='4' className='align-self-center'>
					<Form.Control
						type='number'
						name='certificateNumber'
						ref={register(numberValidationObj)}
						disabled={onlyRead}
						defaultValue={props?.data?.certificateNumber}
					/>
					<FormFieldError errors={errors?.certificateNumber} />
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
					<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Tipo:
				</Form.Label>
				<Col sm='4'>
					<Form.Control
						as='select'
						name='certificateType'
						ref={register}
						disabled={onlyRead}
						defaultValue={props?.data?.certificateType}
					>
						{
							["Parcial", "Completo"]?.map((item, idx) => (
								<option className='text-black-color' value={item} key={idx}>
									{item}
								</option>
							))
						}
					</Form.Control>
					<FormFieldError errors={errors?.certificateType} />
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
					<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Fecha Certificado:
				</Form.Label>
				<Col sm='4'>
					<Form.Control
						type='date'
						name='receiptDate'
						ref={register(receiptDateObjValidation)}
						disabled={onlyRead}
						onChange={() => onSelectDate()}
						defaultValue={props?.data?.receiptDate}
					/>
					<FormFieldError errors={errors?.receiptDate} />
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
					<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Desde
				</Form.Label>
				<Col sm='4'>
					<Form.Control
						type='date'
						name='periodStart'
						ref={register(inicioObraObj)}
						disabled={onlyRead}
						onChange={() => triggerValidation("periodStart")}
						defaultValue={props?.data?.periodStart}
					/>
					<FormFieldError errors={errors?.periodStart} />
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
					<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Hasta
				</Form.Label>
				<Col sm='4'>
					<Form.Control
						type='date'
						name='periodEnd'
						ref={register(inicioObraObj)}
						disabled={onlyRead}
						onChange={() => triggerValidation("periodStart")}
						defaultValue={props?.data?.periodEnd}
					/>
				</Col>
			</Form.Group>

			<Form.Group as={Row}>
				<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
					Vencimiento de Certificado:
				</Form.Label>
				<Col sm='4'>
					<Form.Control
						type='date'
						name='certificateExpiration'
						ref={register}
						disabled={onlyRead}
						onChange={() => triggerValidation("receiptDate")}
						defaultValue={props?.data?.certificateExpiration}
					/>
				</Col>
			</Form.Group>
			<Form.Control hidden ref={register} name='type' value={props.tipo} />
			
			<Form.Group as={Row}>
				<Form.Label htmlFor='expediente-data' className='text-right d-flex mandatory-label' column sm='4'>
					<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Expediente:
				</Form.Label>
				<Col sm='4'>
					<div className="input-group mb-6">
						<Form.Control
							type='number'
							placeholder='Código'
							name='administrativeDocument'
							disabled={onlyRead}
							ref={register(codeValidation)}
							defaultValue={props?.data?.administrativeDocument}
						/>
						<span className='mt-1 mb-1 px-1'> - </span>
						<Form.Control
							type='number'
							name='number'
							placeholder='Correlativo'
							disabled={onlyRead}
							ref={register(correlativeExpValidationObj)}
							defaultValue={props?.data?.number}
						/>
						<span className='mt-1 mb-1 px-1'> / </span>
						<Form.Control
							as='select'
							name='year'
							disabled={onlyRead}
							ref={register(yearExpValidationObj)}
							defaultValue={props?.data?.year}
						>
							<option value={''}>Año</option>{
								yearsOptions?.map(item => (<option value={item} key={item.id}>
									{item}
								</option>))
							}
						</Form.Control>
					</div>
					<FormFieldError errors={errors?.administrativeDocument} />
					<FormFieldError errors={errors?.number} />
					<FormFieldError errors={errors?.year} />
				</Col>
			</Form.Group>
			<Form.Group as={Row}>
				<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
					<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Obra:
				</Form.Label>
				<Col sm='4'>
					<Form.Control
						name='work'
						disabled={onlyRead}
						ref={register(constructionSiteValidationObj)}
						defaultValue={props?.data?.work}
					/>
					<FormFieldError errors={errors?.work} />
				</Col>
			</Form.Group>
			<Form.Group as={Row}>
				<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
					<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Localidad:
				</Form.Label>
				<Col sm='4'>
					<Form.Control
						name='department'
						disabled={onlyRead}
						ref={register(locationValidationObj)}
						defaultValue={props?.data?.department}
					/>
					<FormFieldError errors={errors?.department} />
				</Col>
			</Form.Group>
			<Form.Group as={Row}>
				<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
					<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Licitación Nro.:
				</Form.Label>
				<Col sm='4'>
					<Form.Control
						type='number'
						name='biddingNumber'
						disabled={onlyRead}
						ref={register(numberValidationObj)}
						defaultValue={props?.data?.biddingNumber}
					/>
				</Col>
			</Form.Group>

			<Card className='mx-3 mb-3' >
				<h6 className='px-3 py-2 d-flex text-left font-italic'>
					Autorizado por:
				</h6>
				<Form.Group as={Row}>
					<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
						Instrumento legal
					</Form.Label>
					<Col sm='4'>
		                <div className="input-group" >
							<Form.Control
								as='select'
								className='mr-2'
								name='authorizedBy'
								disabled={onlyRead}
								ref={register(authorizeLegalInstrumentTypeValidationObj)}
								defaultValue={props?.data?.authorizedLegalInstrumentId?.legalInstrumentType?.name}
							>
		                        <option value={undefined} selected>Tipo</option>

								{
									LEGAL_INSTRUMENT_TYPES?.map(item => (<option className='text-black-color' value={item.name} key={item.id}>
										{item.name}
									</option>))
								}
							</Form.Control>
							<Form.Control
								type='number'
								className='mr-2'
								placeholder="Número"
								name='authorityNumber'
								disabled={onlyRead}
								ref={register(numberValidationObj)}
								defaultValue={props?.data?.authorizedLegalInstrumentId?.number}
							/>
							<Form.Control
								as='select'
								name='authorityNumberYear'
								className='select-min-width'
								ref={register(authorityNumberYearValidationObj)}
								disabled={onlyRead}
								defaultValue={props?.data?.authorizedLegalInstrumentId?.year}
							>
								<option value={''} selected>Año</option>
								{yearsOptions?.map(item => (
									<option value={item} key={item.id}> {item} </option>))
								}
							</Form.Control>
						</div>
						<FormFieldError errors={errors?.authorizedBy} />
						<FormFieldError errors={errors?.authorityNumber} />
						<FormFieldError errors={errors?.authorityNumberYear} />
					</Col>
				</Form.Group>
				<h6 className='mt-5 px-3 py-2 d-flex text-left font-italic'>
					Adjudicado por:
				</h6>
				<Form.Group as={Row}>
					<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
						Instrumento legal
					</Form.Label>
					<Col sm='4'>
		                <div className="input-group" >
							<Form.Control
								as='select'
								className='mr-2'
								name='grantedBy'
								disabled={onlyRead}
								ref={register(granteLegalInstrumentTypeValidationObj)}
								defaultValue={props?.data?.adjudicatedLegalInstrumentId?.legalInstrumentType?.name}
							>
		                        <option value={undefined} selected>Tipo</option>
								{LEGAL_INSTRUMENT_TYPES?.map(item => (
									<option className='text-black-color' value={item.name} key={item.id}>
										{item.name}
									</option>))
								}
							</Form.Control>
							<Form.Control
								type='number'
								className='mr-2'
								placeholder="Número"
								name='adjudicatorNumber'
								disabled={onlyRead}
								ref={register(numberValidationObj)}
								defaultValue={props?.data?.adjudicatedLegalInstrumentId?.number}
							/>
							<Form.Control
								as='select'
								name='grantedByYear'
								className='select-min-width'
								disabled={onlyRead}
								ref={register(grantedNumberYearValidationObj)}
								defaultValue={props?.data?.adjudicatedLegalInstrumentId?.year}
							>
								<option value={''} selected>Año</option>
								{yearsOptions?.map(item => (
									<option value={item} key={item.id}> {item} </option>))
								}
							</Form.Control>
						</div>
						<FormFieldError errors={errors?.grantedBy} />
						<FormFieldError errors={errors?.adjudicatorNumber} />
						<FormFieldError errors={errors?.grantedByYear} />
					</Col>
				</Form.Group>
			</Card>

			<Card className='mx-3 my-3' >				
				<Form.Group as={Row} className='mt-3'>
					<Form.Label className='text-right d-flex mandatory-label col-sm-2'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
						Factura:
					</Form.Label>
					<Col sm='3'>
						<Form.Control
							name='ticket'
							disabled={onlyRead}
							defaultValue={props?.data?.ticket}
							value={item?.ticket}
							onChange={handleChange}
						/>
					</Col>
					<Form.Label className='text-right d-flex mandatory-label col-sm-2'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
						Importe
					</Form.Label>
					<Col sm='3'>
						<NumberFormat
							className="form-control text-right"
							name='amount'
							disabled={onlyRead}
							inputmode="numeric"
							value={item?.amount}
							placeholder='ej: $1.234,56'
							defaultValue={props?.data?.amount}
							onValueChange={(inputObj) => handleChange({ target: { name: 'amount', value: inputObj.floatValue } })}
							thousandSeparator='.' decimalSeparator=','
							decimalScale='2'
						/>
					</Col>
					<ActionIcon 
						className="ml-2 sm-2" size='lg' 
						toolTipText="Añadir" 
						text='Añadir' 
						disabled={!item?.ticket || !item?.amount} icon={faPlus} 
						onClick={addTicket} 
					/>
				</Form.Group>
				{tickets?.length > 0 ?
					<Table striped hover size='sm' responsive className='col-md-8 offset-md-2' >
						<thead>
							<tr>
								<th className='text-center align-middle' width="20%" >Nro.</th>
								<th className='text-center align-middle' width="30%" >Factura</th>
								<th className='text-center align-middle' width="30%" >Importe</th>
								<th className='text-center align-middle' width="20%" >Acciones</th>
							</tr>
						</thead>
						<tbody className='text-black-color'>
							{tickets?.map((item, i) =>
								<tr key={item?.id}>
									<td className='text-center align-middle'>
										{(i + 1)}
									</td>
									<td className='text-center align-middle'>
										{item?.ticket}
									</td>
									<td className='text-center align-middle'>
										{item?.amount}
									</td>
									<td className='text-center align-middle'>
										{!onlyRead && <ActionIcon className="text-danger ml-2" size='lg' toolTipText="Remover" icon={faTimes} onClick={() => removeTicket(i)} />}
									</td>
								</tr>
							)}
						</tbody>
					</Table>
					: <Alert variant='warning' className='col-md-8 offset-md-2'>
						<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
						Debe Añadir una factura con su importe.
					</Alert>
				}
				<Form.Group as={Row} className='mt-2 mb-5 mr-3'>
					<Col className='text-right col-form-label col-sm-8'>
						Total de facturas:
					</Col>
					<Col className='col-sm-4' >
						<NumberFormat
							className="form-control"
							name='totalTicket'
							inputmode="numeric"
							thousandSeparator='.' decimalSeparator=','
							decimalScale='2'
							getInputRef={register}
							value={onlyRead ? tickets?.map((v) => v?.amount)
								.flat()?.reduce((prev, next) => Number(prev) + Number(next)) : totalTickets}
							prefix={'$'}
							disabled
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label className='text-right d-flex mandatory-label col-sm-2'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
						Concepto:
					</Form.Label>
					<Col sm='3'>
						<Form.Control
							name='concept'
							disabled={onlyRead}
							value={concept}
							onChange={(e) => setConcept(e.target.value)}





						/>
					</Col>
					<Form.Label className='text-right d-flex mandatory-label' column sm='2'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
						Parciales:
					</Form.Label>
					<Col sm='3' >
						<NumberFormat
							className='form-control text-right'
							name='amount'
							disabled={onlyRead}
							inputmode="numeric"
							value={amount}
							placeholder='ej: $1.234,56'
							onChange={(e) => setAmount(e.target.value)}
							thousandSeparator={'.'}
							decimalSeparator={','}
							decimalScale={2}
						/>
					</Col>
					<ActionIcon 
						className="ml-2" size='lg' 
						toolTipText="Cargar Factura" 
						text='Añadir'
						disabled={!concept || !amount} 
						icon={faPlus} 
						onClick={addConcept}
					/>
					<Col sm='12' className='text-center'>
					</Col>
				</Form.Group>
				{amounts?.length > 0 ?
					<Table striped hover size='sm' responsive className='col-md-8 offset-md-2' >
						<thead>
							<tr>
								<th className='text-center align-middle' width="20%" >Nro.</th>
								<th className='text-center align-middle' width="30%" >Concepto</th>
								<th className='text-center align-middle' width="30%" >Parciales</th>
								<th className='text-center align-middle' width="20%" >Acciones</th>
							</tr>
						</thead>
						<tbody className='text-black-color'>
							{amounts?.map((item, i) =>
								<tr key={item?.id}>
									<td className='text-center align-middle'>
										{(i + 1)}
									</td>
									<td className='text-center align-middle'>
										{item?.concept}
									</td>
									<td className='text-center align-middle'>
										{item?.amount}
									</td>
									<td className='text-center align-middle'>
										{!onlyRead && <ActionIcon className="text-danger ml-2" size='lg' toolTipText="Remover" icon={faTimes} onClick={() => removeComcept(i)} />}
									</td>
								</tr>
							)}
						</tbody>
					</Table>
					: <Alert variant='warning' className='col-md-8 offset-md-2'>
						<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
						Debe Añadir un concepto con su importe.
					</Alert>
				}
				<Form.Group as={Row} className='mt-2 mr-3'>
					<Col className='text-right col-form-label col-sm-8'>
						Total de conceptos:
					</Col>
					<Col className='col-sm-4' >
						<NumberFormat
							className="form-control"
							name='totalTicket'
							inputmode="numeric"
							thousandSeparator='.' decimalSeparator=','
							decimalScale='2'
							getInputRef={register}
							value={onlyRead ? amounts?.map((v) => v?.amount)
								.flat()?.reduce((prev, next) => Number(prev) + Number(next)) : totalAmounts}
							prefix={'$'}
							disabled
						/>
					</Col>
				</Form.Group>
				{ (totalTickets !== totalAmounts) &&
					<Alert variant='danger' className='col-md-8 offset-md-2'>
						<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
						El monto total de Facturas y de Conceptos debe coincidir.
					</Alert>
				}
			</Card>
	</>
};

export default ModalCertificacionObraPublica;