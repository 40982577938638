import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { faAsterisk, faSearch, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getOrderPayCreateIsError } from 'src/redux/orderPay/orderPayReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import FormFieldError from 'src/components/general/FormFieldError';
import { onKeyDownEnter } from 'src/utils/utils';
import { getAdministrativeDocumentData, getAdministrativeDocumentIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';

const ExpedienteControl = props => {

	const { onSearchAdministrativeDocument, invalidRangeCode, yearsOptions, disableAdmExpedientSearch, onBlurSearch } = props;
	const { register, expedientesSeleccionados, orderPay, errors } = useFormContext();

	const orderPayCreateIsError = useSelector(state => getOrderPayCreateIsError(state));
	const administrativeDocumentFinded = useSelector(state => getAdministrativeDocumentData(state));
	const isFetchingAdministrativeDocument = useSelector(state => getAdministrativeDocumentIsFetching(state));

	const [codigo, setCodigo] = useState(orderPay?.administrativeDocument?.codeOrganism);
	const [correlativo, setCorrelativo] = useState(orderPay?.administrativeDocument?.number);
	const [anio, setAnio] = useState(orderPay?.administrativeDocument?.year);

	const codigoRequiredMsg = 'Debe ingresar un código';
    const correlativoRequiredMsg = 'Debe ingresar un código correlativo';
    const anioRequiredMsg = 'Debe ingresar un año';
	const titleRequiredMsg = 'Debe ingresar un asunto';

	const codigoValidationObj = { required: codigoRequiredMsg };
    const correlativoValidationObj = { required: correlativoRequiredMsg };
    const anioValidationObj = { required: anioRequiredMsg };
	const titleValidationObj = { required: titleRequiredMsg };

	const searchNotAllowed = (!codigo || !correlativo || !anio);

	useEffect(() => {
		disableAdmExpedientSearch ? false : orderPayCreateIsError && onSearchAdministrativeDocument();
    }, [orderPayCreateIsError]);

	return <>
		<Form.Group as={Row}>
			<Form.Label htmlFor='expediente-data' className='text-right d-flex mandatory-label' column sm='4'>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Expediente:
			</Form.Label>
			<Col sm='4' className=''>
				<div className="input-group">
					<Form.Control
						type='number'
						placeholder='Código'
						name='codigo'
						disabled={!disableAdmExpedientSearch && expedientesSeleccionados.length || orderPay}
						defaultValue={orderPay?.administrativeDocument?.codeOrganism}
						ref={register(!orderPay ? codigoValidationObj : null)}
						onBlur={() => disableAdmExpedientSearch && onBlurSearch(codigo, correlativo, anio)}
						onChange={event => setCodigo(event.target.value)}
						onKeyDown={event => onKeyDownEnter(event)}
					/>
					<span className='mt-1 mb-1 px-1'> - </span>
					<Form.Control
						type='number'
						name='correlativo'
						placeholder='Correlativo'
						disabled={!disableAdmExpedientSearch && expedientesSeleccionados.length || orderPay}
						defaultValue={orderPay?.administrativeDocument?.number}
						ref={register(!orderPay ? correlativoValidationObj : null)}
						onBlur={() => disableAdmExpedientSearch && onBlurSearch(codigo, correlativo, anio)}
						onChange={event => setCorrelativo(event.target.value)}
						onKeyDown={event => onKeyDownEnter(event)}
					/>
					<span className='mt-1 mb-1 px-1'> / </span>
					<Form.Control
						as='select'
						name='anio'
						defaultValue={orderPay?.administrativeDocument?.year}
						disabled={!disableAdmExpedientSearch && expedientesSeleccionados.length || orderPay}
						onBlur={() => disableAdmExpedientSearch && onBlurSearch(codigo, correlativo, anio)}
						onChange={event => setAnio(event.target.value)}
						ref={register(!orderPay ? anioValidationObj : null)} >
						<option value={''}>Año</option>{
							yearsOptions?.map(item => (<option value={item} key={item.id}>
								{item}
							</option>))
						}
					</Form.Control>
				</div>
				<FormFieldError errors={errors?.codigo} />
				<FormFieldError errors={errors?.correlativo} />
				<FormFieldError errors={errors?.anio} />
			</Col>
			<Col sm={2} className='ml-0 pl-0' >
				<Button
					type='button'
					onClick={onSearchAdministrativeDocument}
					className='text-white-color'
					hidden={disableAdmExpedientSearch}
					disabled={searchNotAllowed || expedientesSeleccionados.length || orderPay}
				>
					<FontAwesomeIcon icon={faSearch} />
				</Button>
			</Col>
		</Form.Group>
		{ disableAdmExpedientSearch &&
			<Form.Group as={Row}>
				<Form.Label htmlFor='expediente-data' className='text-right d-flex mandatory-label' column sm='4'>
					<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Asunto:
				</Form.Label>
				<Col sm='4' className='text-left'>
					<Spinner hidden={!disableAdmExpedientSearch || !isFetchingAdministrativeDocument} className='mx-5' animation='border' />
						{!isFetchingAdministrativeDocument &&
							<>
							<Form.Control
								type='text'
								name='title'
								placeholder='Asunto'
								disabled={!administrativeDocumentFinded || administrativeDocumentFinded?.length || orderPay}
								defaultValue={orderPay?.administrativeDocument?.title || administrativeDocumentFinded?.[0]?.title}
								ref={register(!orderPay? titleValidationObj: null)}
								onKeyDown={event => onKeyDownEnter(event)}
							/>
							{ !orderPay && administrativeDocumentFinded?.[0]?.title && <div className='d-flex alert alert-warning m-1' role='alert'>
								<FontAwesomeIcon className='text-black mr-3 mt-1' icon={faExclamationTriangle} />
								<p className='m-0'>El Expediente ingresado se encuentra registrado previamente y posee movimientos. Por favor corrobore si es el indicado</p>
							</div>}
							<FormFieldError errors={errors?.title} />
							</>
						}
				</Col>
			</Form.Group>
		}
		{!orderPay && invalidRangeCode &&
			<Col md={4} className="offset-md-4 alert alert-danger form-field-error mb-0 mt-1 py-2" role="alert">
				<strong>¡Importante!</strong> El código de organismo del expediente debe estar entre los rangos 1000 y 9999.
			</Col>
		}
    </>
};

export default ExpedienteControl;