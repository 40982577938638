import * as ACTIONS from './exerciseActions';
import * as SERVICES from 'src/services/exerciseService';
import * as LEGALINSTRUMENTSERVICE from 'src/services/legalInstrumentServices'
import { downloadStreamFile } from 'src/utils/utils';
import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

// Try list exercises
export const tryListExercises = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listExercisesRequest());

		if(accessToken){
			const response = await SERVICES.listExercises(accessToken, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.listExercisesRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listExercisesRequestFailure(response?.response?.data));
				showError(dispatch, `Error al obtener los ejercicios. Detalle: ${response?.response?.data || response}`);
			}

			return response;
		}

	};
};



// Try create exercise
export const tryCreateExercise = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.createExerciseRequest());

		if(accessToken){
			
			const response = await SERVICES.createExercise(accessToken, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.createExerciseRequestSuccess(response?.data));
				showSuccess(dispatch, `Ejercicio creado correctamente.`);
			}
			else {
				dispatch(ACTIONS.createExerciseRequestFailure(response?.response?.data));
				showError(dispatch, `Error al crear el ejercicio. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
};



// Try edit exercise
export const tryEditExercise = (exerciseId, params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.editExerciseRequest());

		if(accessToken){

			const response = await SERVICES.editExercise(accessToken, exerciseId, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.editExerciseRequestSuccess());
				showSuccess(dispatch, `Ejercicio editado correctamente.`);
			}
			else {
				dispatch(ACTIONS.editExerciseRequestFailure(response?.response?.data));
				showError(dispatch, `Error al editar el ejercicio. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
};



// Try get services by exercise ID
export const tryGetServicesByExerciseId = exerciseId => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getServicesByExerciseIdRequest(exerciseId));

		if(accessToken){

			const response = await SERVICES.getServicesByExerciseId(accessToken, exerciseId);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.getServicesByExerciseIdRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getServicesByExerciseIdRequestFailure(response?.response?.data));
				showError(dispatch, `Error al obtener los servicios del ejercicio. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
};



/// Try add service to exercise
export const tryAddServiceToExercise = (exerciseId, serviceId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		if(accessToken){
		dispatch(ACTIONS.addServiceToExerciseRequest(exerciseId));


		
		const response = await SERVICES.addServiceToExercise(accessToken, exerciseId, serviceId);
		
		if (response?.status == 200) {
			dispatch(ACTIONS.addServiceToExerciseRequestSuccess(response?.data));
			showSuccess(dispatch, 'El servicio se agregó correctamente al ejercicio.');
		}
		else {
			dispatch(ACTIONS.addServiceToExerciseRequestFailure(response?.response?.data));
			showError(dispatch, `Error al asociar el servicio con el ejercicio. Detalle: ${response?.response?.data?.message || response}`);
		}
		
		return response;
	}
	};
};



/// Try delete service from exercise
export const tryDeleteServiceFromExercise = (exerciseId, serviceId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		if(accessToken){
			
			dispatch(ACTIONS.deleteServiceFromExerciseRequest(exerciseId));
	
			const response = await SERVICES.deleteServiceFromExercise(accessToken, exerciseId, serviceId);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.deleteServiceFromExerciseRequestSuccess(response?.data));
				showSuccess(dispatch, 'El servicio se removió correctamente');
			}
			else {
				dispatch(ACTIONS.deleteServiceFromExerciseRequestFailure(response?.response?.data));
				showError(dispatch, `Error al remover el servicio del ejercicio. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
};

/// Try Active service from exercise
export const tryActiveServiceFromExercise = (exerciseId, serviceId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		if(accessToken){

			dispatch(ACTIONS.activeServiceFromExerciseRequest(exerciseId));
	
			const response = await SERVICES.activeServiceFromExercise(accessToken, exerciseId, serviceId);
			//Hotfix, coreapi/presupuesto deberia enviar correctamente dentro de un json en vez de array  
			if( !!response?.response?.data && response?.response?.status != 200){
				dispatch(ACTIONS.activeServiceFromExerciseRequestFailure(response?.data));
				const msjError = extractJsonMsj(response.response.data.message);
				showError(dispatch, msjError);
			}
			else if (response?.status == 200) {
				dispatch(ACTIONS.activeServiceFromExerciseRequestSuccess(response?.data));
				showSuccess(dispatch, 'El servicio cambio el estado correctamente');
			}
			else {
				dispatch(ACTIONS.activeServiceFromExerciseRequestFailure(response?.response?.data));
				showError(dispatch, `Error al cambiar el estado servicio del ejercicio. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
};

const extractJsonMsj = (jsonString = '') =>{

	const jsonAsString = jsonString.slice(jsonString.indexOf("["),jsonString.length);
	const jsonRes = JSON.parse(jsonAsString);
	return jsonRes.length && jsonRes[0].message;
}


// Try closed exercise
export const tryClosedExercise = (exerciseId, paramsToSend) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.closedExerciseRequest());

		if(accessToken){

			const response = await SERVICES.closedExercise(accessToken, exerciseId, paramsToSend);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.closedExerciseRequestSuccess(response?.data));
				showSuccess(dispatch, `Ejercicio cerrado correctamente.`);
			}
			else {
				dispatch(ACTIONS.closedExerciseRequestFailure(response?.response?.data));
				showError(dispatch, `Error al cerrar el ejercicio. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
};

//Search file 
export const trySearchLegalInstrumentExercise = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listLegalInstrumentExerciseRequest());

		if(accessToken){

			const response = await LEGALINSTRUMENTSERVICE.getFileOfLegalInstruments(accessToken, params);
	
			if (response?.status == 200) {
				downloadStreamFile(response, 'instrumento_legal_modificaciones_ejercicio');
				dispatch(ACTIONS.listLegalInstrumentExerciseRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listLegalInstrumentExercisRequestFailure(response?.response?.data));
				showError(dispatch, `Error al recuperar el instrumento legal. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
};


//Legal Instrument Exercise types
export const tryEditLegalInstrumentExercise = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.editLegalInstrumentExerciseRequest());

		if(accessToken){
			
			const response = await SERVICES.getEditLegalInstrumentExercise(accessToken, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.editLegalInstrumentExerciseRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.editLegalInstrumentExerciseRequestFailure(response?.response?.data));
				showError(dispatch, `Error al recuperar las modificaciones del ejercicio y el instrumento legal. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
};

// Try Enable exercise
export const tryEnableExercise = (exerciseId, paramsToSend) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.enableExerciseRequest());

		if(accessToken){

			const response = await SERVICES.enableExercise(accessToken, exerciseId, paramsToSend);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.enableExerciseRequestSuccess(response?.data));
				showSuccess(dispatch, `Ejercicio habilitado correctamente.`);
			}
			else {
				dispatch(ACTIONS.enableExerciseRequestFailure(response?.response?.data));
				showError(dispatch, `Error al intentar habilitar el ejercicio. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
};

// Try List exercise by ID exercises
export const tryListExercisesById = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listExercisesByIdRequest());

		if(accessToken){
			const response = await SERVICES.listExercisesById(accessToken, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.listExercisesByIdRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listExercisesByIdRequestFailure(response?.response?.data));
				showError(dispatch, `Error al obtener los ejercicios por ID. Detalle: ${response?.response?.data || response}`);
			}
		}

	};
};

// Try List Periods Monthly exercises
export const tryListPeriodsMonthly = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listPeriodsMonthlyRequest());

		if(accessToken){
		const response = await SERVICES.listPeriodsMonthly(accessToken, params);
		
			if (response?.status == 200) {
				dispatch(ACTIONS.listPeriodsMonthlyRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listPeriodsMonthlyRequestFailure(response?.response?.data));
				showError(dispatch, `Error al obtener los ejercicios por mes. Detalle: ${response?.response?.data || response}`);
			}

			return response;
		}
		
	};
};

// Try Report enabled date monthly period - Report Credit Execution
export const tryGetReportEnableDateMonthlyPeriodCreditExecution = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportEnableDateMonthlyPeriodCreditExecutionRequest());

		if(accessToken){
		const response = await SERVICES.getReportEnableDateMonthlyPeriodCreditExecution(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportEnableDateMonthlyPeriodCreditExecutionRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getReportEnableDateMonthlyPeriodCreditExecutionRequestFailure(response?.response?.data));
				showError(dispatch, `Error al obtener la habilitación de los ejercicios por mes. Detalle: ${response?.response?.data || response}`);
			}

			return response;
		}
		
	};
};

// Try Get Active administrative service from exercise
export const tryGetActiveAdminsitrativeServiceFromExercise = (exerciseId, serviceId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		if(accessToken){

			dispatch(ACTIONS.getActiveAdminsitrativeServiceFromExerciseRequest(exerciseId));
	
			const response = await SERVICES.getActiveAdministrativeServiceFromExercise(accessToken, exerciseId, serviceId);

			if (response?.status == 200) {
				dispatch(ACTIONS.getActiveAdminsitrativeServiceFromExerciseRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getActiveAdminsitrativeServiceFromExerciseRequestFailure(response?.response));
				showError(dispatch, `Error al buscar el estado del servicio administrativo del ejercicio. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
};