import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card } from "react-bootstrap";
import { tryAddNewConcept } from "src/redux/conceptRequest/conceptRequestActionCreator";
import { push } from "connected-react-router";
import ConceptFormAM from "./ConceptFormAM";
import { CONCEPT_NEW, CONCEPT_LIST } from "src/utils/constants";
import { getConceptListIsFetching } from "src/redux/conceptRequest/conceptRequestReducer";

const ConceptNewPage = () => {
  const dispatch = useDispatch();
  const redirectToConceptList = () => {
    dispatch(push(CONCEPT_LIST));
  };
  const redirectToNewConceptPage = () => {
    dispatch(push(CONCEPT_NEW));
  };
  const isFetchingConceptList = useSelector((state) =>
    getConceptListIsFetching(state)
  );
  const onFormSubmit = (data, createAgain) => {
    dispatch(tryAddNewConcept(data)).then(() =>
      createAgain ? redirectToNewConceptPage() : redirectToConceptList()
    );
  };

  return (
        <Container fluid>
          <Card className="mb-3">
            <Card.Header className="h6">
              Registro de Nuevo Concepto de Liquidación de Haberes
            </Card.Header>
            <Card.Body>
              <ConceptFormAM
                onFormSubmit={onFormSubmit}
                isFetching={isFetchingConceptList}
              />
            </Card.Body>
          </Card>
        </Container>
  );
};

export default ConceptNewPage;