import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Alert, Form, Spinner } from 'react-bootstrap';
import { faPlus, faExclamationTriangle, faFilter, faBroom, faEdit, faTrash, faSearch, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { newProvisionOrder, provisionOrder } from 'src/utils/label';
import ActionIcon from 'src/components/general/ActionIcon';
import { useForm } from 'react-hook-form';
import { getFilterObject } from 'src/redux/globalData/globalDataReducer';
import { isNotEmptyArray } from 'src/services/validationService';
import { setFilterObject,  clearFilterObject} from 'src/redux/globalData/globalDataActions';
import AppLoading from 'src/components/common/AppLoading';
import RCPagination from 'src/components/common/RCPagination';
import { clearProvisionOrderListData, setProvisionOrderDetail, setProvisionOrderToEdit } from 'src/redux/provisionOrder/provisionOrderActions'
import { getProvisionOrderListData, getProvisionOrderListIsFetching } from 'src/redux/provisionOrder/provisionOrderReducer';
import { tryDeleteProvisionOrder, tryGetProvisionOrderDetails, tryProvisionOrderById  } from 'src/redux/provisionOrder/provisionOrderActionCreator';
import { PROVISION_ORDER_NEW, PROVISION_ORDER_EDIT, PROVISION_ORDER_DETAILS } from 'src/utils/constants';

const ProvisionOrderListPage = () => {
    const dispatch = useDispatch();
	const { handleSubmit, register, reset, getValues } = useForm();
	const figurativeExpedituresRefContainer = useRef(null);
	const colSpanCellsNumber = figurativeExpedituresRefContainer?.current?.cells.length;

	// Permissions
	const globalFilterObject = useSelector(state => getFilterObject(state)?.data);

	// Fetch Wage Template
	const provisionOrderListData = useSelector(state => getProvisionOrderListData(state));
	const isFetching = useSelector(state => getProvisionOrderListIsFetching(state));
	const records = provisionOrderListData?.records;
	const hasRecords = isNotEmptyArray(records);

	// Pagination
	const page = provisionOrderListData?.metadata?.page + 1;
	const totalItems = provisionOrderListData?.metadata?.filteredCount;
	const perPage= provisionOrderListData?.metadata?.pageSize;
	
	const defaultParams = {
		filter: {
			page: 0,
			size: 10,
			sort: 'id,asc',
		}
	};
	const loadAllData = () => {
		dispatch(tryGetProvisionOrderDetails(defaultParams));
	};
	const onPageChange = (pNumber) => {
		const object = {
			filter: {...globalFilterObject},
			page: pNumber - 1
		};
		fetchList(object);
	};
	const onClickNewProvisionOrder = () => {
		dispatch(push(PROVISION_ORDER_NEW));
	};
	const onClickDetail = provisionOrder => {
        dispatch(setProvisionOrderDetail(provisionOrder));
        dispatch(push(PROVISION_ORDER_DETAILS));
    };
    const onClickEdit = provisionOrder => {
        dispatch(tryProvisionOrderById(provisionOrder?.id)).then(
			(response) => {
				dispatch(setProvisionOrderToEdit(response));
                dispatch(push(PROVISION_ORDER_EDIT));
			}
		);        
    };
	const onClickDelete = idProvisionOrder => {
		swal({
			title: 'Confirmación',
			text: '¿Realmente desea eliminar la orden de provisión?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(tryDeleteProvisionOrder(idProvisionOrder?.id)).then(
					response => {
						if (response?.status == 204) {
							dispatch(clearProvisionOrderListData());
							loadAllData();
						}
					}
				);
			}
		});
	};	
	const onSubmitForm = (data) => {
		if(!isFetching) {
			const filter = {
				administrativeServiceId: servicio,
				name: data?.name,
				type: contratacion,
				...defaultParams.filter
			}
			dispatch(tryGetProvisionOrderDetails(filter));
		}
	}
	const fetchList = params => {
		dispatch(tryGetProvisionOrderDetails(params));
		dispatch(setFilterObject(params?.filter));
	};
	const cleanFilters = () => {
		dispatch(clearFilterObject());
		reset();
		fetchList(defaultParams);
    };

	useEffect(() => {
		loadAllData();
		cleanFilters();
	}, []);

    return <>
    <Container fluid className='lisrtin'>
		<Card className='mb-5'>
			<Card.Header className='h6'>
				{provisionOrder}
			</Card.Header>
			<Card.Body>
				<div className='d-flex align-items-center mb-3'>
                    <Button size='sm' className='mb-3 btn-success' variant='secondary' onClick={onClickNewProvisionOrder}>
                        <FontAwesomeIcon icon={faPlus} className='mr-1' />
                            {newProvisionOrder}
                    </Button>
				</div>
				<Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off' >
					<Table striped hover size='sm'>
						<thead>
							<tr ref={figurativeExpedituresRefContainer}>
								<th className='text-center align-middle' width='12%'>Número expediente</th>
								<th className='text-center align-middle' width='12%'>Número orden</th>
								<th className='text-center align-middle' width='15%'>Iniciador</th>
								<th className='text-center align-middle' width='15%'>Proveedor</th>
								<th className='text-center align-middle' width='15%'>Instrumento legal</th>
								<th className='text-center align-middle' width='15%'>Contratación</th>
								<th className='text-center align-middle' width='16%'>Acciones</th>
							</tr>
							<tr className='secondary'>
								<th>
									<Form.Control className='text-center' type='string' name='name' ref={register}
										defaultValue={provisionOrderListData?.name ? globalFilterObject?.name : ""}
									/>
								</th>
								<th>
									<Form.Control className='text-center' type='string' name='name' ref={register}
										defaultValue={provisionOrderListData?.name ? globalFilterObject?.name : ""}
									/>
								</th>
								<th>
									<Form.Control className='text-center' type='string' name='name' ref={register}
										defaultValue={provisionOrderListData?.name ? globalFilterObject?.name : ""}
									/>
								</th>
                                <th>
									<Form.Control className='text-center' type='string' name='name' ref={register}
										defaultValue={provisionOrderListData?.name ? globalFilterObject?.name : ""}
									/>
								</th>
                                <th>
									<Form.Control className='text-center' type='string' name='name' ref={register}
										defaultValue={provisionOrderListData?.name ? globalFilterObject?.name : ""}
									/>
								</th>
                                <th>
									<Form.Control className='text-center' type='string' name='name' ref={register}
										defaultValue={provisionOrderListData?.name ? globalFilterObject?.name : ""}
									/>
								</th>
								<th className='text-center align-middle'>
									<div className='d-flex justify-content-around'>
										<ActionIcon
											size="lg"
											id="search-button"
											className="btn-primary search-button text-white-color"
											toolTipText="Filtrar"
											icon={faFilter}
											type='submit'
											onSubmit={onSubmitForm}
										/>
										<ActionIcon
											size="lg"
											id="clean-filter"
											className="btn-primary clean-filter text-white-color"
											toolTipText="Limpiar filtros"
											icon={faBroom}
											type='reset'
											onClick={() => cleanFilters()}
										/>
									</div>
								</th>
							</tr>
						</thead>
						<tbody className='text-black-color'>
							{
								hasRecords ?
									records?.map(item => {
										return (
											<tr key={item?.id}>
												<td className='text-center align-middle truncate maxTruncate' >
                                                    {item?.administrativeDocument?.codeOrganism}-{item?.administrativeDocument?.number}/{item?.administrativeDocument?.year} 
												</td>
												<td className='text-center align-middle truncate maxTruncate' >
													{item?.number}
												</td>
												<td className='text-center align-middle truncate maxTruncate' >
													{item?.initiator}
												</td>
                                                <td className='text-center align-middle truncate maxTruncate' >
													{item?.beneficiary?.type}
												</td>
                                                <td className='text-center align-middle truncate maxTruncate' >
													{item?.legalInstrument?.legalInstrumentType?.name}
												</td>
                                                <td className='text-center align-middle truncate maxTruncate' >
													{item?.acquisitionType.replace(/_/g, ' ')}
												</td>
												<td className='text-center align-middle p-0'>
													<ActionIcon
														size='lg'
														className={ 'mx-2' }
														toolTipText='Detalle de Orden de provisiones'
														icon={faSearch}
														onClick={() => onClickDetail(item)}
													/>
                                                    <ActionIcon 
                                                        size='lg' 
														className={ 'mx-2' }
                                                        toolTipText='Descargar Orden de provisiones' 
                                                        icon={faDownload} 
                                                        onClick={() => handleDownload(item?.id)} 
                                                    />
													<ActionIcon
														size='lg'
														className={ 'mx-2' }
														toolTipText='Editar Orden de provisiones'
														icon={faEdit}
														onClick={() => onClickEdit(item)}
													/>
													<ActionIcon
														size="lg"
														className={ 'mx-2' }
														toolTipText='Eliminar Orden de provisiones'
														icon={faTrash}
														onClick={() => onClickDelete(item) }
													/>
												</td>
											</tr>);
									})
									:
									<tr>
										<td colSpan={colSpanCellsNumber} className='text-center'>
											{
												!isFetching &&
												<Alert variant='info' className='mb-0'>
													<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
													No hay Ordenes de provisiones o no se posee permiso de acceso a las mismas.
												</Alert>
											}
										</td>
									</tr>
							}
						</tbody>
					</Table>
				</Form>
				<RCPagination
					activePage={page}
					itemsCountPerPage={perPage}
					totalItemsCount={totalItems}
					totalItems={totalItems}
					thePage={page}
					onChange={onPageChange}
					innerClass="justify-content-center"
				/>
			</Card.Body>
			</Card>
		<AppLoading isLoading={ isFetching } />
	</Container>;
    </>
};

export default ProvisionOrderListPage;