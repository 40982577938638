import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Button, Table} from 'react-bootstrap';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { PROVISION_ORDER_LIST } from 'src/utils/constants';
import { getProvisionOrderDetail } from 'src/redux/provisionOrder/provisionOrderReducer';

const ProvisionOrderDetailPage = () => {
    const dispatch = useDispatch();
	const provisionOrderDetail = useSelector((state) => getProvisionOrderDetail(state));
	const onClickBackButton = () => {
		dispatch(push(PROVISION_ORDER_LIST));	
	};

	return <>
		<Container fluid>
			<Card className='mb-3'>
				<Card.Header className='h6'>
					Detalle de orden de provisión
				</Card.Header>
                <Card.Body>
                    <Table className='text-black-color' striped borderless hover>
						<tbody>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Número expediente:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.administrativeDocument?.codeOrganism} - {provisionOrderDetail?.administrativeDocument?.number} / {provisionOrderDetail?.administrativeDocument?.year}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Número orden:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.number}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Iniciador:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.initiator}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Proveedor:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.beneficiary?.type}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Instrumento legal:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.legalInstrument?.legalInstrumentType?.name}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Contratación:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.acquisitionType.replace(/_/g, ' ')}
								</td>
							</tr>
						</tbody>
					</Table>
                    <div className='text-center'>
						<Button variant='danger' size='lg' className='my-3' onClick={onClickBackButton}>
							<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
							Volver
						</Button>
					</div>
                </Card.Body>
            </Card>
	    </Container>
    </>;
};

export default ProvisionOrderDetailPage;