import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useSelector, useDispatch } from "react-redux";
import { Container, Card, Form, Row, Col, Button, Alert } from "react-bootstrap";

import PageTitle from "src/components/general/PageTitle";
import AppLoading from 'src/components/common/AppLoading';

import { tryGetAccrualBasisReport } from 'src/redux/reports/reportsActionCreactor';

import { getReportAccrualBasisIsFetching } from 'src/redux/reports/reportsReducer';
import { getGlobalDataSelectedPeriod, getReportDatesData } from "src/redux/globalData/globalDataReducer";

import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { notice } from 'src/utils/label';
import { getClearObject } from 'src/utils/utils';

import { push } from 'connected-react-router';
import { ROUTE_PATHS as ROUTES } from 'src/routes';

import RangeDates from 'src/components/common/RangeDates';

const ReportAccrualBasis = () => {

    const dispatch = useDispatch();

    const hookFormMethods = useForm();

    const reportDates = useSelector(state => getReportDatesData(state));
    const globalSelectedPeriod = useSelector((state) => getGlobalDataSelectedPeriod(state));
    const reportIsFetching = useSelector((state) => getReportAccrualBasisIsFetching(state));

    const [dateFrom, setDateFrom] = useState(reportDates?.dateFrom);
    const [dateTo, setDateTo] = useState(reportDates?.dateTo);
    const [dateHasErrors, setDateHasErrors] = useState(false);

    const [alertReportGenerate, setAlertReportGenerate] = useState(false);

    const allowGetReport = (
        globalSelectedPeriod?.id &&
        !dateHasErrors &&
        dateTo &&
        dateFrom
    );

    const getReport = outputFormat => {

        if (globalSelectedPeriod) {
            setAlertReportGenerate(false);
            const params = {
                exerciseId: globalSelectedPeriod?.id,
                dateFrom,
                dateTo,
                outputFormat
            };

            const paramsToSend = getClearObject(params)

            dispatch(tryGetAccrualBasisReport(paramsToSend)).then(response => {
                if (response?.status == 200) {
                    setAlertReportGenerate(true);
                };
            });
        }
    };

    const onClickXLS = () => getReport(2);

    return (
        <Container fluid>
            <Card className="mb-5">

                <PageTitle text="Reporte Base Devengado" />

                <Container fluid>

                    <Card className="mb-3">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={8}>
                                        <Form.Group>
                                            <Form.Label className='text-black-color'>
                                                Ejercicio
                                            </Form.Label>

                                            <Form.Control
                                                type='number'
                                                name='globalSelectedPeriod'
                                                id='globalSelectedPeriod'
                                                value={globalSelectedPeriod?.year}
                                                readOnly
                                            />
                                        </Form.Group>

                                        <FormContext {...hookFormMethods}>
                                            <RangeDates
                                                {...{ dateFrom, setDateFrom, dateTo, setDateTo, setDateHasErrors }}
                                            />
                                        </FormContext>

                                        {
                                            alertReportGenerate && !reportIsFetching &&
                                            <Alert key={'1'} variant={'primary'} className='mt-4'>
                                                <b className='text-black-color' >{notice}:</b> El reporte se está generando en segundo plano y estará disponible en el módulo de <a
                                                    className='font-italic text-info cursor-pointer'
                                                    onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED))}>
                                                    reportes generados.
                                                </a>
                                            </Alert>
                                        }

                                        <Form.Group className='d-flex justify-content-end mt-4 mb-4'>
                                            <Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
                                                <FontAwesomeIcon icon={faFileExcel} className='mr-2' />
                                                Generar XLS
                                            </Button>
                                        </Form.Group>

                                        <AppLoading
                                            isLoading={reportIsFetching}
                                        />

                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>

                </Container>

            </Card>
        </Container>
    )
};

export default ReportAccrualBasis;