import React, {forwardRef, useEffect,useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Col, Row, Card } from 'react-bootstrap';
import { faAsterisk, faChevronDown, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { getListAllServiceAdministrativeByUserData} from "src/redux/administrativeService/administrativeServiceReducer";
import DropdownList from 'react-widgets/lib/DropdownList';
import FormFieldError from 'src/components/general/FormFieldError';
import { getAdministrativeDocumentYearsOptions, parseIntOrUndefined, singleNumberFormatter } from 'src/utils/utils';
import { serviceRequiredMsg, organismCodeDropDownRequiredMsg, descriptionRequiredMsg, yearRequiredMsg,
	numberRequiredOrderPayMsg, dateRequiredOrderPayMsg, penaltyFeeRequiredOrderPayMsg } from 'src/utils/label.js'
import ActionIcon from 'src/components/general/ActionIcon';
import { isNotEmptyArray } from 'src/services/validationService';
import TicketTable from 'src/components/pages/OrderPay/tables/TicketTable';
import { getOrderTypeDate } from 'src/redux/orderPay/orderPayReducer';

// eslint-disable-next-line react/display-name
const ModalReceptionAct = props => {

	const receiptDate = 'Debe ingresar una fecha';

	const { organismList, messagesDropDown,  
			handleModalAdmServiceIdOnChange, codeOrganism, handleOrganismOnChange, tipo, onlyRead } = props;

	const [ticket, setTicket] = useState("");
	const [amount, setAmount] = useState("");
	const [tempAdmServiceId, setTempAdmServiceId] = useState();
	const initialDate = useSelector(state => getOrderTypeDate(state));
	const [finalDate, setFinalDate] = useState("");
	const [provisionOrderNumber, setProvisionOrderNumber] = useState(props?.data?.provisionOrder?.number || '');
	const [provisionOrderYear, setProvisionOrderYear] = useState(props?.data?.provisionOrder?.year || '');

	const handlerProvionOrderNumber = (value) => {
		setProvisionOrderNumber(value);
		delete errors?.orderProvisionYear
	};

	const handlerProvionOrderYear = (value) => {
		setProvisionOrderYear(parseIntOrUndefined(value));
		delete errors?.orderProvisionNumber
	};

	const [penaltyFeeValue, setPenaltyFeeValue] = useState((props?.data?.penaltyFee || 0)?.toString()?.replaceAll('.', ','));
	const maxValue = 99999999999;

	const withValueLimit = (inputObj) => {
        const { value } = inputObj;
        if (value <= maxValue)
            return inputObj;
    };

	//Para reutilizar modal en detalle de OP
	let register, errors, codeAdministrative, getValues;
	if(!props.data || !onlyRead){
		register = useFormContext().register;
		errors = useFormContext().errors;
		codeAdministrative = useFormContext().codeAdministrative;
		getValues = useFormContext().getValues;
	}else{
		register = useForm().register;
		errors = useForm().errors;
		codeAdministrative = useForm().codeAdministrative;
		getValues = useForm().getValues;
	}

	const yearsOptions = getAdministrativeDocumentYearsOptions();
	const organismDropDownObjValidation = { required: organismCodeDropDownRequiredMsg };
	const serviceValidationObj = { required: serviceRequiredMsg };
	const descriptionValidation = { required: descriptionRequiredMsg };

	const yearValidation = { required: yearRequiredMsg };
	const numberValidation = { required: numberRequiredOrderPayMsg };
	const provisionOrderNumberValidation = { required: provisionOrderYear ? numberRequiredOrderPayMsg : false};
	const provisionOrderYearValidation = { required: provisionOrderNumber ? yearRequiredMsg : false};
	const penaltyFeeValidation = { required: penaltyFeeRequiredOrderPayMsg };
	const receiptDateObjValidation = { 
		required: receiptDate,
		validate: () => {
			if (initialDate < finalDate){
				return 'La fecha del recibo es superior a la fecha de la orden de pago';
			}
		}
	};

	const administrativeServiceData = useSelector( state => getListAllServiceAdministrativeByUserData(state) )
		?.sort( (a,b) => a.code>b.code ? 1 : -1 )
		?.map( item => ({
			displayName: `${item?.code} - ${item?.name} `,
			id: item?.id,
			name: item?.name
		}) );

	const preselectedAdmService = administrativeServiceData?.find((item) => item.id == props?.data?.provisionOrder?.administrativeServiceId) || administrativeServiceData?.find(i=>i.id==codeAdministrative);

	const selectedOrganism = organismList?.find((item) => item.id == props?.data?.codeOrganism);

	const handleActReceptionService = (value) => {
		handleModalAdmServiceIdOnChange(value);
		setTempAdmServiceId(value);
    };

	const ticketHandler = () => {
		props?.ticketObj?.setTicketData(prev => [...prev, {ticket, amount: singleNumberFormatter(amount)}])
		setTicket("");
		setAmount("");
		delete errors?.ticketAmount;
	};

	const onSelectDate = () => {
		setFinalDate(getValues()?.receiptDate);
		register(receiptDateObjValidation)
    };

	useEffect(() => {
		return () => {
			!props?.data && handleOrganismOnChange();
			!props?.data && handleModalAdmServiceIdOnChange();
			props?.ticketObj?.setTicketData([]);
		}
	}, []);

	return <>
		<Form.Group as={ Row }>
			<Form.Label className='d-flex mandatory-label' column sm='3'>
				<FontAwesomeIcon icon={ faAsterisk } size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Organismo:
			</Form.Label>
				<Col sm='8'>
					<DropdownList
						valueField='id'
						filter='contains'
						placeholder='Seleccione un organismo...'
						data={organismList}
						allowCreate='onFilter'
						textField='nameToShow'
						as='select'
						disabled={onlyRead}
						defaultValue={props?.data && selectedOrganism?.id}
						onChange={ value => handleOrganismOnChange(value?.id) }
						messages={ messagesDropDown }
						selectIcon={ <FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/> }
						searchIcon={ <FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/> }
					>
					</DropdownList>
					<FormFieldError errors={errors?.codeOrganism} />
					<Form.Control hidden ref={register(organismDropDownObjValidation)} name='codeOrganism' value={codeOrganism} />
				</Col>
		</Form.Group>

		<Form.Group as={ Row }>
			<Form.Label className='d-flex mandatory-label' column sm='3'>
				<FontAwesomeIcon icon={ faAsterisk } size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Número:
			</Form.Label>
			<Col sm='6'>
				<Form.Control
					disabled={onlyRead}
					type='number'
					defaultValue={props?.data?.number}
					name='number'
					ref={register(numberValidation)}
				/>
				<FormFieldError errors={errors?.number} />
			</Col>
			<Col sm='2'>
				<Form.Control
					as='select'
					name='year'
					ref={register(yearValidation)}
					disabled={onlyRead}
					defaultValue={props?.data?.year}
				>
					<option value={''} selected>Año</option>
					{
						yearsOptions?.map( (item,idx )=> ( <option key={ idx } value={ item }>
							{ item }
						</option>
						) )
					}
				</Form.Control>
				<FormFieldError errors={errors?.year} />
			</Col>
		</Form.Group>

		<Form.Group as={ Row }>
			<Form.Label className='d-flex mandatory-label' column sm='3'>
				<FontAwesomeIcon icon={ faAsterisk } size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Fecha:
			</Form.Label>
			<Col sm='8'>
				<Form.Control
					type='date'
					disabled={onlyRead}
					defaultValue={props?.data?.receiptDate}
					name='receiptDate'
					ref={register(receiptDateObjValidation)}
					onChange={onSelectDate}
				/>
				<FormFieldError errors={errors?.receiptDate} />
			</Col>
		</Form.Group>

		<Form.Group as={ Row }>
			<Form.Label className='d-flex mandatory-label' column sm='3'>
				<FontAwesomeIcon icon={ faAsterisk } size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Descripcion:
			</Form.Label>
			<Col sm='8'>
				<Form.Control
					name='description'
					disabled={onlyRead}
					defaultValue={props?.data?.description}
					ref={register(descriptionValidation)}
					maxLength='255'
				/>
				<FormFieldError errors={errors?.description} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} className={errors?.ticketAmount && 'mb-0'}>
			<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Factura / Remito:
			</Form.Label>
			<Col sm='3'>
				<Form.Control
					name='ticket'
					disabled={onlyRead}
					defaultValue={props?.data?.ticket}
					onChange={(e) => setTicket(e.target.value)}
					value={ticket}
				/>
			</Col>
			<Form.Label className='text-right d-flex mandatory-label' column sm='2'>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Importe
			</Form.Label>
			<Col sm='3'>
				<NumberFormat
					className="form-control text-right"
					name='amount'
					disabled={onlyRead}
					inputmode="numeric"
					value={amount}
					placeholder='ej: $1.234,56'
					defaultValue={props?.data?.amount}
					onChange={(e) => setAmount(e.target.value)}
					thousandSeparator='.' decimalSeparator=','
					decimalScale='2'
				/>
			</Col>
			<ActionIcon className="ml-2" size='lg' toolTipText="Cargar Factura" disabled={!ticket || !amount} icon={faPlus} onClick={ticketHandler}/>
		</Form.Group>

		<Form.Group as={Row} hidden={!errors?.ticketAmount}>
			<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
				&nbsp;
			</Form.Label>
			<Col sm='8'>
				<FormFieldError errors={errors?.ticketAmount} />
				<Form.Control hidden ref={register({ required: "Debe ingresar una factura" })} name='ticketAmount' value={props?.ticketObj?.ticketData} />
			</Col>
		</Form.Group>
		{	(props?.data || isNotEmptyArray(props?.ticketObj?.ticketData)) &&
				<TicketTable
					ticketObj={props?.ticketObj}
					data={props?.data}
					onlyRead={onlyRead}
				/>
		}

		<Form.Group as={ Row }>
			<Form.Label className='d-flex mandatory-label' column sm='3'>
				<FontAwesomeIcon icon={ faAsterisk } size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Multa:
			</Form.Label>
			<Col sm='8'>
				<NumberFormat
					className="form-control text-right"
					name='penaltyFee'
					inputmode="numeric"
					disabled={onlyRead}
					defaultValue={props?.data?.penaltyFee || 0}
					placeholder='ej: $1.234,56'
					thousandSeparator='.' decimalSeparator=','
					decimalScale='2'
					isAllowed={(inputObj)=> withValueLimit(inputObj)}
					value={penaltyFeeValue}
					onChange={(e) =>{
							setPenaltyFeeValue(e.target.value);
							delete errors?.penaltyFee
						}
					}
				/>
				<FormFieldError errors={errors?.penaltyFee} />
				<Form.Control hidden ref={register(penaltyFeeValidation)} name='penaltyFee' value={penaltyFeeValue} />
			</Col>
		</Form.Group>

		<Card variant='success' className='my-3'>
            <Card.Header>
                 <h6 className='mb-0 text-center text-white'>
					Orden de Provisión
                </h6>
            </Card.Header>
        </Card>

		<Form.Group as={ Row }>
			<Form.Label className='d-flex mandatory-label' column sm='3'>
				Servicio:
			</Form.Label>
			<Col sm='8'>
				<DropdownList
					filter='contains'
					placeholder='Seleccione un servicio...'
					data={administrativeServiceData}
					allowCreate='onFilter'
					name='admService'
					textField='displayName'
					onChange={ item => handleActReceptionService(item.id)}
					className='w-100'
					disabled={onlyRead}
					defaultValue={preselectedAdmService}
					messages={ messagesDropDown }
					selectIcon={ <FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/> }
					searchIcon={ <FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/> } >
				</DropdownList>
				<FormFieldError errors={errors?.admService} />
                <Form.Control hidden ref={register(serviceValidationObj)} name='admService' value={tempAdmServiceId ? tempAdmServiceId : preselectedAdmService?.id} />
			</Col>
		</Form.Group>

		<Form.Group as={ Row }>
			<Form.Label className='d-flex mandatory-label' column sm='3'>
				Número:
			</Form.Label>
			<Col sm='8'>
				<Form.Control
					type='number'
					name='orderProvisionNumber'
					disabled={onlyRead}
					defaultValue={props?.data?.provisionOrder?.number}
					ref={register(provisionOrderNumberValidation)}
					onChange={event => handlerProvionOrderNumber(event.target.value)}
				/>
				<FormFieldError errors={errors?.orderProvisionNumber} />
			</Col>
		</Form.Group>

		<Form.Control hidden ref={register} name='type' value={tipo} />

		<Form.Group as={ Row }>
			<Form.Label className='d-flex mandatory-label' column sm='3'>
				Año:
			</Form.Label>
			<Col sm='8'>
				<Form.Control
					className='text-left'
					as='select'
					disabled={onlyRead}
					name='orderProvisionYear'
					defaultValue={props?.data?.provisionOrder?.year}
					ref={register(provisionOrderYearValidation)}
					onChange={event => handlerProvionOrderYear(event.target.value)}
				>
					<option value={''} selected>Seleccione un año...</option>
					{
						yearsOptions?.map( (item,idx )=> ( <option key={ idx } value={ item }>
							{ item }
						</option>
						) )
					}
				</Form.Control>
				<FormFieldError errors={errors?.orderProvisionYear} />
			</Col>
		</Form.Group>
    </>
};

export default ModalReceptionAct;