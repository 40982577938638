export const STATES = {
    BORRADOR: 'BORRADOR',
	ACTIVO_PENDIENTE_DE_AUTORIZACION: 'ACTIVO_PENDIENTE_DE_AUTORIZACION',
	ANULADO: 'ACTIVO_PENDIENTE_DE_AUTORIZACION',
	AUTORIZADO_PENDIENTE_DE_PAGO: 'AUTORIZADO_PENDIENTE_DE_PAGO',
	PAGADO: 'PAGADO'
}
export const USER_ACTIONS = [
	{ id:"1", name: "Login" },
	{ id:"2", name:"Cambio Email" },
	{ id:"3", name:"Cambio Contraseña"},
	{ id:"4", name:"Envio Token" },
	{ id:"5", name:"Modificación Usuario" },
	{ id:"6", name:"Eliminar Usuario" },
	{ id:"7", name:"Nuevo Usuario" },
	{ id:"9", name:"Validación Usuario"}
];

export const TIPO_RECIBO = {
    ACTA_DE_RECEPCIÓN: 'Acta Recepción',
	CERTIFICADO_DE_SERVICIOS: 'Certificación de Servicio',
	CERTIFICADO_DE_OBRAS_PUBLICAS: 'Certificación de Obras Públicas'
}

export const INITIAL_URI = '/';
export const LOGIN_URI = '/login';
export const SET_PASSWORD_URI = '/set-password';
export const FORGOT_PASSWORD_URI = '/forgot-password';
export const RESET_PASSWORD_URI = '/reset-password';

export const CREDIT = '/credit';
export const CREDIT_DETAIL = '/credit-detail';
export const CREDIT_STEP_ONE = '/credit-step-one';
export const CREDIT_STEP_TWO = '/credit-step-two';
export const CREDIT_STEP_TWO_NEW = '/credit-step-two-new';

export const COMPENSATION_STEP_ONE = '/compensation-step-one';
export const COMPENSATION_STEP_TWO = '/compensation-step-two';
export const COMPENSATION_STEP_THREE = '/compensation-step-three';

export const INCORPORATION_CREDIT_STEP_ONE = '/incorporation-credit-step-one';
export const INCORPORATION_CREDIT_STEP_TWO = '/incorporation-credit-step-two';

export const CREDIT_EXECUTION = '/credit-execution';
export const CREDIT_EXECUTION_NEW_AFFECTATION_STEP_ONE = '/new-affectation-step-1';
export const CREDIT_EXECUTION_NEW_AFFECTATION_STEP_TWO = '/new-affectation-step-2';
export const CREDIT_EXECUTION_NEW_AFFECTATION_STEP_THREE = '/new-affectation-step-3';
export const CREDIT_EXECUTION_NEW_AFFECTATION_STEP_FOUR = '/new-affectation-step-4';
export const CREDIT_EXECUTION_AFFECTATION_HISTORY = '/affectation-history';
export const CREDIT_EXECUTION_CREDIT_QUERY = '/credit-query';
export const CREDIT_EXECUTION_BALANCE_DETAIL_COMPONENT = '/credit-execution-balance-detail-component';
export const CREDIT_EXECUTION_BALANCE_TOTAL_MODAL_COMPONENT = '/credit-execution-balance-total-modal-component';
export const CREDIT_EXECUTION_DETAILS_REVERT = '/credit-execution-details-revert';

export const BUDGET_ITEM_CONTROL = '/budget-item-control';
export const BUDGET_SUBCODE = '/budget-subcode';

export const USER_LIST = '/user-list';
export const USER_LIST_JURISDICTION = '/user-list-jurisdiction';
export const USER_NEW = '/user-new';
export const USER_EDIT = '/user-edit';
export const EDIT_PASSWORD = '/edit-password';
export const USER_DETAIL = '/user-detail';

export const PROFILE_LIST = '/profile-list';
export const PROFILE_NEW = '/profile-new';
export const PROFILE_EDIT = '/profile-edit';

export const REPORT_ORGANISM_ANALYTICAL = '/report-organism-analytical';
export const REPORT_JURISDICTION_ANALYTICAL = '/report-jurisdiction-analytical';
export const REPORT_CONSOLIDATED = '/report-consolidated';
export const REPORT_JURISDICTION_TOTAL = '/report-jurisdiction-total';
export const REPORT_ORGANISM_TOTAL = '/report-organism-total';
export const REPORT_TOTAL_CROSSED_PURPOSE_FUNCTION_OBJECT_EXPENDITURE = '/report-total-crossed-purpose-function-object-expenditure';
export const REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT = '/report-affectations-by-administrative-document';
export const REPORT_INSTRUMENT_LEGAL_MODIFY = '/report-instrumento-legal-modify';
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION = '/report-budget-status-credit-execution';
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_V2 = '/report-budget-status-credit-execution-v2';
export const REPORT_CREDIT_MODIFY_CREDIT_EXECUTION = '/report-credit-modify-credit-execution';
export const REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_V2 = '/report-credit-modify-credit-execution-v2';
export const REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION = '/report-movements-status-credit-execution';
export const REPORT_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION = '/report-budget-analytical-record-credit-execution';
export const REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION = '/report-budget-analytical-accounting-credit-execution';
export const REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_V2 = '/report-budget-analytical-accounting-credit-execution-v2';
export const REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION = '/report-balance-status-payment-list-credit-execution';
export const REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_V2 = '/report-balance-status-payment-list-credit-execution-v2';
export const REPORT_DELAYED_FILES_CREDIT_EXECUTION = '/report-delayed-files-credit-execution';
export const REPORT_TABLE_TOTALS_CREDIT_EXECUTION = '/report-table-totals-credit-execution';
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED = '/report-budget-status-credit-execution-generated';
export const REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION = '/report-works-budget-analytical-record-credit-execution';
export const REPORT_DELIVERY_ORDERS_FUND_REQUEST = '/report-delivery-orders-fund-request';
export const REPORT_FUND_REQUEST = '/report-fund-request';
export const REPORT_BANK_ACCOUNT_FUND_REQUEST = '/report-bank-account-fund-request';
export const REPORT_PROVIDER_FUND_REQUEST = '/report-provider-fund-request';
export const REPORT_SERVICE_DIRECTION_FUND_REQUEST = '/report-service-direction-fund-request';
export const REPORT_NEW_FUND_REQUEST = '/report-new-fund-request';
export const REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST = '/report-payment-order-administrative-service-fund-request';
export const REPORT_BALANCE_STATUS = '/balance-status';
export const REPORT_BALANCE_STATUS_V2 = '/balance-status-v2';
export const REPORT_IMPORTS = '/imports';
export const REPORT_BY_ADMINISTRATIVE_VOUCHER = '/report-administrative-voucher';
export const REPORT_DETERMINATION_PASSIVE_RESIDUES = '/report-determination-passive-residues';
export const REPORT_DETAIL_PASSIVE_RESIDUES = '/report-detail-passive-residues';
export const REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES = '/report-pending-resolution-passive-residues';
export const REPORT_ACCRUAL_BASIS = "/report-accrual-basis";

export const PRE_LOAD = '/pre-carga';

export const FIGURATIVE_EXPEDITURES = '/figurative-expeditures-list';
export const FIGURATIVE_EXPEDITURES_NEW = '/figurative-expeditures-new';
export const FIGURATIVE_EXPEDITURES_EDIT = '/figurative-expeditures-edit';

export const WAGES_TEMPLATE_LIST = '/wages-template-list';
export const WAGES_TEMPLATE_NEW = '/wages-template-new';
export const WAGES_TEMPLATE_EDIT = '/wages-template-edit';
export const WAGES_TEMPLATE_DETAIL = '/wages-template-detail';

export const REVERT_PASSIVE_REMAINING_BY_SERVICE = '/revert-passive-remaining-by-service';

export const REPORT_CONVERTION = '/convertions';

export const EXERCISES_LIST = '/exercises-list';
export const EXERCISE_NEW = '/exercise-new';
export const EXERCISE_EDIT_STEP_ONE = '/exercise-edit-1';
export const EXERCISE_EDIT_STEP_TWO = '/exercise-edit-2';
export const EXERCISE_DETAILS = '/exercise-details';
export const EXERCISE_SERVICE_ASOCIATION = '/exercise-service-asociation';

export const SERVICE_ADMINISTRATIVE_LIST = '/service-administrative-list';
export const SERVICE_ADMINISTRATIVED_NEW = '/service-administrative-new';
export const SERVICE_ADMINISTRATIVE_EDIT = '/service-administrative-edit';
export const SERVICE_ADMINISTRATIVE_DETAILS = '/service-administrative-details';
export const SERVICE_ADMINISTRATIVE_USER = '/service-administrative-user';
export const SERVICE_ADMINISTRATIVE_ORGANISM = '/service-administrative-organism';

export const ADMINISTRATIVE_ORGANISM_LIST = '/administrative-organism-list';
export const ADMINISTRATIVE_ORGANISM_NEW = '/administrative-organism-new';
export const ADMINISTRATIVE_ORGANISM_EDIT = '/administrative-organism-edit';
export const ADMINISTRATIVE_ORGANISM_DETAILS = '/administrative-organism-details';

export const ORDER_PAY_LIST = '/order-pay-list';
export const ORDER_PAY_NEW = '/order-pay-new';
export const ORDER_PAY_EDIT = '/order-pay-edit';
export const ORDER_PAY_DETAILS = '/order-pay-details';

export const FUND_REQUESTS_LIST = '/fund-requests-list';
export const FUND_REQUESTS_NEW = '/fund-requests-new';
export const FUND_REQUESTS_EDIT = '/fund-requests-edit';
export const FUND_REQUESTS_DETAILS = '/fund-requests-details';

export const DELIVERY_ORDER_LIST = '/delivery-order-list';
export const DELIVERY_ORDER_NEW = '/delivery-order-new';
export const DELIVERY_ORDER_NEW_MASSIVE = '/delivery-order-new-massive';
export const DELIVERY_ORDER_EDIT = '/delivery-order-edit';
export const DELIVERY_ORDER_DETAILS = '/delivery-order-details';

export const PROVISION_ORDER_LIST  = '/provision-order-list';
export const PROVISION_ORDER_NEW = '/provision-order-new';
export const PROVISION_ORDER_EDIT = '/provision-order-edit';
export const PROVISION_ORDER_DETAILS = '/provision-order-details';

export const BANK_ACCOUNT_LIST = '/bank-account-list';
export const BANK_ACCOUNT_NEW = '/bank-account-new';
export const BANK_ACCOUNT_EDIT = '/bank-account-edit';
export const BANK_ACCOUNT_DETAILS = '/bank-account-details';


export const AUDITS_LIST = '/audits-list';
export const AUDITS_USERS = '/audits-users';

export const USER_MANUAL_SERVICE_OPERATOR = '/files/operator-service-manual';
export const USER_MANUAL_PROFILE_HTC = '/files/htc-manual';

export const CAPTION_LIST = '/caption-list';
export const CAPTION_NEW = '/caption-new';
export const CAPTION_EDIT = '/caption-edit';

export const ALERT_LIST = '/alert-list';
export const ALERT_NEW = '/alert-new';
export const ALERT_EDIT = '/alert-edit';

export const NOTIFICATION_LIST  = '/notification-list';
export const NOTIFICATION_NEW  = '/notification-new';
export const NOTIFICATION_DETAILS = '/notification-details';

export const EXPEDIENT_LIST = '/expedient-list';
export const EXPEDIENT_EDIT = '/expedient-edit';

export const MAINTENANCE_SYSTEM_LIST = '/maintenance-system-list';

export const CONCEPT_LIST = '/concept-list';
export const CONCEPT_NEW  = '/concept-new';
export const CONCEPT_EDIT  = '/concept-edit';

export const INDEX_PAGE = '/index';

export const ROUTE_PATHS = {
	INITIAL_URI,
	LOGIN_URI,
	SET_PASSWORD_URI,
	FORGOT_PASSWORD_URI,
	RESET_PASSWORD_URI,

	CREDIT,
	CREDIT_DETAIL,
	CREDIT_STEP_ONE,
	CREDIT_STEP_TWO,
	CREDIT_STEP_TWO_NEW,

	COMPENSATION_STEP_ONE,
	COMPENSATION_STEP_TWO,
	COMPENSATION_STEP_THREE,
	
	INCORPORATION_CREDIT_STEP_ONE,
	INCORPORATION_CREDIT_STEP_TWO,


	CREDIT_EXECUTION,
	CREDIT_EXECUTION_NEW_AFFECTATION_STEP_ONE,
	CREDIT_EXECUTION_NEW_AFFECTATION_STEP_TWO,
	CREDIT_EXECUTION_NEW_AFFECTATION_STEP_THREE,
	CREDIT_EXECUTION_NEW_AFFECTATION_STEP_FOUR,
	CREDIT_EXECUTION_AFFECTATION_HISTORY,
	CREDIT_EXECUTION_CREDIT_QUERY,
	CREDIT_EXECUTION_BALANCE_DETAIL_COMPONENT,
	CREDIT_EXECUTION_BALANCE_TOTAL_MODAL_COMPONENT,
	CREDIT_EXECUTION_DETAILS_REVERT,

	BUDGET_ITEM_CONTROL,
	BUDGET_SUBCODE,

	USER_LIST,
	USER_LIST_JURISDICTION,
	USER_NEW,
	USER_EDIT,
	EDIT_PASSWORD,
	USER_DETAIL,

	PROFILE_LIST,
	PROFILE_NEW,
	PROFILE_EDIT,

	REPORT_ORGANISM_ANALYTICAL,
	REPORT_JURISDICTION_ANALYTICAL,
	REPORT_CONSOLIDATED,
	REPORT_JURISDICTION_TOTAL,
	REPORT_ORGANISM_TOTAL,
	REPORT_TOTAL_CROSSED_PURPOSE_FUNCTION_OBJECT_EXPENDITURE,
	REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT,
	REPORT_INSTRUMENT_LEGAL_MODIFY,
	REPORT_BUDGET_STATUS_CREDIT_EXECUTION,
	REPORT_BUDGET_STATUS_CREDIT_EXECUTION_V2,
	REPORT_CREDIT_MODIFY_CREDIT_EXECUTION,
	REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_V2,
	REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION,
	REPORT_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION,
	REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION,
	REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_V2,
	REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION,
	REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_V2,
	REPORT_DELAYED_FILES_CREDIT_EXECUTION,
	REPORT_TABLE_TOTALS_CREDIT_EXECUTION,
	REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED,
	REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION,
	REPORT_DELIVERY_ORDERS_FUND_REQUEST,
	REPORT_FUND_REQUEST,
	REPORT_BANK_ACCOUNT_FUND_REQUEST,
	REPORT_PROVIDER_FUND_REQUEST,
	REPORT_SERVICE_DIRECTION_FUND_REQUEST,
	REPORT_NEW_FUND_REQUEST,
	REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST,
	REPORT_BALANCE_STATUS,
	REPORT_BALANCE_STATUS_V2,
	REPORT_IMPORTS,
	REPORT_ACCRUAL_BASIS,
	PRE_LOAD,

	FIGURATIVE_EXPEDITURES,
	FIGURATIVE_EXPEDITURES_NEW,
	FIGURATIVE_EXPEDITURES_EDIT,

	WAGES_TEMPLATE_LIST,
	WAGES_TEMPLATE_NEW,
	WAGES_TEMPLATE_EDIT,
	WAGES_TEMPLATE_DETAIL,

	REVERT_PASSIVE_REMAINING_BY_SERVICE,

	REPORT_CONVERTION,
	REPORT_BY_ADMINISTRATIVE_VOUCHER,
	REPORT_DETERMINATION_PASSIVE_RESIDUES,
	REPORT_DETAIL_PASSIVE_RESIDUES,
	REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES,

	EXERCISES_LIST,
	EXERCISE_NEW,
	EXERCISE_EDIT_STEP_ONE,
	EXERCISE_EDIT_STEP_TWO,
	EXERCISE_DETAILS,
	EXERCISE_SERVICE_ASOCIATION,

	SERVICE_ADMINISTRATIVE_LIST,
	SERVICE_ADMINISTRATIVED_NEW,
	SERVICE_ADMINISTRATIVE_EDIT,
	SERVICE_ADMINISTRATIVE_DETAILS,
	SERVICE_ADMINISTRATIVE_USER,
	SERVICE_ADMINISTRATIVE_ORGANISM,

	ADMINISTRATIVE_ORGANISM_LIST,
	ADMINISTRATIVE_ORGANISM_NEW,
	ADMINISTRATIVE_ORGANISM_EDIT,
	ADMINISTRATIVE_ORGANISM_DETAILS,

	ORDER_PAY_LIST,
	ORDER_PAY_NEW,
	ORDER_PAY_EDIT,
	ORDER_PAY_DETAILS,

	FUND_REQUESTS_LIST,
	FUND_REQUESTS_NEW,
	FUND_REQUESTS_EDIT,
	FUND_REQUESTS_DETAILS,

	DELIVERY_ORDER_LIST,
	DELIVERY_ORDER_NEW,
	DELIVERY_ORDER_NEW_MASSIVE,
	DELIVERY_ORDER_EDIT,
	DELIVERY_ORDER_DETAILS,

	PROVISION_ORDER_LIST,
	PROVISION_ORDER_NEW,
	PROVISION_ORDER_EDIT,
	PROVISION_ORDER_DETAILS,

	BANK_ACCOUNT_LIST,
	BANK_ACCOUNT_NEW,
	BANK_ACCOUNT_EDIT,
	BANK_ACCOUNT_DETAILS,


	AUDITS_LIST,
	AUDITS_USERS,

	USER_MANUAL_SERVICE_OPERATOR,
	USER_MANUAL_PROFILE_HTC,

	CAPTION_LIST,
	CAPTION_NEW,
	CAPTION_EDIT,

	ALERT_LIST,
	ALERT_NEW,
	ALERT_EDIT,

	NOTIFICATION_LIST,
	NOTIFICATION_NEW,
	NOTIFICATION_DETAILS,

	MAINTENANCE_SYSTEM_LIST,

	CONCEPT_LIST,
	CONCEPT_NEW,
	CONCEPT_EDIT,
	
	EXPEDIENT_LIST,
	EXPEDIENT_EDIT,

	INDEX_PAGE
};
