import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from 'src/components/general/FormFieldError';
import { config } from 'src/env.js';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getYearsOptionsByParam } from 'src/utils/utils';

const InputLegalInstrumentForm = ({ legalInstrument, handleLegalInstrumentType, handleLegalInstrumentNumber, handleLegalInstrumentYear }) => {
    const { register, errors } = useFormContext();
    const LEGAL_INSTRUMENT_TYPES = config.appSettings.LEGAL_INSTRUMENTS_TYPES;
    const legalInstrumentTypeRequiredMsg = 'Debe ingresar el tipo del instrumento legal.';
    const legalInstrumentNumberRequiredMsg = 'Debe ingresar el Número del instrumento legal.';
    const legalInstrumentYearRequiredMsg = 'Debe ingresar el año del instrumento legal.';
    const legalInstrumentTypeValidationObj = { required: legalInstrumentTypeRequiredMsg };
    const legalInstrumentNumberValidationObj = { required: legalInstrumentNumberRequiredMsg };
    const legalInstrumentYearValidationObj = { required: legalInstrumentYearRequiredMsg };
    const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const minimumYear = 2018;
    const yearsOptions = getYearsOptionsByParam(minimumYear, globalSelectedPeriod?.year);
    yearsOptions.push(2024);

    return <>
        <Form.Group as={Row}>
            <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                Instrumento Legal:
            </Form.Label>
            <Col sm='4'>
                <div className="input-group" >
                    <Form.Control
                        as='select'
                        value={legalInstrument?.legalInstrumentType}
                        className='mr-2'
                        onChange={e => handleLegalInstrumentType(e?.target?.value)}
                    >
                        <option value={undefined} selected>Tipo</option>
                        {LEGAL_INSTRUMENT_TYPES?.map(item => (
                            <option className='text-black-color' value={item?.name} key={item?.id}>
                                {item.name}
                            </option>))
                        }
                    </Form.Control>
                    <Form.Control
                        type='number'
                        name='legalInstrumentNumber'
                        value={legalInstrument?.legalInstrumentNumber}
                        className='mr-2'
                        max={99999}
                        min={1}
                        placeholder={'Number'}
                        onChange={e => handleLegalInstrumentNumber(e.target.value)}
                        ref={register(legalInstrumentNumberValidationObj)}
                    />
                    <Form.Control
                        as='select'
                        value={legalInstrument?.legalInstrumentYear}
                        className='select-min-width'
                        onChange={e => handleLegalInstrumentYear(e?.target?.value)}
                    >
                        <option value={undefined} selected>Año</option>
                        {yearsOptions?.map(item => (
                            <option value={item}>
                                {item}
                            </option>))
                        }
                    </Form.Control>
                </div>
                <Form.Control hidden ref={register(legalInstrumentTypeValidationObj)} name='legalInstrumentTypeId' value={legalInstrument?.legalInstrumentType} />
                <FormFieldError errors={errors?.legalInstrumentTypeId} />
                <FormFieldError errors={errors?.legalInstrumentNumber} />
                <Form.Control hidden ref={register(legalInstrumentYearValidationObj)} name='legalInstrumentYear' value={legalInstrument?.legalInstrumentYear} />
                <FormFieldError errors={errors?.legalInstrumentYear} />
            </Col>
        </Form.Group>
    </>
}

export default InputLegalInstrumentForm