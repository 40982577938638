import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { useEffect } from 'react';
import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { getUserListData } from 'src/redux/user/userReducer';
import { getEmail } from 'src/redux/login/loginReducer';
import banner1 from 'src/assets/images/image-vector-1.svg';
import banner2 from 'src/assets/images/image-vector-2.png';
import banner3 from 'src/assets/images/image-vector-3.svg';

import { config } from 'src/env.js'

const IndexPage = props => {
	const dispatch = useDispatch();
	const email = useSelector(state => getEmail(state));
	const listUserData = useSelector(state => getUserListData(state));
	const userFound = listUserData?.records?.find(item => item.email == email);  
	
	console.log("ENV: ", config);
	
	useEffect(() => {
		if (userFound) {
			dispatch(tryListAllServiceAdministrativeByUser(userFound.id));
		}
	}, [userFound]);

	return (
		<Container fluid className='IndexPage__container rounded mt-3' >
			<Image src={banner1} className='IndexPage__img' />
			<Image src={banner2} className='IndexPage__img' />
			<Image src={banner3} className='IndexPage__img' /> 
		</Container>
	);
};

export default IndexPage;