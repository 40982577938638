import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form';
import { Container, Card, Form } from 'react-bootstrap';
import PageTitle from 'src/components/general/PageTitle';
import MultiSelect from "react-multi-select-component";
import PreLoadExecutionTable from './PreLoadExecutionTable';
import { clearPreloadData } from 'src/redux/preload/preloadActions';
import { tryGetPreloadData } from 'src/redux/preload/preloadActionCreator';
import { getListAllServiceAdministrativeByUserIsFetching, getListAllServiceAdministrativeByUserData } from 'src/redux/administrativeService/administrativeServiceReducer';


const CreditExecutionAffectationPreLoadEdit = () => {

    const dispatch = useDispatch();
    const hookFormMethods = useForm();
    const { reset } = hookFormMethods;
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(options);
    const administrativeService = useSelector(state => getListAllServiceAdministrativeByUserData(state))?.sort((a, b) => a.code > b.code ? 1 : -1);
	const administrativeServiceIsFetching = useSelector(state => getListAllServiceAdministrativeByUserIsFetching(state));

    const [filterObject, setFilterObject] = useState();
    const [checkedPreload, setCheckedPreload] = useState(false);
    const attributeFilter = checkedPreload ? "subCodePreloadWithoutConversion" : "hasSubCodePreload";

    const defaultParams = {
        page: 1,
        pageSize: 10,
    };

    useEffect(() => {
		let optionsArray = [];
		let i = 0;
		for (i in administrativeService){
			optionsArray.push({label: administrativeService[i]?.code + " - " + administrativeService[i]?.shortName, value:  administrativeService[i]?.id} )
		}
		setOptions(optionsArray);
	}, [administrativeService]);

    const searchAllPreloadData = () => {
        reset();
        setFilterObject();
        setCheckedPreload(!checkedPreload)
        dispatch(clearPreloadData());
        dispatch(tryGetPreloadData({...defaultParams, filter: { [attributeFilter] : 1 }}));
    };

    const idiom = {
		"selectSomeItems": "Seleccionar una o más opciones...",
		"allItemsAreSelected": "Todos los elementos están seleccionados.",
		"selectAll": "Seleccionar todo",
		"search": "Buscar",
		"clearSearch": "Limpiar búsqueda."
	}

    const customValueRenderer = (selected) => {
		return selected.length
			? selected.map(({ label }) => " ✔️" + label)
			: "Seleccione una Opción...";
	};

    return (
        <Container fluid className='lisrtin'>
            <Card className='mb-5'>
                <PageTitle text='Módulo de Edicion Precarga de Modificación de Crédito' />
                <Container fluid className='mt-0'>
                    <Form.Check
                        className='text-black-color ml-5 my-3'
                        id="preloadCheck"
                        label='Incluir precargas completadas'
                        onClick={() => searchAllPreloadData()}
                    />
                    <Card className='mb-3'>
                        <Card.Body>
                           {/*  <p className='text-black-color'>Servicio</p>
                            <MultiSelect
                                className='text-black-color'
                                options={options}
                                value={selected}
                                onChange={setSelected}
                                labelledBy={"Select"}
                                overrideStrings={idiom}
                                valueRenderer={customValueRenderer}
                                hasSelectAll={true}
                                isLoading={administrativeServiceIsFetching}
                                ClearSelectedIcon={"🧹Limpiar"}
							/> */}
                            <FormContext {...hookFormMethods } >
                                <PreLoadExecutionTable
                                    servicesSelect={selected}
                                    checkedPreload={checkedPreload}
                                    filterObject={filterObject}
                                    setFilterObject={setFilterObject}
                                />
                            </FormContext>
                        </Card.Body>
                    </Card>
                </Container>
            </Card>
        </Container>
    )
}

export default CreditExecutionAffectationPreLoadEdit;