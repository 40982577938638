import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Spinner, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import MultiSelect from "react-multi-select-component";
import { config } from 'src/env.js';

import PageTitle from 'src/components/general/PageTitle';
import FormFieldError from 'src/components/general/FormFieldError';
import { tryPostUser, tryGetListUserProfile } from 'src/redux/user/userActionCreator';
import { getUserProfileListData, getUserProfileListIsFetching, getUserCreateIsFetching, getAddUserToProfileIsFetching } from 'src/redux/user/userReducer';
import { validatePasswordFormat, PASSWORD_INVALID_MSG } from 'src/services/validationService';
import { USER_LIST, USER_LIST_JURISDICTION } from 'src/utils/constants';
import { generatePassword, joinLastNameName } from 'src/utils/utils';
import { newUser, user, required, lastName, name, password, confirmPassword, email, group, cancel, save } from 'src/utils/label';
import { setAssignProfiles } from 'src/utils/usersProfiles';

import { tryAddAllUsersAdministrativeService, tryRemoveAllUsersAdministrativeService, tryAddNewUserAdministrativeService, tryListAllAdministrativeServices } from 'src/redux/administrativeService/administrativeServiceActionCreator';

import { getUserListData } from 'src/redux/user/userReducer';
import { tryGetUserList } from 'src/redux/user/userActionCreator';

import { getProfileName, getUserPermissionsSecurity } from 'src/redux/login/loginReducer';
import { getAdministrativeServiceListAllData, getAdministrativeServiceListAllIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import { tryUserNewJurisdiction } from '../../redux/user/userActions';

const UserNewPage = props => {

	const dispatch = useDispatch();

	const profileName = useSelector(state => getProfileName(state));

	// Acciones of Profiles
	const securityPermissionsActionsProfiles = useSelector(state => getUserPermissionsSecurity(state))?.actions?.adminServices;

	const { register, handleSubmit, errors, getValues } = useForm();

	const lastNameRequiredMsg = 'Debe ingresar apellido.';
	const nameRequiredMsg = 'Debe ingresar nombre.';
	const passwordConfirmRequiredMsg = 'Debe ingresar una confirmación de contraseña.';
	const passwordConfirmSameMsg = 'Las contraseñas ingresadas no coinciden.';
	const nameConfirmBlankMsg = "No se debe ingresar solo espacios en blanco en el nombre"
	const lastNameConfirmBlankMsg = "No se debe ingresar solo espacios en blanco en el apellido"
	const emailRequiredMsg = 'Debe ingresar un correo electrónico.';
	const profileRequiredMsg = 'Debe seleccionar un grupo.';
	const samePasswordValidation = value => (value === getValues('password') ? undefined : passwordConfirmSameMsg);
	const nameValidationSpaceBlank = value => !!value.trim() ? undefined : nameConfirmBlankMsg;
	const lastNameValidationSpaceBlank = value => !!value.trim() ? undefined : lastNameConfirmBlankMsg;
	const lastNameValidationObj = { required: lastNameRequiredMsg, validate: lastNameValidationSpaceBlank };
	const nameValidationObj = { required: nameRequiredMsg, validate: nameValidationSpaceBlank };
	const passwordValidationObj = { validate: value => (validatePasswordFormat(value) ? undefined : PASSWORD_INVALID_MSG) };
	const passwordConfirmValidationObj = { required: passwordConfirmRequiredMsg, validate: samePasswordValidation };
	const emailValidationObj = { required: emailRequiredMsg };
	const profileValidationObj = { required: profileRequiredMsg };

	const listProfileData = useSelector(state => getUserProfileListData(state))?.sort((profileValue1, profileValue2) => {
		return (profileValue1.name?.toUpperCase() < profileValue2.name?.toUpperCase()) ? -1 : 1;
	});
	const listProfileIsFetching = useSelector(state => getUserProfileListIsFetching(state));

	// Set config Users By Profile
	const profileNameArray = setAssignProfiles();

	// Filter for type profile
	const profileNameArrayRecords = listProfileData?.filter(item => profileNameArray?.includes(item?.name));
	const [selectedProfile, setSelectedProfile] = useState(false);

	//Services Administrative
	const administrativeService = useSelector(state => getAdministrativeServiceListAllData(state))?.records?.sort((a, b) => a.code > b.code ? 1 : -1);
	const administrativeServiceIsFetching = useSelector(state => getAdministrativeServiceListAllIsFetching(state));

	const postUserIsFetching = useSelector(state => getUserCreateIsFetching(state));
	const postAddUserToProfileIsFetching = useSelector(state => getAddUserToProfileIsFetching(state));
	const userCreateIsFetching = (postUserIsFetching || postAddUserToProfileIsFetching);

	const listUserData = useSelector(state => getUserListData(state));

	const handleShowModal = () => {
		dispatch(push(USER_LIST_JURISDICTION));
		dispatch(tryUserNewJurisdiction(selected));
	};

	//ProfileNameServices
	const idProfileOperadorServicioPresupuesto = 'Operador de Servicio de Presupuesto';  //ID 22 Operador de Servicio de Prespuesto
	const idProfileFiscalizador = 'Fiscalizador';  //ID 46 Fiscalizador
	const idProfileOperadorDireccionContabilidad = 'Operador de Dirección de Contabilidad';
	const idProfileDirectorSA = 'Director de SA';
	const idProfileOperadorTesoreria = 'Operador de Tesoreria';
	const idProfileJefeTesoreriaSA = 'Jefe de Tesoreria de SA';
	const idProfileJefeContabilidadSA = 'Jefe de Contabilidad de SA';
	const idProfileDelegadoFiscalSA = 'Delegado Fiscal de SA';
	const idProfileContadorGeneral= 'Contador General';

	const allowedProfilesChecker = (selectedProfile) => {
		const profilesAllowApplyUserServices = [
			idProfileOperadorServicioPresupuesto,
			idProfileFiscalizador,
			idProfileOperadorDireccionContabilidad, 
			idProfileDirectorSA,
			idProfileOperadorTesoreria,
			idProfileJefeTesoreriaSA,
			idProfileJefeContabilidadSA,
			idProfileDelegadoFiscalSA,
			idProfileContadorGeneral
		];
		return profilesAllowApplyUserServices?.some((item)=>item === selectedProfile);
	};
	
	const idProfileUser = (allowedProfilesChecker(selectedProfile));

	const onFormSubmit = data => {
		//Seteo random password
		data.password = generatePassword();

		let alias = data?.name;//se envia a alias solo en nombre antes de concatenarlo con apellido 
		data.name = joinLastNameName(data?.lastName, data?.name);
		let profileOperatorServiceBudget = (data?.name == idProfileOperadorServicioPresupuesto || data?.name == idProfileFiscalizador);
		dispatch(tryPostUser(data?.name?.toUpperCase(), alias, data?.password, data?.email, undefined, data?.profileId)).then(
			response => {
				if (response?.status == 200) {
					let userID = [response?.data?.id];
					if (idProfileUser) {
						let selectedAdministrativeService = selected?.map(item => item.value);
						dispatch(tryAddNewUserAdministrativeService(userID, selectedAdministrativeService));
					} else {
						dispatch(tryAddAllUsersAdministrativeService(response?.data?.id));
					}
				}
			}
		);
	};

const onChangeProfile = item => {
	setSelectedProfile(item.target.options[item.target.selectedIndex].text);
}

useEffect(() => {
	dispatch(tryGetListUserProfile());
	dispatch(tryGetUserList());
	dispatch(tryListAllAdministrativeServices());
}, []);

//// Multiselect
// View items selector
let options = [];
let i = 0;
for (i in administrativeService) {
	options.push({ label: administrativeService[i]?.code + " - " + administrativeService[i]?.shortName, value: administrativeService[i]?.id })
}

// Add options
const [selected, setSelected] = useState(options);

// Idiom Spanish
const idiom = {
	"selectSomeItems": "Seleccionar una o más opciones...",
	"allItemsAreSelected": "Todos los elementos están seleccionados.",
	"selectAll": "Seleccionar todo",
	"search": "Buscar",
	"clearSearch": "Limpiar búsqueda."
}

// Load data 	
const customValueRenderer = (selected, _options) => {
	return selected.length
		? selected.map(({ label }) => " ✔️" + label)
		: "No hay ítems seleccionados...";
};
const hasValueCustomValueRenderer = (selected.length === 0);
//// END Multiselect	

const allowGet = (userCreateIsFetching || (idProfileUser && hasValueCustomValueRenderer));

return <Container fluid className='mb-5'>
	<Card>

		<PageTitle text={user} />

		<Container fluid>
			<Card className='mb-3'>
				<Card.Header>
					<h6 className='mb-0'>{newUser}</h6>
				</Card.Header>
				<Card.Body >

					<Row>
						<Col xs={8}>
							<h6 className='mb-4 text-black-color'>

							</h6>
						</Col>

						<Col xs={4} className='text-right font-weight-bold font-italic'>
							<span className='text-danger d-flex mandatory-label'>
								<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
								{required}
							</span>
						</Col>
					</Row>

					<Form onSubmit={handleSubmit(onFormSubmit)}>
						<Form.Group as={Row} controlId='alias'>
							<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
								<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
								{lastName}:
							</Form.Label>
							<Col sm={4}>
								<Form.Control
									ref={register(lastNameValidationObj)}
									type='text'
									placeholder={lastName}
									name='lastName'
									maxLength={50}
									minLength={3}
								/>
								<FormFieldError errors={errors?.lastName} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId='alias'>
							<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
								<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
								{name}:
							</Form.Label>
							<Col sm={4}>
								<Form.Control
									ref={register(nameValidationObj)}
									type='text'
									placeholder={name}
									name='name'
									id='name'
									maxLength={50}
									minLength={3}
								/>
								<FormFieldError errors={errors?.name} />
							</Col>
						</Form.Group>


						{/*
							<Form.Group as={Row} controlId='password'>
								<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{password}:
								</Form.Label>
								<Col sm={4}>
									<Form.Control 
										ref={register(passwordValidationObj)} 
										type='password' 
										placeholder={password} 
										name='password' 
										id='password' 
									/>
									<FormFieldError errors={errors?.password} />
								</Col>
							</Form.Group>

							<Form.Group as={Row} controlId='passwordConfirm'>
								<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{confirmPassword}:
								</Form.Label>
								<Col sm={4}>
									<Form.Control 
										ref={register(passwordConfirmValidationObj)} 
										type='password' 
										placeholder={confirmPassword} 
										name='passwordConfirm' 
										id='passwordConfirm' 
									/>
									<FormFieldError errors={errors?.passwordConfirm} />
								</Col>
							</Form.Group>
							*/}

						<Form.Group as={Row} controlId='email'>
							<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
								<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
								{email}:
							</Form.Label>
							<Col sm={4}>
								<Form.Control
									ref={register(emailValidationObj)}
									type='email'
									placeholder='Correo electrónico'
									name='email'
									id='email'
									pattern="^[a-z0-9!#$%&'*+\/=?^_`\{\|\}~\-]+([a-z0-9!#$%&'*+\/=?^_`\{\|\}~\-\.])*@([a-z0-9]([a-z0-9]*[a-z0-9])?\.)+[a-z0-9]([a-z0-9]*[a-z0-9])?$"
								/>
								<FormFieldError errors={errors?.email} />
							</Col>
						</Form.Group>

						<Form.Group as={Row} controlId='group'>
							<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='4'>
								<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
								{group}:
							</Form.Label>
							<Col sm='4'>
								<Form.Control
									as='select'
									name='profileId'
									id='profileId'
									ref={register(profileValidationObj)}
									onChange={event => onChangeProfile(event)}
								>
									<option option className='text-black-color' value={''} selected >
										Seleccione una opción...
									</option>
									{
										profileNameArrayRecords?.map(profile =>
											<option className='text-black-color' value={profile.id}>
												{`${profile.name || ''}`}
											</option>)
									}
								</Form.Control>
								<FormFieldError errors={errors?.profileId} />
							</Col>
							<Col sm='4' className='py-1'>
								{
									listProfileIsFetching
										?
										<Spinner animation='border' size='sm' className='my-2 spinner-border text-danger' />
										:
										null
								}
							</Col>
						</Form.Group>

						{
							idProfileUser //<- ID Operador de Servicio de Prespuesto
								?
								<>

									<Form.Group as={Row} controlId='service'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='4'>
											<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
											{'Servicio'}:
										</Form.Label>
										<Col sm='4'>
											<MultiSelect
												className='text-black-color'
												options={options}
												value={selected}
												onChange={setSelected}
												labelledBy={"Select"}
												overrideStrings={idiom}
												valueRenderer={customValueRenderer}
												hasSelectAll={true}
												isLoading={administrativeServiceIsFetching}
												ClearSelectedIcon={"🧹Limpiar"}
											/>

											{
												hasValueCustomValueRenderer
													?
													<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
														{`${'Debe seleccionar al menos un ítem.'}`}
													</div>

													:
													null
											}
										</Col>
									</Form.Group>


									{/* TODO: Habilitar boton al terminar modal de Usuario por jurisdiccion y unidad org 
									<Form.Group  as={Row}>
										<Col sm='4'></Col>
										<Button 
											className={'mx-3 mb-4'} 
											size='s' 
											disabled={userCreateIsFetching} 
											onClick={handleShowModal}
										>
											Seleccionar Jurisdicción / Unidad Organizacional
										</Button>
									</Form.Group> 
									*/}


								</>
								:
								null
						}

						<div class='d-flex justify-content-around mt-4 mb-3'>
							<Button type='submit' variant='danger' size='lg' disabled={userCreateIsFetching} onClick={() => dispatch(push(USER_LIST))}>
								{cancel}
							</Button>

							<span className={(userCreateIsFetching ? '' : 'hidden')}>
								<Spinner className='spinner-border text-danger' animation='border' />
							</span>

							<Button type='submit' variant='success' size='lg' disabled={allowGet}>
								{save}
							</Button>
						</div>

					</Form>
				</Card.Body>
			</Card>
		</Container>
	</Card>
</Container>;
};

export default UserNewPage;