import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Table, Button, Spinner, Alert, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faSearch, faFilter, faBroom } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { getClearObject } from 'src/utils/utils';
import { USER_ACTIONS } from 'src/utils/constants';
import { isNotEmptyArray, isNotEmptyObject } from 'src/services/validationService';
import { seeMore, seeLess } from 'src/utils/label'

import { clearAuditsUsersList } from 'src/redux/audit/auditActions'
import { getAuditsUsersListData, getAuditsUsersListIsFetching, getAuditsUsersListMetadata } from 'src/redux/audit/auditReducer';
import { tryGetAuditsUsersList } from 'src/redux/audit/auditActionCreator';

import { dateToStringFormatedToShowUSA } from 'src/utils/utils';

import ActionIcon from 'src/components/general/ActionIcon';
import UserAuditDetailModal from 'src/components/pages/Audits/UserAuditDetailModal';
import AppLoading from 'src/components/common/AppLoading';

const AuditsUsersPage = () => {

    const dispatch = useDispatch();
    const { handleSubmit, register, reset, getValues } = useForm();

    let auditData = useSelector(state => getAuditsUsersListData(state))?.records || [];
        // ?.sort((a, b) => a.created_at < b.created_at ? 1 : -1);
    const hasAuditData = isNotEmptyArray(auditData);
    const metadata = useSelector(state => getAuditsUsersListMetadata(state));
    const auditDataIsFetching = useSelector(state => getAuditsUsersListIsFetching(state));
    const todayDate = dateToStringFormatedToShowUSA(new Date());

    const defaultParams = {
        size: 10,
    }

    //Audit detail modal
    const [dataModal, setDataModal] = useState();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onClickAuditDetailModal = (auditData) => {
        setDataModal(auditData);
        handleShow(true);
    };

    const loadAllData = () => {
        fetchList(defaultParams);
    }

    useEffect(loadAllData, []);


    //Documentar metodo!
    const buildParams = (data) =>{
        const {StartDate, EndDate, page} = data;
        // data.dateFrom = data.dateTo = null;
        const filterText = getClearObject(data);
        filterText.email &&= filterText.email.trim();

        return {
            StartDate,
            EndDate,
            page,
            ...(isNotEmptyObject(filterText) && {filter: filterText})
        }
    }

    const onSubmitForm = (data) => {
        fetchList(buildParams(data));
    }

    const onClickLoadPage = (page) => {
        let formData = getValues();
        formData = {
            ...formData,
            ...defaultParams,
            page
        }
        fetchList(buildParams(formData));
    };

    const clearFilters = () => {
        reset();
        loadAllData();
    };
    
    const fetchList = (params) => {
        dispatch(clearAuditsUsersList());
        dispatch(tryGetAuditsUsersList(params));
    }

    return <Container fluid>
        <Card>
            <Container fluid>
                <Card className='my-3'>
                    <Card.Header className='h6'>
                        Auditorías de Usuarios
                    </Card.Header>
                    <Card.Body>
    
                        <Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off'>

                            <Table striped bordered hover size='sm' >
                                <thead>
                                    <tr>
                                        <th className='text-center align-middle tipeTableFormat tbTipo'>Fecha y Hora</th>
                                        <th className='text-center align-middle' width="30%">Email del usuario</th>
                                        <th className='text-center align-middle' width='30%'>Acción</th>
                                        <th className='text-center align-middle tbDesc referenceCtn audit-ref'>Detalle</th>
                                    </tr>
                                    <tr className='secondary'>
                                        <th className='text-center align-middle'>
                                            <Form.Control
                                                type='date'
                                                size="sm"
                                                name='StartDate'
                                                ref={register}
                                                max={todayDate}
                                                className='text-center mb-1 mr-4'
                                                title='Fecha desde'
                                            />
                                            <Form.Control
                                                type='date'
                                                size="sm"
                                                name='EndDate'
                                                ref={register}
                                                max={todayDate}
                                                className='text-center'
                                                title='Fecha hasta'
                                            />
                                        </th>
                                        <th className='text-center align-middle'>
                                            <Form.Control
                                                type='text'
                                                size="sm"
                                                className='text-left'
                                                name='email'
                                                ref={register}
                                                placeholder="Filtrar email del usuario"
                                        />
                                        </th>
                                        <th className='text-center align-middle'>
                                            <Form.Control
                                                as="select"
                                                ref={register}
                                                name='actionId'
                                                className="text-black-color"
                                                >
                                                <option className='text-black-color' value={''}>
                                                Filtrar acción
                                                </option>
                                                {USER_ACTIONS.map( action => 
                                                    <option key={action.id} value={action.id}>
                                                        {action.name}
                                                    </option>
                                                )}
                                            </Form.Control>
                                        </th>
                                        <th className='text-center align-middle'>
											<div className='d-flex justify-content-around'>
												<ActionIcon
													size="lg"
													id="search-button"
													className="btn-primary search-button text-white-color px-5 py-1"
													toolTipText="Filtrar"
													icon={faFilter}
													type='submit'
													onSubmit={onSubmitForm}
													/>
												<ActionIcon
													size="lg"
													id="clean-filter"
													className="btn-primary clean-filter text-white-color px-5 py-1"
													toolTipText="Limpiar filtros"
													icon={faBroom}
													type='reset'
													onClick={()=> clearFilters()}
													/>
											</div>
                                    </th>
                                    </tr>

                                </thead>
                                <tbody className='text-black-color'>
                                    {
                                        hasAuditData
                                            ?
                                                auditData?.map((item, i) => 
                                                    <tr key={i}>

                                                        <td className='text-center align-middle tipeTableFormat tbTipo'>
                                                            {moment(item?.dateCreated).format('DD/MM/YYYY HH:mm')}
                                                        </td>
                                                        <td className='text-center align-middle actionTableFormat tbAction'>
                                                            {item?.usuario?.email}
                                                        </td>
                                                        <td className='text-center align-middle '>
                                                            {item?.actionName}
                                                        </td>
                                                        <td className='text-center align-middle p-0 m-0 table-responsive'>
                                                            <ActionIcon size='lg' toolTipText='Detalle de la auditoría' icon={faSearch} onClick={() => onClickAuditDetailModal(item)} />
                                                        </td>
                                                    </tr>
                                                )
                                            
                                            :
                                            <tr>
                                                <td colSpan='13' className='text-center'>
                                                    {
                                                        !auditDataIsFetching &&
                                                            <Alert variant='info' className='mb-0'>
                                                                <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                                No hay registros
                                                            </Alert>
                                                    }
                                                </td>
                                            </tr>
                                    }
                                </tbody>

                            </Table>

                            <Row>
                            
                                <Col className={'text-right ' + (metadata?.page>1? '':'hidden')}>
                                    <Button size='sm' className='px-5' disabled={auditDataIsFetching || metadata?.page<2} onClick={()=>onClickLoadPage(metadata.page - 1)}>
                                        {
                                            auditDataIsFetching ?
                                                <Spinner animation='border' size='sm' />
                                                :
                                                <>{seeLess}</>
                                        }
                                    </Button>
                                </Col>

                                <Col className={'text-left ' + (metadata?.hasMore? '':'hidden')}>
                                    <Button size='sm' className='px-5' disabled={auditDataIsFetching || !metadata?.hasMore} onClick={()=>onClickLoadPage(metadata.page + 1)}>
                                        {
                                            auditDataIsFetching ?
                                                <Spinner animation='border' size='sm' />
                                                :
                                                <>{seeMore}</>
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
            <UserAuditDetailModal
                show = {show}
                handleClose = {handleClose}
                dataModal = { dataModal }
            />
        </Card>
        <AppLoading isLoading={auditDataIsFetching} />
    </Container>;

}
export default AuditsUsersPage; 