import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { Container, Card, Form, Row, Col, Button, Table, Spinner, Alert } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,  faFilter, faBroom} from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import { formatterPeso, dateToStringFormatedToShowARG, numberNegativeRed } from 'src/utils/utils';
import { isNotEmptyArray } from 'src/services/validationService';

import { getCreditQueryDataSelected, getAffectationHistoryDataSelected } from 'src/redux/affectation/affectationReducer';
import { reverseAffectation } from '../../../redux/affectation/affectationActionCreator';

import CreditDetailPageModifyDetail from 'src/components/forms/credit/detail/CreditDetailPageModifyDetail';
import CreditExecutionBalanceDetailComponent from 'src/components/pages/CreditExecution/CreditExecutionBalanceDetailComponent';
import ExpedientTableCreditQuery from 'src/components/pages/CreditExecution/ExpedientTableCreditQuery'
import { CREDIT_EXECUTION_DETAILS_REVERT, CREDIT_EXECUTION } from 'src/utils/constants';

import { getSubcodeAffectationsHistoryData, getSubcodeAffectationsHistoryIsFetching, getSubcodeDataData, getSubcodeDataIsFetching } from 'src/redux/subcode/subcodeReducer'
import { tryGetSubcodeAffectationsHistory, tryGetSubcodeData, tryGetSubcodeDetails } from 'src/redux/subcode/subcodeActionCreator';
import { tryGetActiveAdminsitrativeServiceFromExercise } from 'src/redux/exercise/exerciseActionCreator';
import { getActiveAdministrativeServiceFromExerciseData } from 'src/redux/exercise/exerciseReducer';


import { getUserPermissionsExecutionBudget } from 'src/redux/login/loginReducer';

import { actions, numberCreditAbbreviation, creditInquiry, visibleName, jurisdiction, creditOrigin, creditBalance, modifications, currentCredit, totalMonth, preventive, seatNumber, definitive,  obligationPay, pay, affectationsHistory, expedientNumberAbbreviation, dateImpact, typesOfAffectation, amountNumber, reverse, noHistory, back, detail } from 'src/utils/label';
import { getClearObject} from 'src/utils/utils';
import moment from 'moment';

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

import ActionIcon from 'src/components/general/ActionIcon';
import AppLoading from '../../common/AppLoading';

const SUB_CODE_DETAILS_FIELDS = {
	NUMERO_ASIENTO: 'accountingNumber',
	NUMERO_EXPEDIENTE: 'administrativeDocument',
	FECHA_AFECTACION: 'affectationDate',
	TIPO_AFECTACION: 'affectationStatusLabel'
}

import RCPagination from 'src/components/common/RCPagination';

const CreditExecutionCreditQueryPage = () => {

	const dispatch = useDispatch();

	const { handleSubmit, register, reset } = useForm();
	
	const subcodeHistorialData = useSelector(state => getAffectationHistoryDataSelected(state));

	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

	// Permissions
	const executionBudgetPermissions = useSelector(state => getUserPermissionsExecutionBudget(state));

	const subcodeAffectationsHistoryData = useSelector(state => getSubcodeAffectationsHistoryData(state));
	const subcodeHistory = subcodeAffectationsHistoryData?.data;
	const hasSubcodeHistory = isNotEmptyArray(subcodeHistory);
	const subcodeAffectationsHistoryIsFetching = useSelector(state => getSubcodeAffectationsHistoryIsFetching(state));
	const mapAddDateToShow = history => ({...history, dateToShown: moment(history?.affectationDate).format('DD/MM/YYYY')});

	// Credit details
	const subcodeDetails = useSelector(state => getSubcodeDataData(state));
	const subcodeDetailsIsFetching = useSelector(state => getSubcodeDataIsFetching(state));
	const subcodeData = useSelector(state => getCreditQueryDataSelected(state));
	const periodRpId = subcodeData?.rpParams?.period_rp_id;
	const periodName = subcodeData?.rpParams?.period;
	const periodRpName= subcodeData?.rpParams?.periodRpName;
	const serviceId = subcodeData?.code?.credit?.service?.id;

	const serviceStatusData = useSelector(state => getActiveAdministrativeServiceFromExerciseData(state));
	const serviceStatus = serviceStatusData?.active;

	// Amount Credit
	const currentCreditAmount = formatterPeso.format(subcodeDetails?.credit?.currentCredit);
	// const getItemClassCurrentCredit = amount => {
	// 	return `px-3 text-left align-middle ${subcodeData?.credit?.currentCredit < 0 ? 'text-danger' : ''}`;
	// }
	const originalCreditAmount = formatterPeso.format(subcodeDetails?.credit?.originalCredit);
	const modificationCreditAmount = formatterPeso.format(subcodeDetails?.credit?.modificationCredit);

	// Amount Monthly
	const monthlyPreventiveCreditAmount = formatterPeso.format(subcodeDetails?.balance?.monthly?.preventive);
	const monthlyDefinitiveCreditAmount = formatterPeso.format(subcodeDetails?.balance?.monthly?.definitive);
	const monthlyPaymentObligationCreditAmount = formatterPeso.format(subcodeDetails?.balance?.monthly?.payment_obligation);
	const monthlyPaymentCreditAmount = formatterPeso.format(subcodeDetails?.balance?.monthly?.payment);
	
	// Amount Balance Current
	const currentBalanceCreditAmount = formatterPeso.format(subcodeDetails?.balance?.currentBalance);

	//Affectation History pagination

	const [affectationHistoryFilter, setAffectationHistoryFilter] = useState({});
	const todayDate = moment(new Date()).format('YYYY-MM-DD');

	const defaultParamsAffectationsHistory = {
        page: 1,
        pageSize: 10,
    };

	const totalItemsCount = subcodeAffectationsHistoryData?.totalCount ? subcodeAffectationsHistoryData?.totalCount : 1;
    const totalItems = subcodeAffectationsHistoryData?.totalCount ? subcodeAffectationsHistoryData?.totalCount : '0';

	const onPageChange = (pNumber) => {

        const params = {
            ...defaultParamsAffectationsHistory,
			filter: affectationHistoryFilter,
            page: pNumber
        };

        loadAffectationHistoryData(params);
    };

	const onSubmitForm = (data) => {

		let paramsToSend = getClearObject(data);

		let newFilterObject = { 
			...defaultParamsAffectationsHistory,   
			filter: paramsToSend,
		};

		setAffectationHistoryFilter(paramsToSend);

		loadAffectationHistoryData(newFilterObject);
	}

	const cleanFilters = () => {
		reset();
		setAffectationHistoryFilter({});
		loadAffectationHistoryData(defaultParamsAffectationsHistory);
    };
	
	//Revert
	const onClickReverseAffectation = (item) => {

		const message = item?.actions?.desafectationAction?.alertMessage;
		const url = item?.actions?.desafectationAction?.action;

		let params = null;
		if(subcodeData?.rpParams?.period_rp_id){
			params = {
				period_id_rp: subcodeData?.rpParams?.period_rp_id,
				period_id: globalSelectedPeriod?.id
			}
		}

		swal({
            title: 'Confirmación',
            text: message,
            icon: 'warning',
            buttons: ["Cancelar", "Aceptar"]
        })
            .then((willDelete) => {
                if (willDelete) {
					dispatch(reverseAffectation(url, params)).then(
						response => {
							if (response?.status == 200) {
								dispatch(push(CREDIT_EXECUTION_DETAILS_REVERT));
							}
						}
					);
				}
			});
	};

	const loadAffectationHistoryData = (params) => {

		const paramsToSend = {
			subcodeId: subcodeHistorialData?.id,
			...params
		};

		dispatch(tryGetSubcodeAffectationsHistory(paramsToSend));
	};

	const loadSubCodeDetails = () => {

		const filter = {
			period_id: globalSelectedPeriod?.id,
			period_rp_id: subcodeData?.rpParams?.period_rp_id,
			sub_code_id: subcodeHistorialData?.id,
			is_passive_remaining: subcodeData?.rpParams?.isPassiveRemaining
		}

		const filterToSend = getClearObject(filter);

		dispatch(tryGetSubcodeData({filter: filterToSend}));
	};

	useEffect(() => {
		if(!subcodeData) {
			dispatch( push(CREDIT_EXECUTION) );
		} else {
			loadSubCodeDetails();
			loadAffectationHistoryData(defaultParamsAffectationsHistory);
			dispatch(tryGetSubcodeDetails({ subcodeId: subcodeHistorialData?.id , period_rp_id: subcodeData?.rpParams?.period_rp_id}));
			dispatch(tryGetActiveAdminsitrativeServiceFromExercise(globalSelectedPeriod?.id, serviceId));
		};
	}, []);


	return <Container fluid>
		<Card>

			<PageTitle text='Detalle de partida presupuestaria' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='h6'>
						{creditInquiry}
					</Card.Header>
					<Card.Body>
						<Container fluid hidden={subcodeDetailsIsFetching}>							
							<Row>
								<Col xs={12} className='text-right'>
									<Form.Group className='mb-0' as={Row} controlId='nroCredit'>
										<Form.Label className='text-black-color' column sm='3'>
										Ejercicio:
									</Form.Label>
										<Col sm='9'>
											<Form.Control plaintext readOnly className='px-3' value={subcodeData?.rpParams?.period} />
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xs={12} className='text-right'>
									<Form.Group className='mb-0' as={Row} controlId='nroCredit'>
										<Form.Label className='text-black-color' column sm='3'>
										Tipo de partida:
									</Form.Label>
										<Col sm='9'>
											<Form.Control plaintext readOnly className='px-3' value={subcodeData?.rpParams?.value} />
										</Col>
									</Form.Group>
								</Col>
							</Row>						
							<Row>
								<Col xs={12} className='text-right'>
									<Form.Group className='mb-0' as={Row} controlId='nroCredit'>
										<Form.Label className='text-black-color' column sm='3'>
										{numberCreditAbbreviation}:
									</Form.Label>
										<Col sm='9'>
											<Form.Control plaintext readOnly className='px-3' value={subcodeDetails?.completeNumber} />
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xs={12} className='text-right'>
									<Form.Group className='mb-0' as={Row} controlId='denominacion'>
										<Form.Label className='text-black-color' column sm='3'>
											{visibleName}:
									</Form.Label>
										<Col sm='9'>
											<Form.Control plaintext readOnly className='px-3' value={subcodeDetails?.visibleName} />
										</Col>
									</Form.Group>
								</Col>
							</Row>							
							<Row>
								<Col xs={12} className='text-right'>
									<Form.Group className='mb-0' as={Row} controlId='jurisdiction'>
										<Form.Label className='text-black-color' column sm='3'>
											{jurisdiction}:
									</Form.Label>
										<Col sm='9' className='text-left'>
											<Form.Control plaintext readOnly className='px-3' value={`${subcodeDetails?.code?.credit?.organization?.jurisdiction?.number} - ${subcodeDetails?.code?.credit?.organization?.jurisdiction?.name}`} />
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xs={12} className='text-right'>

									<Form.Group className='mb-0' as={Row} controlId='organization'>
										<Form.Label className='text-black-color' column sm='3'>
											U. Org:
									</Form.Label>
										<Col sm='9' className='text-left'>
											<Form.Control plaintext readOnly className='px-3' value={`${subcodeDetails?.code?.credit?.organization?.number} - ${subcodeDetails?.code?.credit?.organization?.name}`} />
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xs={12} className='text-right'>
									<Form.Group className='mb-0' as={Row} controlId='character'>
										<Form.Label className='text-black-color' column sm='3'>
											Carácter:
									</Form.Label>
										<Col sm='9' className='text-left'>
											<Form.Control plaintext readOnly className='px-3' value={`${subcodeDetails?.code?.credit?.character?.code} - ${subcodeDetails?.code?.credit?.character?.name}`} />
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xs={12} className='text-right'>
									<Form.Group className='mb-0' as={Row} controlId='account'>
										<Form.Label className='text-black-color' column sm='3'>
											Cuenta:
									</Form.Label>
										<Col sm='9' className='text-left'>
											<Form.Control plaintext readOnly className='px-3' value={`${subcodeDetails?.code?.credit?.account?.number} - ${subcodeDetails?.code?.credit?.account?.name}`} />
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<hr></hr>
							<Row>
								<Col xs={6} className='text-right'>

									<Form.Group className='mb-0' as={Row} controlId="originalCredit">
										<Form.Label className="text-black-color" column sm="5">
											{creditOrigin}:
									</Form.Label>
										<Col sm="7">
											<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeDetails?.credit?.originalCredit)} text-right p-2`} value={originalCreditAmount} />
										</Col>
									</Form.Group>

								</Col>

								<Col xs={6} className='text-right'>

									<Form.Group className='mb-0' as={Row} controlId="balanceCredit">
										<Form.Label className="text-black-color" column sm="5">
											{creditBalance}:
									</Form.Label>
										<Col sm="7">
											<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeDetails?.balance?.currentBalance)} text-right p-2`} value={currentBalanceCreditAmount} />
										</Col>
									</Form.Group>

								</Col>

							</Row>
							<Row>
								<Col xs={6} className='text-right'>

									<Form.Group className='mb-0' as={Row} controlId="modifyCredit">
										<Form.Label className="text-black-color" column sm="5">
											{modifications}:
									</Form.Label>
										<Col sm="7">
											<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeDetails?.credit?.modificationCredit)} text-right p-2`} value={modificationCreditAmount} />
										</Col>
									</Form.Group>

								</Col>

								<Col xs={6} className='text-right'>
									<Form.Group className='mb-0' as={Row} controlId="sector1">
										<Form.Label className="text-black-color" column sm="5">
											{/* Sector: */}
										</Form.Label>
										<Col sm="7">
											{/* <Form.Control plaintext readOnly defaultValue="XXXXX" /> */}
										</Col>
									</Form.Group>

								</Col>

							</Row>
							<Row>

								<Col xs={6} className='text-right'>

									<Form.Group className='mb-0' as={Row} controlId="currentCredit">
										<Form.Label className="text-black-color" column sm="5">
											{currentCredit}:
									</Form.Label>
										<Col sm="7">
											<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeDetails?.credit?.currentCredit)} text-right p-2`} value={currentCreditAmount} />
										</Col>
									</Form.Group>

								</Col>
								<Col xs={6} className='text-right'>
									<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail" c>
										<Form.Label className="text-black-color" column sm="5">
											{/* Partida primaria: */}
										</Form.Label>
										<Col sm="7">
											{/* <Form.Control plaintext readOnly defaultValue="XXXXX" /> */}
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<hr></hr>
							<div>
								<h5 className='text-center text-black-color'>{totalMonth}</h5>
							</div>
							<Row>
								<Col xs={6} className='text-right'>
									<Form.Group className='mb-0' as={Row} controlId="preventive">
										<Form.Label className="text-black-color" column sm="5">
											{preventive}:
									</Form.Label>
										<Col sm="7">
											<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeDetails?.balance?.monthly?.preventive)} text-right p-2`} value={monthlyPreventiveCreditAmount} />
										</Col>
									</Form.Group>
								</Col>
								<Col xs={6} className='text-right'>
									<Form.Group className='mb-0' as={Row} controlId="definitive">
										<Form.Label className="text-black-color" column sm="5">
											{definitive}:
									</Form.Label>
										<Col sm="7">
											<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeDetails?.balance?.monthly?.definitive)} text-right p-2`} value={monthlyDefinitiveCreditAmount} />
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xs={6} className='text-right'>

									<Form.Group className='mb-0' as={Row} controlId="paymentObligation">
										<Form.Label className="text-black-color" column sm="5">
											{obligationPay}:
									</Form.Label>
										<Col sm="7">
											<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeDetails?.balance?.monthly?.payment_obligation)} text-right p-2`} value={monthlyPaymentObligationCreditAmount} />
										</Col>
									</Form.Group>

								</Col>

								<Col xs={6} className='text-right'>

									<Form.Group className='mb-0' as={Row} controlId="payment">
										<Form.Label className="text-black-color" column sm="5">
											{pay}:
									</Form.Label>
										<Col sm="7">
											<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeDetails?.balance?.monthly?.payment)} text-right p-2`} value={monthlyPaymentCreditAmount} />
										</Col>
									</Form.Group>

								</Col>

							</Row>
						</Container>
						{
							subcodeDetailsIsFetching &&
								<div className={`text-center text-black-color`}>
									<Spinner animation="border" size="sm" />
								</div>
						}
					</Card.Body>

					<Card className='card-body'>
						<CreditDetailPageModifyDetail />
					</Card>

					<Card className='card-body' hidden={subcodeDetailsIsFetching}>
						<CreditExecutionBalanceDetailComponent
							data={subcodeDetails}
							title={'Saldos'}
						/>
					</Card>
					{
						subcodeDetailsIsFetching &&
							<div className={`text-center text-black-color`}>
								<Spinner animation="border" size="sm" />
							</div>
					}

					<Card>
						<Card.Header>
							<h6 className='mb-0'>Historial de Expedientes de la Partida</h6>
						</Card.Header>
						<Card.Body>
							<ExpedientTableCreditQuery 
								filter={{subCodeId: subcodeData?.id, periodRpId: periodRpId}}
								details={{periodName: periodName, periodRpName: periodRpName }}
								subcodeData={subcodeData}
								loadAffectationHistoryData={loadAffectationHistoryData}
								cleanAffectationHystoryFilter={cleanFilters}
								/>
						</Card.Body>
					</Card>
					<Card>
						<Card.Header>
							<h6 className='mb-0'>{affectationsHistory}</h6>
						</Card.Header>
						<Card.Body>
							<div className='table-responsive'>
								<Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off'>
									<Table bordered hover size='sm'>
										<thead>
											<tr>
												<th className='text-center'>{seatNumber}</th>
												<th className='text-center'>{expedientNumberAbbreviation}</th>
												<th className='text-center'>{dateImpact}</th>
												<th className='text-center'>{typesOfAffectation}</th>
												<th className='text-center'>{detail}</th>
												<th className='text-center'>{amountNumber}</th>
												<th className='text-center'>{actions}</th>
												{/* <th className='text-center'>Adjunto</th> */}

											</tr>
											<tr className='secondary'>
												<th>
													<Form.Control className='text-center' name='accounting_number' ref={register} size='sm'/>
												</th>
												<th>
													<Form.Control className='text-center' name='administrative_document_number' ref={register} size='sm' />
												</th>
												<th>
													<Form.Control type='date' max={todayDate} className='text-center' name='affectation_date' ref={register} size='sm' />
												</th>
												<th>
													<Form.Control className='text-center' name='affectation_status_label' ref={register} size='sm' />
												</th>
												<th>
													<Form.Control className='text-center' name='extract' ref={register} size='sm' />
												</th>
												<th>
												</th>
												<th>
													<th className='text-center justify-content-around custom-button border-0'>
														<>
															<ActionIcon
																size="lg"
																id="search-button"
																className="text-white-color"
																toolTipText="Filtrar"
																icon={faFilter}
																type='submit'										
															/>
														</>
														<>
															<ActionIcon
																size="lg"
																id="clean-filter"
																className="text-white-color"
																toolTipText="Limpiar filtros"
																icon={faBroom}
																type='reset'
																onClick={()=> cleanFilters()}
															/>
														</>
													</th>
												</th>
											</tr>
										</thead>
										<tbody className='text-black-color'>
											{
												hasSubcodeHistory
													?
													<>

														{
															subcodeHistory?.map(item =>
															<>
																{
																
																	<>
																		<tr style={{backgroundColor: "#EDEEF1"}}>

																			<td className='text-center'>{item?.accountingNumber}</td>

																			<td className='text-center'>{item?.administrativeDocument?.completeLabel}</td>

																			<td className='text-center'>{dateToStringFormatedToShowARG(item?.affectationDate)}</td>

																			<td className='text-center'>{item?.affectationStatusLabel}</td>

																			<td className='text-center'>{item?.extract || '-'}</td>

																			<td className={`${numberNegativeRed(item?.amount)} + text-right p-2`} >
																			{formatterPeso.format(item?.amount)}
																			</td>

																			{/* <td className='text-center'><ActionIcon size='lg' id='download' toolTipText='Descargar reporte' icon={faFilePdf} onClick={() => onClickDownloadReport(item?.reportUrl)} /></td> */}

																			<th className='text-center align-middle'>
																				{
																					executionBudgetPermissions?.canCreate &&
																						<Button
																							type='button'
																							variant='danger'
																							Size='md'
																							title='Revertir afectación'
																							disabled={!item?.actions?.desafectationAction?.action || !serviceStatus}
																							onClick={() => onClickReverseAffectation(item)}
																						>
																							{reverse}
																						</Button>
																				}	
																			</th>
																	
																		</tr>
																	</>
																}

															</>)
														}
													</>
													:
													<tr>
														<td colSpan='7' className='text-center'>
															{
																subcodeAffectationsHistoryIsFetching
																	?
																	<Spinner animation='border' size='sm' />
																	:
																	<Alert variant='info' className='mb-0'>
																		<FontAwesomeIcon icon={''} className='text-black-color mr-3' />
																		{noHistory}
																	</Alert>
															}
														</td>
													</tr>
											}
										</tbody>
									</Table>
								</Form>
								{hasSubcodeHistory &&
									<RCPagination
										activePage={subcodeAffectationsHistoryData?.page}
										itemsCountPerPage={subcodeAffectationsHistoryData?.perPage}
										totalItemsCount={totalItemsCount}
										pageRangeDisplayed={7}
										thePage={subcodeAffectationsHistoryData?.page}
										totalItems={totalItems}
										innerClass="justify-content-center"
										onChange={onPageChange}
									/> 
								}
							</div>
								
						</Card.Body>
					</Card>
					<AppLoading isLoading={subcodeDetailsIsFetching}/>
				</Card>


			</Container>
			<hr></hr>
			<div className='d-flex justify-content-around mt-4 mb-3'>
				<Button type='button' variant='danger' size='lg' onClick={() => dispatch(goBack())} >

					<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
							{back}
						</Button>
			</div>
			<div className='mx-auto mb-4'>

			</div>
		</Card>
	</Container>;
};

export default CreditExecutionCreditQueryPage;