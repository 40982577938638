import React from 'react';

import {
  Switch,
  Route
} from "react-router-dom";



import IndexPage from 'src/components/pages/IndexPage';

import CreditDetailPage from 'src/components/pages/CreditDetailPage';
import CreditListingPage from 'src/components/pages/CreditListingPage';
import CreditStepOnePage from 'src/components/pages/CreditStepOnePage';
import CreditStepTwoPage from 'src/components/pages/CreditStepTwoPage';
import CreditStepTwoPageNew from 'src/components/pages/CreditStepTwoPageNew';

import CompensationStepOnePage from 'src/components/pages/Compensation/CompensationStepOnePage';
import CompensationStepTwoPage from 'src/components/pages/Compensation/CompensationStepTwoPage';
import CompensationStepThreePage from 'src/components/pages/Compensation/CompensationStepThreePage';

import CreditIncorporationStepOnePage from 'src/components/pages/CreditIncorporation/CreditIncorporationStepOnePage.js';
import CreditIncorporationStepTwoPage from 'src/components/pages/CreditIncorporation/CreditIncorporationStepTwoPage.js';

import CreditExecutionListingPage from 'src/components/pages/CreditExecution/CreditExecutionListingPage';
import CreditExecutionNewAffectationStepOnePage from 'src/components/pages/CreditExecution/CreditExecutionNewAffectationStepOnePage';
import CreditExecutionNewAffectationStepTwoPage from 'src/components/pages/CreditExecution/CreditExecutionNewAffectationStepTwoPage';
import CreditExecutionNewAffectationStepThreePage from 'src/components/pages/CreditExecution/CreditExecutionNewAffectationStepThreePage';
import CreditExecutionNewAffectationStepFourPage from 'src/components/pages/CreditExecution/CreditExecutionNewAffectationStepFourPage';
import CreditExecutionCreditQueryPage from 'src/components/pages/CreditExecution/CreditExecutionCreditQueryPage';
import CreditExecutionAffectationHistoryPage from 'src/components/pages/CreditExecution/CreditExecutionAffectationHistoryPage';
import CreditExecutionBalanceDetailComponent from 'src/components/pages/CreditExecution/CreditExecutionBalanceDetailComponent';
import CreditExecutionBalanceTotalModalComponent from 'src/components/pages/CreditExecution/CreditExecutionBalanceTotalModalComponent';
import CreditExecutionDetailsRevert from 'src/components/pages/CreditExecution/CreditExecutionDetailsRevert';

import BudgetItemControlPage from 'src/components/pages/BudgetItemControlPage';
import BudgetSubCodePage from 'src/components/pages/BudgetSubCodePage';

import UsersListingPage from 'src/components/pages/UsersListingPage';
import UserNewPage from 'src/components/pages/UserNewPage';
import UserNewJurisdictionPage from 'src/components/pages/UserNewJurisdictionPage';
import UserEditPage from 'src/components/pages/UserEditPage';
import UserEditPasswordPage from 'src/components/pages/UserEditPasswordPage';
import UserDetailsPage from 'src/components/pages/UserDetailsPage';
import ProfileListingPage from 'src/components/pages/ProfileListingPage';
import ProfileNewPage from 'src/components/pages/ProfileNewPage';
import ProfileEditPage from 'src/components/pages/ProfileEditPage';

import ReportOrganismAnalyticalPage from 'src/components/pages/Reports/ReportOrganismAnalyticalPage';
import ReportJurisdictionAnalyticalPage from 'src/components/pages/Reports/ReportJurisdictionAnalyticalPage';
import ReportConsolidatedPage from 'src/components/pages/Reports/ReportConsolidatedPage';
import ReportJurisdictionTotalPage from 'src/components/pages/Reports/ReportJurisdictionTotalPage';
import ReportOrganismTotalPage from 'src/components/pages/Reports/ReportOrganismTotalPage';
import ReportTotalCrossedPurposeFunctionObjectExpenditurePage from 'src/components/pages/Reports/ReportTotalCrossedPurposeFunctionObjectExpenditurePage';
import ReportAffectationsByAdministrativeDocument from 'src/components/pages/Reports/ReportAffectationsByAdministrativeDocumentPage';
import ReportInstrumentLegalModifyPage from 'src/components/pages/Reports/ReportInstrumentLegalModifyPage';
import ReportBudgetStatusCreditExecutionPage from 'src/components/pages/Reports/ReportBudgetStatusCreditExecutionPage';
import ReportBudgetStatusCreditExecutionV2Page from 'src/components/pages/Reports/ReportBudgetStatusCreditExecutionV2Page';
import ReportCreditModifyCreditExecutionPage from 'src/components/pages/Reports/ReportCreditModifyCreditExecutionPage';
import ReportCreditModifyCreditExecutionPageV2 from 'src/components/pages/Reports/ReportCreditModifyCreditExecutionPageV2';
import ReportMovementsStatusCreditExecutionPage from 'src/components/pages/Reports/ReportMovementsStatusCreditExecutionPage';
import ReportWorksBudgetAnalyticalRecordCreditExecutionPage from 'src/components/pages/Reports/ReportWorksBudgetAnalyticalRecordCreditExecutionPage';
import ReportBudgetAnalyticalAccountingCreditExecutionPage from 'src/components/pages/Reports/ReportBudgetAnalyticalAccountingCreditExecutionPage';
import ReportBudgetAnalyticalAccountingCreditExecutionPageV2 from 'src/components/pages/Reports/ReportBudgetAnalyticalAccountingCreditExecutionPageV2';
import ReportBalanceStatusPaymentListCreditExecutionPage from 'src/components/pages/Reports/ReportBalanceStatusPaymentListCreditExecutionPage';
import ReportBalanceStatusPaymentListCreditExecutionPageV2 from 'src/components/pages/Reports/ReportBalanceStatusPaymentListCreditExecutionPageV2';
import ReportDelayedFilesCreditExecutionPage from 'src/components/pages/Reports/ReportDelayedFilesCreditExecutionPage';
import ReportTableTotalsCreditExecutionPage from 'src/components/pages/Reports/ReportTableTotalsCreditExecutionPage';
import ReportTableTotalsCreditExecutionPageGenerated from 'src/components/pages/Reports/ReportTableTotalsCreditExecutionPageGenerated';
import ReportAccrualBasis from 'src/components/pages/Reports/ReportAccrualBasis';
import ReportDeliveryOrdersFundRequestPage from 'src/components/pages/Reports/ReportDeliveryOrdersFundRequestPage';
import ReportFundRequestPage from 'src/components/pages/Reports/ReportFundRequestPage';
import ReportBankAccountFundRequestPage from 'src/components/pages/Reports/ReportBankAccountFundRequestPage';
import ReportProviderFundRequestPage from 'src/components/pages/Reports/ReportProviderFundRequestPage';
import ReportServiceDirectionFundRequestPage from 'src/components/pages/Reports/ReportServiceDirectionFundRequestPage';
import ReportNewFundRequestPage from 'src/components/pages/Reports/ReportNewFundRequestPage';
import ReportPaymentOrderAdministrativeServiceFundRequestPage from 'src/components/pages/Reports/ReportPaymentOrderAdministrativeServiceFundRequestPage';
import ReportBalanceStatusPage from 'src/components/pages/Reports/ReportBalanceStatusPage';
import ReportBalanceStatusV2Page from 'src/components/pages/Reports/ReportBalanceStatusV2Page';
import ReportImport from 'src/components/pages/Reports/ReportImport';
import ReportDeterminationPassiveResiduesPage from 'src/components/pages/Reports/ReportDeterminationPassiveResiduesPage';
import ReportDetailPassiveResiduesPage from 'src/components/pages/Reports/ReportDetailPassiveResiduesPage';
import ReportPendingResolutionPassiveResiduesPage from 'src/components/pages/Reports/ReportPendingResolutionPassiveResiduesPage';

import CreditExecutionAffectationPreLoadEdit from 'src/components/pages/CreditExecution/CreditExecutionAffectationPreLoadEdit';
import ReportConvertionRP from 'src/components/pages/Reports/ReportConvertionRP';
import ReportAdministrativeDocumentVoucher from 'src/components/pages/Reports/ReportAdministrativeDocumentVoucher';

import FigurativeExpedituresListPage from 'src/components/pages/FigurativeExpeditures/FigurativeExpedituresListPage';
import FigurativeExpedituresNewPage from 'src/components/pages/FigurativeExpeditures/FigurativeExpedituresNewPage';
import FigurativeExpedituresEditPage from 'src/components/pages/FigurativeExpeditures/FigurativeExpedituresEditPage';

import RevertPassiveRemainingByServicePage from '../components/pages/RevertPassiveRemainingByService/RevertPassiveRemainingByServicePage';

import ExercisesListPage from 'src/components/pages/Exercise/ExercisesListPage';
import ExerciseNewPage from 'src/components/pages/Exercise/ExerciseNewPage';
import ExerciseEditStepOnePage from 'src/components/pages/Exercise/ExerciseEditStepOnePage';
import ExerciseEditStepTwoPage from 'src/components/pages/Exercise/ExerciseEditStepTwoPage';
import ExerciseDetailsPage from 'src/components/pages/Exercise/ExerciseDetailsPage';
import ExerciseServiceAsociation from 'src/components/pages/Exercise/ExerciseServiceAsociation';

import ServiceAdministrativeListPage from 'src/components/pages/ServiceAdministrative/ServiceAdministrativeListPage';
import ServiceAdministrativeNewPage from 'src/components/pages/ServiceAdministrative/ServiceAdministrativeNewPage';
import ServiceAdministrativeEditPage from 'src/components/pages/ServiceAdministrative/ServiceAdministrativeEditPage';
import ServiceAdministrativeDetailsPage from 'src/components/pages/ServiceAdministrative/ServiceAdministrativeDetailsPage';
import ServiceAdmnistrativeUserPage from 'src/components/pages/ServiceAdministrative/ServiceAdmnistrativeUserPage';
import ServiceAdministrativeOrganismPage from 'src/components/pages/ServiceAdministrative/ServiceAdministrativeOrganismPage';

import AdministrativeOrganismListPage from 'src/components/pages/AdministrativeOrganism/AdministrativeOrganismListPage';
import AdministrativeOrganismNewPage from 'src/components/pages/AdministrativeOrganism/AdministrativeOrganismNewPage';
import AdministrativeOrganismEditPage from 'src/components/pages/AdministrativeOrganism/AdministrativeOrganismEditPage';
import AdministrativeOrganismDetailsPage from 'src/components/pages/AdministrativeOrganism/AdministrativeOrganismDetailsPage';

import OrderPayListPage from 'src/components/pages/OrderPay/OrderPayListPage';
import OrderPayNewPage from 'src/components/pages/OrderPay/OrderPayNewPage';
import OrderPayEditPage from 'src/components/pages/OrderPay/OrderPayEditPage';
import OrderPayDetailsPage from 'src/components/pages/OrderPay/OrderPayDetailsPage';

import FundRequestsListPage from 'src/components/pages/FundRequests/FundRequestsListPage';
import FundRequestsNewPage from 'src/components/pages/FundRequests/FundRequestsNewPage';
import FundRequestsEditPage from 'src/components/pages/FundRequests/FundRequestsEditPage';
import FundRequestsDetailsPage from 'src/components/pages/FundRequests/FundRequestsDetailsPage';

import DeliveryOrderListPage from 'src/components/pages/DeliveryOrder/DeliveryOrderListPage';
import DeliveryOrderNewPage from 'src/components/pages/DeliveryOrder/DeliveryOrderNewPage';
import DeliveryOrderNewPageMassive from 'src/components/pages/DeliveryOrder/DeliveryOrderNewPageMassive';
import DeliveryOrderEditPage from 'src/components/pages/DeliveryOrder/DeliveryOrderEditPage';
import DeliveryOrderDetailsPage from 'src/components/pages/DeliveryOrder/DeliveryOrderDetailsPage';

import ProvisionOrderListPage from 'src/components/pages/ProvisionOrder/ProvisionOrderListPage';
import ProvisionOrderNewPage from 'src/components/pages/ProvisionOrder/ProvisionOrderNewPage';
import ProvisionOrderEditPage from 'src/components/pages/ProvisionOrder/ProvisionOrderEditPage';
import ProvisionOrderDetailPage from '../components/pages/ProvisionOrder/ProvisionOrderDetailPage';

import BankAccountListPage from 'src/components/pages/BankAccounts/BankAccountListPage';
import BankAccountNewPage from 'src/components/pages/BankAccounts/BankAccountNewPage';
import BankAccountEditPage from 'src/components/pages/BankAccounts/BankAccountEditPage';
import BankAccountDetailsPage from 'src/components/pages/BankAccounts/BankAccountDetailsPage';


import AuditsListPage from 'src/components/pages/Audits/AuditsListPage';
import AuditsUsersPage from 'src/components/pages/Audits/AuditsUsersPage';

import CaptionListPage from 'src/components/pages/Caption/CaptionListPage';
import CaptionNewPage from 'src/components/pages/Caption/CaptionNewPage';
import CaptionEditPage from 'src/components/pages/Caption/CaptionEditPage';

import AlertListPage from 'src/components/pages/Alert/AlertListPage';
import AlertNewPage from 'src/components/pages/Alert/AlertNewPage';
import AlertEditPage from 'src/components/pages/Alert/AlertEditPage';

import NotificationListingPage from 'src/components/pages/Notification/NotificationListingPage';
import NotificationNewPage from 'src/components/pages/Notification/NotificationNewPage';
import NotificationDetailsPage from 'src/components/pages/Notification/NotificationDetailsPage';

import ExpedientListPage from 'src/components/pages/Expedient/ExpedientListPage';
import ExpedientEditPage from 'src/components/pages/Expedient/ExpedientEditPage';

import MaintenanceSystemListPage from 'src/components/pages/MaintenanceSystem/MaintenanceSystemListPage';

import Header from '../components/general/header/Header';
import Navigation from '../components/general/Navigation';
import { ROUTE_PATHS as PATHS } from '../utils/constants';
import { useSelector } from 'react-redux';
import { getProfileName } from 'src/redux/login/loginReducer';

import WagesTemplateListPage from '../components/pages/WagesTemplate/WagesTemplateListPage';
import WagesTemplateNewPage from '../components/pages/WagesTemplate/WagesTemplateNewPage';
import WagesTemplateEditPage from '../components/pages/WagesTemplate/WagesTemplateEditPage';
import WagesTemplateDetailsPage from '../components/pages/WagesTemplate/WagesTemplateDetailsPage';

import ConceptListPage from '../components/pages/Concepts/ConceptListPage';
import ConceptNewPage from '../components/pages/Concepts/ConceptNewPage';
import ConceptEditPage from '../components/pages/Concepts/ConceptEditPage';

export const PrivateLayout = () => {
	const profileName = useSelector(state => getProfileName(state));

	const profileNameSA = profileName == 'Super Admin';
	const profileBudgetDirection = profileName == 'Dirección de Presupuesto';

	return (
			<>
				<Header />
				<Navigation></Navigation>
				<Switch>
					<Route path={PATHS.INDEX_PAGE} component={IndexPage} />

					<Route path={PATHS.CREDIT} component={CreditListingPage} />
					<Route path={PATHS.CREDIT_DETAIL} component={CreditDetailPage} />
					<Route path={PATHS.CREDIT_STEP_ONE} component={CreditStepOnePage} />
					<Route path={PATHS.CREDIT_STEP_TWO} component={CreditStepTwoPage} />
					<Route path={PATHS.CREDIT_STEP_TWO_NEW} component={CreditStepTwoPageNew} />

					<Route path={PATHS.INCORPORATION_CREDIT_STEP_ONE} component={CreditIncorporationStepOnePage} />
					<Route path={PATHS.INCORPORATION_CREDIT_STEP_TWO} component={CreditIncorporationStepTwoPage} />

					<Route path={PATHS.COMPENSATION_STEP_ONE} component={CompensationStepOnePage} />
					<Route path={PATHS.COMPENSATION_STEP_TWO} component={CompensationStepTwoPage} />
					<Route path={PATHS.COMPENSATION_STEP_THREE} component={CompensationStepThreePage} />
					
					<Route path={PATHS.CREDIT_EXECUTION} component={CreditExecutionListingPage} />
					<Route path={PATHS.CREDIT_EXECUTION_NEW_AFFECTATION_STEP_ONE} component={CreditExecutionNewAffectationStepOnePage} />
					<Route path={PATHS.CREDIT_EXECUTION_NEW_AFFECTATION_STEP_TWO} component={CreditExecutionNewAffectationStepTwoPage} />
					<Route path={PATHS.CREDIT_EXECUTION_NEW_AFFECTATION_STEP_THREE} component={CreditExecutionNewAffectationStepThreePage} />
					<Route path={PATHS.CREDIT_EXECUTION_NEW_AFFECTATION_STEP_FOUR} component={CreditExecutionNewAffectationStepFourPage} />
					<Route path={PATHS.CREDIT_EXECUTION_AFFECTATION_HISTORY} component={CreditExecutionAffectationHistoryPage} />
					<Route path={PATHS.CREDIT_EXECUTION_CREDIT_QUERY} component={CreditExecutionCreditQueryPage} />
					<Route path={PATHS.CREDIT_EXECUTION_BALANCE_DETAIL_COMPONENT} component={CreditExecutionBalanceDetailComponent} />
					<Route path={PATHS.CREDIT_EXECUTION_BALANCE_TOTAL_MODAL_COMPONENT} component={CreditExecutionBalanceTotalModalComponent} />
					<Route path={PATHS.CREDIT_EXECUTION_DETAILS_REVERT} component={CreditExecutionDetailsRevert} />

					<Route path={PATHS.BUDGET_ITEM_CONTROL} component={BudgetItemControlPage} />
					<Route path={PATHS.BUDGET_SUBCODE} component={BudgetSubCodePage} />

					<Route path={PATHS.USER_LIST} component={UsersListingPage} />
					<Route path={PATHS.USER_LIST_JURISDICTION} component={UserNewJurisdictionPage} />
					<Route path={PATHS.USER_NEW} component={UserNewPage} />
					<Route path={PATHS.USER_EDIT} component={UserEditPage} />
					<Route path={PATHS.EDIT_PASSWORD} component={UserEditPasswordPage} />
					<Route path={PATHS.USER_DETAIL} component={UserDetailsPage} />
					<Route path={PATHS.PROFILE_LIST} component={ProfileListingPage} />
					<Route path={PATHS.PROFILE_NEW} component={ProfileNewPage} />
					<Route path={PATHS.PROFILE_EDIT} component={ProfileEditPage} />

					<Route path={PATHS.REPORT_ORGANISM_ANALYTICAL} component={ReportOrganismAnalyticalPage} />
					<Route path={PATHS.REPORT_JURISDICTION_ANALYTICAL} component={ReportJurisdictionAnalyticalPage} />
					<Route path={PATHS.REPORT_CONSOLIDATED} component={ReportConsolidatedPage} />
					<Route path={PATHS.REPORT_JURISDICTION_TOTAL} component={ReportJurisdictionTotalPage} />
					<Route path={PATHS.REPORT_ORGANISM_TOTAL} component={ReportOrganismTotalPage} />
					<Route path={PATHS.REPORT_TOTAL_CROSSED_PURPOSE_FUNCTION_OBJECT_EXPENDITURE} component={ReportTotalCrossedPurposeFunctionObjectExpenditurePage} />
					<Route path={PATHS.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT} component={ReportAffectationsByAdministrativeDocument} />
					<Route path={PATHS.REPORT_INSTRUMENT_LEGAL_MODIFY} component={ReportInstrumentLegalModifyPage} />
					<Route path={PATHS.REPORT_BUDGET_STATUS_CREDIT_EXECUTION} component={ReportBudgetStatusCreditExecutionPage} />
					<Route path={PATHS.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_V2} component={ReportBudgetStatusCreditExecutionV2Page} />
					<Route path={PATHS.REPORT_ACCRUAL_BASIS} component={ReportAccrualBasis} />
					<Route path={PATHS.REPORT_CREDIT_MODIFY_CREDIT_EXECUTION} component={ReportCreditModifyCreditExecutionPage} />
					<Route path={PATHS.REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_V2} component={ReportCreditModifyCreditExecutionPageV2} />
					<Route path={PATHS.REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION} component={ReportMovementsStatusCreditExecutionPage} />
					<Route path={PATHS.REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION} component={ReportWorksBudgetAnalyticalRecordCreditExecutionPage} />
					<Route path={PATHS.REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_V2} component={ReportBudgetAnalyticalAccountingCreditExecutionPageV2} />
					<Route path={PATHS.REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION} component={ReportBudgetAnalyticalAccountingCreditExecutionPage} />
					<Route path={PATHS.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION} component={ReportBalanceStatusPaymentListCreditExecutionPage} />
					<Route path={PATHS.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_V2} component={ReportBalanceStatusPaymentListCreditExecutionPageV2} />
					<Route path={PATHS.REPORT_DELAYED_FILES_CREDIT_EXECUTION} component={ReportDelayedFilesCreditExecutionPage} />
					<Route path={PATHS.REPORT_TABLE_TOTALS_CREDIT_EXECUTION} component={ReportTableTotalsCreditExecutionPage} />
					<Route path={PATHS.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED} component={ReportTableTotalsCreditExecutionPageGenerated} />
					<Route path={PATHS.REPORT_DELIVERY_ORDERS_FUND_REQUEST} component={ReportDeliveryOrdersFundRequestPage} />
					<Route path={PATHS.REPORT_FUND_REQUEST} component={ReportFundRequestPage} />
					<Route path={PATHS.REPORT_BANK_ACCOUNT_FUND_REQUEST} component={ReportBankAccountFundRequestPage} />
					<Route path={PATHS.REPORT_PROVIDER_FUND_REQUEST} component={ReportProviderFundRequestPage} />
					<Route path={PATHS.REPORT_SERVICE_DIRECTION_FUND_REQUEST} component={ReportServiceDirectionFundRequestPage} />
					<Route path={PATHS.REPORT_NEW_FUND_REQUEST} component={ReportNewFundRequestPage} />
					<Route path={PATHS.REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST} component={ReportPaymentOrderAdministrativeServiceFundRequestPage} />
					<Route path={PATHS.REPORT_BALANCE_STATUS} component={ReportBalanceStatusPage} />
					<Route path={PATHS.REPORT_BALANCE_STATUS_V2} component={ReportBalanceStatusV2Page} />
					<Route path={PATHS.REPORT_IMPORTS} component={ReportImport} />
					<Route path={PATHS.REPORT_DETERMINATION_PASSIVE_RESIDUES} component={ReportDeterminationPassiveResiduesPage} />
					<Route path={PATHS.REPORT_DETAIL_PASSIVE_RESIDUES} component={ReportDetailPassiveResiduesPage} />
					<Route path={PATHS.REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES} component={ReportPendingResolutionPassiveResiduesPage} />
					
					<Route path={PATHS.PRE_LOAD} component={CreditExecutionAffectationPreLoadEdit} />

					<Route path={PATHS.FIGURATIVE_EXPEDITURES} component={FigurativeExpedituresListPage} />
					<Route path={PATHS.FIGURATIVE_EXPEDITURES_NEW} component={FigurativeExpedituresNewPage} />
					<Route path={PATHS.FIGURATIVE_EXPEDITURES_EDIT} component={FigurativeExpedituresEditPage} />

					<Route path={PATHS.WAGES_TEMPLATE_LIST} component={WagesTemplateListPage} />
					<Route path={PATHS.WAGES_TEMPLATE_NEW} component={WagesTemplateNewPage} />
					<Route path={PATHS.WAGES_TEMPLATE_EDIT} component={WagesTemplateEditPage} />
					<Route path={PATHS.WAGES_TEMPLATE_DETAIL} component={WagesTemplateDetailsPage} />

					<Route path={PATHS.REVERT_PASSIVE_REMAINING_BY_SERVICE} component={RevertPassiveRemainingByServicePage} />

					<Route path={PATHS.REPORT_CONVERTION} component={ReportConvertionRP} />
					<Route path={PATHS.REPORT_BY_ADMINISTRATIVE_VOUCHER} component={ReportAdministrativeDocumentVoucher} />

					<Route path={PATHS.EXERCISES_LIST} component={ExercisesListPage} />
					<Route path={PATHS.EXERCISE_NEW} component={ExerciseNewPage} />
					<Route path={PATHS.EXERCISE_EDIT_STEP_ONE} component={ExerciseEditStepOnePage} />
					<Route path={PATHS.EXERCISE_EDIT_STEP_TWO} component={ExerciseEditStepTwoPage} />
					<Route path={PATHS.EXERCISE_DETAILS} component={ExerciseDetailsPage} />
					<Route path={PATHS.EXERCISE_SERVICE_ASOCIATION} component={ExerciseServiceAsociation} />

					<Route path={PATHS.SERVICE_ADMINISTRATIVE_LIST} component={ServiceAdministrativeListPage} />
					<Route path={PATHS.SERVICE_ADMINISTRATIVED_NEW} component={ServiceAdministrativeNewPage} />
					<Route path={PATHS.SERVICE_ADMINISTRATIVE_EDIT} component={ServiceAdministrativeEditPage} />
					<Route path={PATHS.SERVICE_ADMINISTRATIVE_DETAILS} component={ServiceAdministrativeDetailsPage} />
					<Route path={PATHS.SERVICE_ADMINISTRATIVE_USER} component={ServiceAdmnistrativeUserPage} />
					<Route path={PATHS.SERVICE_ADMINISTRATIVE_ORGANISM} component={ServiceAdministrativeOrganismPage} />

					<Route path={PATHS.ADMINISTRATIVE_ORGANISM_LIST} component={AdministrativeOrganismListPage} />
					<Route path={PATHS.ADMINISTRATIVE_ORGANISM_NEW} component={AdministrativeOrganismNewPage} />
					<Route path={PATHS.ADMINISTRATIVE_ORGANISM_EDIT} component={AdministrativeOrganismEditPage} />
					<Route path={PATHS.ADMINISTRATIVE_ORGANISM_DETAILS} component={AdministrativeOrganismDetailsPage} />

					<Route path={PATHS.ORDER_PAY_LIST} component={OrderPayListPage} />
					<Route path={PATHS.ORDER_PAY_NEW} component={OrderPayNewPage} />
					<Route path={PATHS.ORDER_PAY_EDIT} component={OrderPayEditPage} />
					<Route path={PATHS.ORDER_PAY_DETAILS} component={OrderPayDetailsPage} />

					<Route path={PATHS.FUND_REQUESTS_LIST} component={FundRequestsListPage} />
					<Route path={PATHS.FUND_REQUESTS_NEW} component={FundRequestsNewPage} />
					<Route path={PATHS.FUND_REQUESTS_EDIT} component={FundRequestsEditPage} />
					<Route path={PATHS.FUND_REQUESTS_DETAILS} component={FundRequestsDetailsPage} />

					<Route path={PATHS.DELIVERY_ORDER_LIST} component={DeliveryOrderListPage} />
					<Route path={PATHS.DELIVERY_ORDER_NEW} component={DeliveryOrderNewPage} />
					<Route path={PATHS.DELIVERY_ORDER_NEW_MASSIVE} component={DeliveryOrderNewPageMassive} />
					<Route path={PATHS.DELIVERY_ORDER_EDIT} component={DeliveryOrderEditPage} />
					<Route path={PATHS.DELIVERY_ORDER_DETAILS} component={DeliveryOrderDetailsPage} />

					<Route path={PATHS.PROVISION_ORDER_LIST} component={ProvisionOrderListPage} />
					<Route path={PATHS.PROVISION_ORDER_NEW} component={ProvisionOrderNewPage} />
					<Route path={PATHS.PROVISION_ORDER_EDIT} component={ProvisionOrderEditPage} />
					<Route path={PATHS.PROVISION_ORDER_DETAILS} component={ProvisionOrderDetailPage} />

					<Route path={PATHS.BANK_ACCOUNT_LIST} component={BankAccountListPage} />
					<Route path={PATHS.BANK_ACCOUNT_NEW} component={BankAccountNewPage} />
					<Route path={PATHS.BANK_ACCOUNT_EDIT} component={BankAccountEditPage} />
					<Route path={PATHS.BANK_ACCOUNT_DETAILS} component={BankAccountDetailsPage} />

					<Route path={PATHS.AUDITS_LIST} component={AuditsListPage} />
					<Route path={PATHS.AUDITS_USERS} component={AuditsUsersPage} />

					<Route path={PATHS.CAPTION_LIST} component={CaptionListPage} />
					<Route path={PATHS.CAPTION_NEW} component={CaptionNewPage} />
					<Route path={PATHS.CAPTION_EDIT} component={CaptionEditPage} />

					<Route path={PATHS.ALERT_LIST} component={AlertListPage} />
					<Route path={PATHS.ALERT_NEW} component={AlertNewPage} />
					<Route path={PATHS.ALERT_EDIT} component={AlertEditPage} />

					<Route path={PATHS.NOTIFICATION_LIST} component={NotificationListingPage} />
					<Route path={PATHS.NOTIFICATION_NEW} component={NotificationNewPage} />
					<Route path={PATHS.NOTIFICATION_DETAILS} component={NotificationDetailsPage} />
					
					<Route path={PATHS.EXPEDIENT_LIST} component={ExpedientListPage} />
					<Route path={PATHS.EXPEDIENT_EDIT} component={ExpedientEditPage} />

					<Route path={PATHS.MAINTENANCE_SYSTEM_LIST} component={MaintenanceSystemListPage} />

					<Route path={PATHS.CONCEPT_LIST} component={ConceptListPage} />
					<Route path={PATHS.CONCEPT_NEW} component={ConceptNewPage} />
					<Route path={PATHS.CONCEPT_EDIT} component={ConceptEditPage} />

					<Route path={PATHS.INITIAL_URI} component={IndexPage} />

					<Route render={() => (<div>Miss</div>)} />

				</Switch>
			</>
	)	
}