import React, { useEffect, useState } from 'react';
import { Button, Table, Modal } from 'react-bootstrap';
import { SumCollectionAmountsOP, formatterPeso, getOrderPayTypeValueToShow, mFormat, removeDuplicateElements, clearNullValuesFromArr, organismsFilter } from 'src/utils/utils';
import TableReceiptsGenerator from 'src/components/pages/OrderPay/TableReceiptsGenerator';
import { PFLabelConstructor } from 'src/utils/utils';
import { getAdministrativeServiceByIdData, getAdministrativeServiceOrganismListData } from '../../../../redux/administrativeService/administrativeServiceReducer';
import { trySearchAdministrativeServiceById } from '../../../../redux/administrativeService/administrativeServiceActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { isNotEmptyArray } from '../../../../services/validationService';
import { TIPO_RECIBO } from 'src/utils/constants';
import ActionIcon from '../../../general/ActionIcon';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import FigurativeDetailModal from './FigurativeDetailModal';
import LiquidationDetailModal from 'src/components/pages/OrderPay/modal/DetailModal';

const DetailModal = (props) => {
    const dispatch = useDispatch();

    const { showPayOrderDetailModal, setShowPayOrderDetailModal, activePayOrder, selectedFundRequestType } = props;

    const allowCuitToShow = activePayOrder?.type === 'ORDEN_DE_PAGO_DE_PROVEEDORES';
    const conditionToWage = activePayOrder?.type === 'ORDEN_DE_PAGO_DE_HABERES';

    const organismList = useSelector(state => getAdministrativeServiceOrganismListData(state));
    const administrativeService = useSelector(state => getAdministrativeServiceByIdData(state));
    const [showFigurativas, setShowFigurativas] = useState(false);
    const [detail, setDetail] = useState();

    const [showOrderPayLiquidationModal, setShowOrderPayLiquidationModal] = useState(false);

    const onClickLiquidationDetail = item => {
        setShowOrderPayLiquidationModal(true);
    };

    const onClickVerfigurativas = detail => {
        setDetail(detail);
        setShowFigurativas(true);
    };

    useEffect(() => {
        activePayOrder?.administrativeServiceId && dispatch(trySearchAdministrativeServiceById(activePayOrder.administrativeServiceId))
        activePayOrder?.legalInstruments?.push(activePayOrder?.legalInstrument)
    }, [activePayOrder]);

    return <>
        <Modal
            show={showPayOrderDetailModal}
            onHide={() => setShowPayOrderDetailModal(false)}
            dialogClassName="modal-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title className='text-uppercase'>{`Detalle de la ${PFLabelConstructor(selectedFundRequestType)?.label}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped borderless hover className="shadow-sm p-2 mb-1 bg-white rounded text-black-color" >
                    <tbody>
                        <tr>
                            <td className='font-weight-bold text-left'>Tipo:</td>
                            <td className='text-left'>
                                {getOrderPayTypeValueToShow(activePayOrder?.type)}
                            </td>
                        </tr>
                        <tr>
                            <td className='text-left font-weight-bold'>
                                Servicio:
                            </td>
                            <td className='text-left' style={{ overflowWrap: 'anywhere' }}>
                                {`${administrativeService?.code} - ${administrativeService?.name}`}
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold text-left'>Expediente:</td>
                            <td className='text-left'>
                                {activePayOrder?.administrativeDocument?.codeOrganism}-{activePayOrder?.administrativeDocument?.number}/{activePayOrder?.administrativeDocument?.year}
                            </td>
                        </tr>  
                        <tr>
                            <td className='font-weight-bold text-left'>{`${PFLabelConstructor(selectedFundRequestType)?.label}:`}</td>
                            <td className='text-left'>
                                {activePayOrder?.number}    -    {activePayOrder?.year}
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold text-left'>Fecha:</td>
                            <td className='text-left'>
                                {mFormat(activePayOrder?.datePaymentOrder)}
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold text-left'>Descripción:</td>
                            <td className='text-left'>
                                {activePayOrder?.description}
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold text-left'>Importe:</td>
                            <td className='text-left'>
                                {formatterPeso.format(SumCollectionAmountsOP(activePayOrder?.details))}
                            </td>
                        </tr>
                        {conditionToWage &&
                            <tr>
                                <td className='font-weight-bold text-left'>
                                    Liquidación:
                                </td>
                                <td className='text-left'>
                                    Consultar detalle de Liquidación &nbsp;
                                    <ActionIcon className='text-right' toolTipText='Ver detalle' icon={faSearch} onClick={() => onClickLiquidationDetail()} />
                                </td>

                            </tr>
                        }
                    </tbody>
                </Table>
                {isNotEmptyArray(activePayOrder?.beneficiaries) &&
                    <>
                        <p className='text-black-color h6' >
                            {TIPO_RECIBO[activePayOrder?.beneficiaries?.[0]?.receipts?.[0]?.type]}:
                        </p>
                        <TableReceiptsGenerator
                            receipts={activePayOrder?.beneficiaries?.[0]?.receipts}
                            tipo={activePayOrder?.beneficiaries?.[0]?.receipts?.[0]?.type}
                            orderPayDetailData={activePayOrder}
                            _hookFormMethodsModal={{
                                organismList:organismsFilter(organismList)
                            }}
                        />
                    </>
                }
                {isNotEmptyArray(clearNullValuesFromArr(activePayOrder?.legalInstruments)) &&
                    <>
                        <p className='text-black-color h6'>
                            Instrumentos Legales:
                        </p>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr className='text-white-color'>
                                    <th className='text-center' width="15%">Instrumento</th>
                                    <th className='text-center' width="15%">Nro.</th>
                                    <th className='text-center' width="15%">Año</th>
                                </tr>
                            </thead>
                            <tbody className='text-black-color'>
                                {removeDuplicateElements(activePayOrder?.legalInstruments, "number")?.map((item) => (
                                    <tr key={item?.number}>
                                        <td className='text-center'>
                                            {item?.legalInstrumentType?.name}
                                        </td>
                                        <td className='text-center'>
                                            {item?.number}
                                        </td>
                                        <td className='text-center'>
                                            {item?.year}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>
                }
                <hr />
                <p className='text-black-color h6'>{`Detalle de la ${PFLabelConstructor(selectedFundRequestType)?.label}:`}</p>
                <Table striped borderless hover>
                    <thead>
                        <tr>
                            <th className='text-center font-weight-bold'>Monto</th>
                            <th className='text-center font-weight-bold'>Tipo de Partida</th>
                            <th className='text-center font-weight-bold'>Nro. Partida</th>
                            <th className='text-center font-weight-bold'>Descripción</th>
                            {allowCuitToShow &&
                                <th className='text-center font-weight-bold'>CUIT</th>
                            }
                            {activePayOrder?.hasFigurativeExpenditure && <th className='text-center font-weight-bold'>acc</th>}
                        </tr>
                    </thead>
                    <tbody className='text-black-color'>
                        {
                            activePayOrder?.details?.map(item =>
                                <tr key={item.id}>
                                    <td className='text-center py-1'>
                                        {formatterPeso.format(item?.presupuestoResponse?.amount)}
                                    </td>
                                    <td className='text-center py-1'>
                                        {item?.presupuestoResponse?.budgetType}
                                    </td>
                                    <td className='text-center py-1'>
                                        {item?.presupuestoResponse?.subCodeCompleteNumber}
                                    </td>
                                    <td className='text-center py-1'>
                                        {item?.presupuestoResponse?.description}
                                    </td>
                                    {allowCuitToShow &&
                                        <td className='text-center py-1'>
                                            {activePayOrder?.beneficiaries ? activePayOrder.beneficiaries[0]?.cuit : 'S/D'}
                                        </td>
                                    }
                                    {activePayOrder?.hasFigurativeExpenditure &&
                                        <td className='text-center py-1'>
                                            <ActionIcon toolTipText='Ver detalle figurativas' icon={faSearch} onClick={() => onClickVerfigurativas(item)} />
                                        </td>
                                    }
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowPayOrderDetailModal(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>

        <FigurativeDetailModal
            show={showFigurativas}
            setShow={setShowFigurativas}
            detail={detail}
        />

        <LiquidationDetailModal
            showPayOrderDetailModal={showOrderPayLiquidationModal}
            setShowPayOrderDetailModal={setShowOrderPayLiquidationModal}
            selectedOrderPay={activePayOrder}
        />
    </>
}

export default DetailModal