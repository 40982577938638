
import React, { useEffect } from 'react';
import { Table, Spinner, Row, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { preventiveBalance, finalBalance, paymentBalance, saldoOblPago } from 'src/utils/label';
import { formatterPeso } from 'src/utils/utils';
import { tryGetAdministrativeDocumentSubcodeDetails } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { clearAdministrativeDocumentSubcodeRelatedData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import { getAdministrativeDocumentSubcodeData, getAdministrativeDocumentSubcodeIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { tryGetExpedientList } from 'src/redux/expedient/expedientActionCreator';
import { clearExpedientList } from 'src/redux/expedient/expedientActions';
import { getExpedientListData, getExpedientListIsFetching } from 'src/redux/expedient/expedientReducer.js';
import RCPagination from 'src/components/common/RCPagination';
import AppLoading from 'src/components/common/AppLoading';

const ReportExpedientTable = ({organismCode, number, year, period_id}) => {

    const dispatch = useDispatch();

    const metaData = useSelector(state => getAdministrativeDocumentSubcodeData(state));
    const subcodes = useSelector(state => getAdministrativeDocumentSubcodeData(state))?.data;
    const isFetching = useSelector(state => getAdministrativeDocumentSubcodeIsFetching(state));

	const page = metaData?.page;
	const totalItemsCount = metaData?.totalCount ? metaData?.totalCount : '1';
	const totalItems = metaData?.totalCount ? metaData?.totalCount : '0';

    const expedientSelected = useSelector(state => getExpedientListData(state))?.data[0];
    const isFetchingExpedientData = useSelector(state => getExpedientListIsFetching(state));

    const paramsToGetTotalBalance = {
        codeOrganism: organismCode,
        number,
        year,
        period_id
    };

    const defaultParams = {
        page: 1,
        pageSize: 10,
        filter : {
            administrative_document_organism_code: parseInt(organismCode),
            administrative_document_number: parseInt(number),
            administrative_document_year: parseInt(year),
            affectation_status_id: 1,
            period_id
        }
    };

    const fetchList = (params) => {
        dispatch(clearAdministrativeDocumentSubcodeRelatedData());
        dispatch(clearExpedientList());
        dispatch(tryGetAdministrativeDocumentSubcodeDetails(params));
        dispatch(tryGetExpedientList(paramsToGetTotalBalance));
    };

    const onPageChange = (pNumber) => {
        const params = {
            ...defaultParams,
            page: pNumber,
        };
        fetchList(params);
    };

    useEffect(() => {
        fetchList(defaultParams);
    }, [])

    return <>
        <div className='d-flex justify-content-between align-items-center mb-3'>
            <span className='text-black-color h5 m-0'>
                Total de Saldos del Expediente:
            </span>
        </div>
        <Table bordered hover size='sm' className='mb-5'>
            <thead>
                <tr>
                    <th className='text-center align-middle'>Expediente</th>
                    <th className='text-center align-middle'>{preventiveBalance}</th>
                    <th className='text-center align-middle'>{finalBalance}</th>
                    <th className='text-center align-middle'>{saldoOblPago}</th>
                    <th className='text-center align-middle'>{paymentBalance}</th>
                </tr>
            </thead>
            <tbody className='text-black-color'>
                    { !isFetching && !isFetchingExpedientData &&
                        <tr>
                            <td className='text-center align-middle'>{expedientSelected?.completeLabel}</td>
                            <td className='text-right'>{formatterPeso.format(expedientSelected?.total?.preventiveTotal)}</td>
                            <td className='text-right'>{formatterPeso.format(expedientSelected?.total?.definitiveTotal)}</td>
                            <td className='text-right'>{formatterPeso.format(expedientSelected?.total?.paymentObligationTotal)}</td>
                            <td className='text-right'>{formatterPeso.format(expedientSelected?.total?.paymentTotal)}</td>
                        </tr>
                    }
            </tbody>
        </Table>
        <div className='d-flex justify-content-between align-items-center mb-3'>
            <span className='text-black-color h5 m-0'>
                Saldos de Partidas del Expediente:
            </span>
        </div>
        <Table bordered hover size='sm'>
            <thead>
                <tr>
                    <th className='text-center align-middle'>Tipo de Partida</th>
                    <th className='text-center align-middle'>Partida del Expediente</th>
                    <th className='text-center align-middle'>{preventiveBalance}</th>
                    <th className='text-center align-middle'>{finalBalance}</th>
                    <th className='text-center align-middle'>{saldoOblPago}</th>
                    <th className='text-center align-middle'>{paymentBalance}</th>
                </tr>
            </thead>
            <tbody className='text-black-color'>
                { subcodes?.length ?
                    subcodes?.map((item) =>
                        <tr key={item.id}>
                            <td className='text-left align-middle'>{item?.budgetType}</td>
                            <td className='text-left align-middle'>{`${item?.subCodeCompleteNumber}-${item?.subCodeVisibleName}`}</td>
                            <td className='text-right'>{formatterPeso.format(item?.balance?.totals?.preventive)}</td>
                            <td className='text-right'>{formatterPeso.format(item?.balance?.totals?.definitive)}</td>
                            <td className='text-right'>{formatterPeso.format(item?.balance?.totals?.payment_obligation)}</td>
                            <td className='text-right'>{formatterPeso.format(item?.balance?.totals?.payment)}</td>
                        </tr>
                    )   : !isFetching &&
                        <tr>
                            <td colSpan={6}>
                                <Row className='d-flex justify-content-center'>
                                    <Alert variant='info' className='w-100 mx-3 text-center'>
                                        <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                        No hay registros para mostrar
                                    </Alert>
                                </Row>
                            </td>
                        </tr>
                }
            </tbody>
        </Table>
        <RCPagination
			activePage={page}
			itemsCountPerPage={defaultParams.pageSize}
			totalItemsCount={totalItemsCount}
			pageRangeDisplayed={7}
			thePage={page}
			totalItems={totalItems}
			innerClass="justify-content-center"
			onChange={onPageChange}
		/>
        <AppLoading isLoading={isFetching || isFetchingExpedientData} />
    </>
};

export default ReportExpedientTable;