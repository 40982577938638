import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Commons from './controls/Commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Row, Col, Modal, Table, Button, Alert } from 'react-bootstrap';
import { clearListAllAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import { objectHasValue, getMsjAFIP, formatterPeso, onKeyDownEnter } from 'src/utils/utils';
import { tryGetPersonaNameByCuit } from 'src/redux/user/userActionCreator';
import { faCheck, faAsterisk, faTimes } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';
import ExpedienteContainer from './controls/ExpedienteContainer';
import { getProveedoresListData } from 'src/redux/proveedores/proveedoresReducer'
import { clearNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationActions';
import { clearListOrderPayLast } from 'src/redux/orderPay/orderPayActions'
import FormFieldError from 'src/components/general/FormFieldError';
import NumberFormat from 'react-number-format';
import ModalReceiptGenerator from 'src/components/pages/OrderPay/ModalReceiptGenerator';
import { isNotEmptyArray } from 'src/services/validationService';
import AppLoading from 'src/components/common/AppLoading';
import TotalAmountReceiptValidationForm from './TotalAmountReceiptValidationForm';

const ProvidersPaymentOrderForm = () => {
    const dispatch = useDispatch();
    const { 
        fundRequest, orderPay, register, setProveedorSelected, setValue, errors, triggerValidation,
        expedientesAsociados, setExpedientesAsociados, formValuesState, setFormValuesState,
        administrativeServiceData, importe, setAllowSaveAmountValidation, expedientesSeleccionados,
    } = useFormContext();
    const _receipts = orderPay?.beneficiaries[0].receipts || [];

    const [receipts, setReceipts] = useState(_receipts);
    const [ticketData, setTicketData] = useState([]);
    const [amountData, setAmountData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [msjValidacion, setMsjValidacion] = useState();
    const [msjValidacionColor, setMsjValidacionColor] = useState('success');
    const [personaFound, setPersonaFound] = useState(false);
    const [validarPersona, setValidarPersona] = useState(false);
    const [administrativeDocument, setAdministrativeDocument] = useState([]);
    const [admServiceId, setAdmServiceId] = useState();
    const [proveedorInput, setproveedorInput] = useState();

    const [proveedorTerm, setProveedorTerm] = useState("");
    const [showProveedoresModal, setShowProveedoresModal] = useState(false);
    const proveedoresList = useSelector(state => getProveedoresListData(state))?.data || [];
    const [removeState, setRemoveState] = useState(false);

    const totalPenaltyFee = receipts?.length < 2 ? receipts?.[0]?.penaltyFee : receipts?.map((item) => item?.penaltyFee)?.flat()?.reduce((prev, next) => Number(prev) + Number(next));
    const totalAmountReceipts = isNotEmptyArray(receipts) &&
        receipts?.map((item) => item.tickets.map((v) => v.amount))
            .flat()?.reduce((prev, next) => Number(prev) + Number(next)) - totalPenaltyFee;
    const selectedExpedientAssociated = expedientesAsociados?.filter(i => i.selected);

    const amountsShowValidation = (totalReceiptAmount, importe) => {
        if (totalReceiptAmount) {
            const resultCondition = totalReceiptAmount?.toFixed(2) === importe?.toFixed(2)
            setAllowSaveAmountValidation(resultCondition);
            return resultCondition;
        }
        return false;
    };
    const isValidTotalAmountReceipts = amountsShowValidation(totalAmountReceipts, importe);
    const applyFilterBankAccounts = (administrativeServiceId) => {
        setAdmServiceId(administrativeServiceId);
    }

    useEffect(() => {
        const benef = formValuesState?.beneficiaries ? formValuesState?.beneficiaries[0] : {};
        const beneficiaries = [{
            ...benef,
            receipts
        }]

        setFormValuesState({ ...formValuesState, beneficiaries });
    }, [receipts]);



    //
    // TODO: Este useEffect se renderiza 8 veces, reveerlo...
    //
    useEffect(() => {
        if (orderPay) {
            setAdmServiceId(orderPay.administrativeServiceId);
        }
        if (fundRequest) {
            setAdmServiceId(fundRequest.administrativeServiceId);
        }
        dispatch(clearListOrderPayLast());
        dispatch(clearListAllAdministrativeServicesData());
        dispatch(clearNewAffectationAdministrativeDocumentData());
        if (orderPay) {
            const p = { cuit: orderPay.beneficiaries[0].persona.cuit, name: orderPay.beneficiaries[0].persona.companyName, beneficiaryValidator: orderPay.beneficiaries[0].persona.beneficiaryValidator };
            setAcreedorObject(p);
            setMsjValidacion(getMsjAFIP(p?.beneficiaryValidator, p?.providerNumber));
            p ? setValidarPersona(true) : setValidarPersona(false);
            p?.beneficiaryValidator ? setMsjValidacionColor('success') : setMsjValidacionColor('warning');
            setAdmServiceId(orderPay.administrativeServiceId);
        } else {
            setAcreedorObject();
        }

        //Inicializo beneficiaries
        setFormValuesState({
            ...formValuesState,
            beneficiaries: [{
                type: 'PROVEEDOR',
                cuit: '',
                persona: {
                    companyName: ''
                },
                receipts: _receipts
            }]
        });
    }, []);

    const clearBeneficiario = () => {
        setValue("cuit", "");
        setValue("acreedor_denominacion", "")
        setRemoveState(true);
        setValidarPersona(false);
    }

    const openModalProveedores = () => {
        setShowProveedoresModal(true);
    };
    const onChangeFilterProveedor = event => {
        setProveedorTerm(event.target.value);
    };
    const handleCloseModalProveedor = () => setShowProveedoresModal(false);

    const setAcreedorObject = (proveedor) => {
        setProveedorSelected(proveedor);
        setValue("cuit", proveedor?.cuit);
        setValue('acreedor_denominacion', proveedor?.name);
        setValue('beneficiaryValidator', proveedor?.beneficiaryValidator);
        handleCloseModalProveedor();
        setPersonaFound(!!proveedor?.name);
        if (proveedor) {
            setRemoveState(true);
        }
    }

    const handleInputProveedor = (event) => {
        setValue('acreedor_denominacion', event.target.value);
        setproveedorInput(event.target.value);
        const beneficiaries = [{
            ...formValuesState.beneficiaries[0],
            persona: {
                companyName: event.target.value
            },
        }];
        setFormValuesState({ ...formValuesState, beneficiaries });
    }

    const handleInputChange = (event) => {
        // 20049905026
        let cuit = event.target.value.replace(/-/g, "")
        let newCuit = cuit.replace(/X/g, "");
        if (newCuit.length == 11 && !isLoading) {
            setIsLoading(true);

            dispatch(tryGetPersonaNameByCuit(newCuit)).then((response) => {
                setIsLoading(false);
                const proveedor = {
                    cuit: newCuit,
                    name: response?.data?.companyName,
                    beneficiaryValidator: response?.data?.beneficiaryValidator,
                    providerNumber: response?.data?.providerNumber
                };
                const beneficiaries = [{
                    ...formValuesState.beneficiaries[0],
                    cuit: newCuit,
                    persona: {
                        companyName: proveedor?.name
                    },
                }];
                setFormValuesState({ ...formValuesState, beneficiaries });
                proveedor?.beneficiaryValidator ? setMsjValidacionColor('success') : setMsjValidacionColor('warning');
                proveedor?.name && delete errors?.acreedor_denominacion;
                proveedor ? setValidarPersona(true) : setValidarPersona(false);
                setMsjValidacion(getMsjAFIP(proveedor?.beneficiaryValidator, proveedor?.providerNumber));
                setAcreedorObject(proveedor);
            })
        }
    }

    // Validations
    const cuitRequiredMsg = 'Debe ingresar un CUIT válido';
    const denominationRequiredMsg = 'Debe ingresar una denominación';

    // Form data
    const cuitValidationObj = { required: cuitRequiredMsg };
    const denominationValidationObj = { required: denominationRequiredMsg };

    return <>
        <Commons
            administrativeServiceData={administrativeServiceData}
            admServiceId={admServiceId}
            setAdmServiceId={setAdmServiceId}
            applyFilterBankAccounts={applyFilterBankAccounts}
            disabledImporte={true}
        />

        <Form.Group as={Row}>
            <Form.Label htmlFor='asunto' className='text-right text-black-color pr-0 d-flex mandatory-label col-sm-4'>
                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                Acreedor:
            </Form.Label>
            <Col sm='2' className='paddingform pr-1'>
                <NumberFormat
                    className="form-control"
                    autocomplete="off"
                    type='text'
                    name='cuit'
                    placeholder='CUIT'
                    defaultValue={orderPay?.beneficiaries?.[0]?.cuit}
                    disabled={validarPersona}
                    onChange={handleInputChange}
                    format="##-########-#"
                    mask="X"
                    pattern="\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]"
                    getInputRef={register(cuitValidationObj)}
                    onKeyDown={event => onKeyDownEnter(event)}
                />
                <FormFieldError errors={errors?.cuit} />
            </Col>
            <Col sm='2' className='pl-1' >
                <Form.Control
                    autocomplete="off"
                    type='text'
                    disabled={personaFound}
                    name='acreedor_denominacion'
                    placeholder='Denominación'
                    onChange={handleInputProveedor}
                    ref={register(denominationValidationObj)}
                />
                <FormFieldError errors={errors?.acreedor_denominacion} />
            </Col>
            <Col sm={2} className='ml-0 pl-0 mt-2' >
                <ActionIcon size='lg' toolTipText='Quitar' icon={faTimes} 
                    className={'text-danger ' + (removeState ? '' : 'd-none')} 
                    onClick={clearBeneficiario} 
                    text='Quitar'
                />
            </Col>
            <Col sm={4} className='offset-md-4' >
                {validarPersona &&
                    <Alert variant={msjValidacionColor} className='form-field-error mb-0 py-1 mt-2'>
                        {msjValidacion}
                    </Alert>
                }
            </Col>
        </Form.Group>

            <Modal
                dialogClassName="modal-lg"
                show={showProveedoresModal}
                onHide={handleCloseModalProveedor}>
                <Modal.Header closeButton>
                    <h5>Proveedor</h5>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" placeholder="Buscar..." onChange={onChangeFilterProveedor} />
                    <br />
                    <Table striped bordered hover size='sm' responsive>
                        <thead>
                            <tr>
                                <th className='text-center align-middle'>Proveedor</th>
                                <th className='text-center align-middle'>Nombre de Fantasia</th>
                                <th className='text-center align-middle'>CUIT</th>
                                <th className='text-center align-middle'>Acciones</th>
                            </tr>
                        </thead>
                        <tbody className='text-black-color'>
                            {proveedoresList?.filter(item => objectHasValue(item, proveedorTerm))
                                .map((item, index) => (
                                    <tr key={item?.id}>
                                        <td className='text-center align-middle'>
                                            {item.companyName}
                                        </td>
                                        <td className='text-center align-middle'>
                                            {item.fantasyName}
                                        </td>
                                        <td className='text-center align-middle'>
                                            {item.cuit}
                                        </td>
                                        <td className='text-center align-middle'>
                                            <ActionIcon size='lg' id={'select-rubro-' + index} toolTipText='Seleccionar' icon={faCheck} onClick={() => setAcreedorObject(item)} />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalProveedor}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        <ModalReceiptGenerator
            errors={errors}
            register={register}
            triggerValidation={triggerValidation}
            receipts={receipts}
            setReceipts={setReceipts}
            ticketObj={{ ticketData, setTicketData }}
            breakDownWorkCertificateObj={{ amountData, setAmountData }}
        />

        {admServiceId &&
            <ExpedienteContainer
                expedientesAsociados={expedientesAsociados}
                setExpedientesAsociados={setExpedientesAsociados}
                administrativeDocument={administrativeDocument}
                setAdministrativeDocument={setAdministrativeDocument}
                receipts={receipts}
            />
        }

        <TotalAmountReceiptValidationForm 
            setAllowSaveAmountValidation={setAllowSaveAmountValidation}
            expedientesSeleccionados={expedientesSeleccionados} 
            selectedExpedientAssociated={selectedExpedientAssociated} 
            totalAmountReceipts={totalAmountReceipts} 
            importe={importe} 
            receipts={receipts}
        />

        <AppLoading isLoading={isLoading} />
    </>
};

export default ProvidersPaymentOrderForm;