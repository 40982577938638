import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { numberAbbreviation, date } from 'src/utils/label';
import { getYearsOptionsByParam, parseIntOrUndefined, onKeyDownEnter } from 'src/utils/utils';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import FormFieldError from 'src/components/general/FormFieldError';

const InputOrganismForm = ({ formData, handleOrganismCode, handleOrganismNumber, handleOrganismYear }) => {
    const { register, errors, admServiceId } = useFormContext();
    const [validCode, setValidCode] = useState(false);
    const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const minimumYear = 2018;
    const yearsOptions = getYearsOptionsByParam(minimumYear, globalSelectedPeriod?.year);
    yearsOptions.push(2024);
    const OrganismTypeRequiredMsg = 'Debe ingresar el Tipo de expediente.';
    const OrganismNumberRequiredMsg = 'Debe ingresar el Número de expediente.';
    const OrganismYearRequiredMsg = 'Debe ingresar el año de expediente.';
    const organismCodeValidationMsg = 'El código de organismo del expediente debe estar entre los rangos 1000 y 9999.'
    const OrganismTypeValidationObj = { required: OrganismTypeRequiredMsg };
    const OrganismNumberValidationObj = { required: OrganismNumberRequiredMsg };
    const OrganismYearValidationObj = { required: OrganismYearRequiredMsg };
    const organismCodeValidationObj = (value) => {
        let validation = (value?.length > 4 || value?.length < 4) || (parseIntOrUndefined(value) < 1000 && parseIntOrUndefined(value) != 0);
        setValidCode(validation);
    };
    const handleCode = (data) => {
        handleOrganismCode(data); 
        organismCodeValidationObj(data)
    }
    const handleNumber = (data) => {
        handleOrganismNumber(data)
    }

    useEffect(() => {
        setValidCode(false);
	}, [admServiceId]);

    return <>
        <Form.Group as={Row}>
            <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                Expediente:
            </Form.Label>
            <Col sm='4' className=''>
                <div className="input-group">
                    <Form.Control
                        name='organismCode'
                        value={formData?.organismCode}
                        type='number'
                        placeholder='Código'
                        onChange={event => handleCode(event?.target?.value)}
                        ref={register(OrganismTypeValidationObj)}
                        onKeyDown={event => onKeyDownEnter(event)}
                    />
                    <span className='px-2 pt-2'>-</span>
                    <Form.Control
                        name='organismNumber'
                        value={formData?.organismNumber}
                        className="form-control"
                        type='number'
                        placeholder={numberAbbreviation}
                        onChange={event => handleNumber(event?.target?.value)}
                        ref={register(OrganismNumberValidationObj)}
                        onKeyDown={event => onKeyDownEnter(event)}
                    />
                    <span className='px-2 pt-2'> / </span>
                    <Form.Control
                        as='select'
                        className='select-min-width'
                        value={formData?.organismYear}
                        onChange={event => handleOrganismYear(event?.target?.value)}
                    >
                        <option value={undefined} selected>Año</option>
                        {yearsOptions?.map(item => (
                            <option value={item}>
                                {item}
                            </option>))
                        }
                    </Form.Control>
                </div>
                {
                    validCode &&
                    <div class="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
                        <strong>¡Importante! </strong>{organismCodeValidationMsg}
                    </div>
                }
                <FormFieldError errors={errors?.organismCode} />
                <FormFieldError errors={errors?.organismNumber} />
                <FormFieldError errors={errors?.organismYear} />
                <Form.Control hidden ref={register(OrganismYearValidationObj)} name='organismYear' value={formData.organismYear} />
            </Col>
        </Form.Group >
    </>
}

export default InputOrganismForm;