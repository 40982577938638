import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller, FormContext, useFormContext } from 'react-hook-form';
import { Form, Col, Row, Button, Modal, Table, Alert } from 'react-bootstrap';

import ActionIcon from 'src/components/general/ActionIcon';

import { selectOption } from 'src/utils/label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinusCircle, faAsterisk, faExclamationTriangle, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';

import NumberFormat from 'react-number-format';
import FormFieldError from 'src/components/general/FormFieldError';
import { isNotEmptyArray } from '../../../../services/validationService';
import DropdownList from 'react-widgets/lib/DropdownList';
import { formatterPeso, numberNegativeRed, parseFloatOrUndefined } from 'src/utils/utils';

import { tryGetWagesTemplateDetails } from 'src/redux/wageTemplate/wageTemplateActionCreator';
import { getWageTemplateListIsFetching } from 'src/redux/wageTemplate/wageTemplateReducer';

import { tryListConceptsSelect } from "src/redux/conceptRequest/conceptRequestActionCreator";
import { getConceptListSelectData, getConceptListSelectIsFetching } from "src/redux/conceptRequest/conceptRequestReducer";

import { tryListSubconcepts } from '../../../../redux/subconcept/subconceptActionCreator';

import { tryGetRecruitmentMethod } from 'src/redux/wageTemplate/wageTemplateActionCreator';
import { getRecruitmentMethodsData, getRecruitmentMethodsIsFetching } from 'src/redux/wageTemplate/wageTemplateReducer';

const ModalLiquidacionHaberes = (props) => {

    const dispatch = useDispatch();

    const { show, handleClose, serviceId } = props;

    const hookFormMethods = useForm();
    const { handleSubmit, register, control, setValue, errors } = hookFormMethods;
    const { settlementOfAssets, setSettlementOfAssets, orderPay } = useFormContext();

    const [methodId, setMethodId] = useState(settlementOfAssets?.wageLiquidationType || '');
    const [salaryTemplate, setSalaryTemplate] = useState('');
    const [assetConcept, setAssetConcept] = useState('');
    const [subConcept, setSubConcept] = useState('');
    const [item, setItem] = useState('');
    const [code, setCode] = useState('');
    const [liquidAmount, setLiquidAmount] = useState(settlementOfAssets?.netAmount || '');
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalGeneral, setTotalGeneral] = useState(0);
    const [validateConcept, setValidateConcept] = useState(true);

    const defaultParams = {
        page: 0,
        size: 99
    };

    // Fetch Wage Template
    const [wageTemplateList, setWageTemplateList] = useState([]);
    const wageTemplateIsFetching = useSelector(state => getWageTemplateListIsFetching(state));
    const hasRecordsWageTemplateList = isNotEmptyArray(wageTemplateList);

    // Concepts
    const conceptListData = useSelector((state) => getConceptListSelectData(state)?.records);
    const conceptListIsFetching = useSelector((state) => getConceptListSelectIsFetching(state));

    // Sub-concepts
    const [subConceptArray, setSubConceptArray] = useState([]);

    // Liquidation list
    const copy = JSON.parse(JSON.stringify(settlementOfAssets?.wageManagerDetails || []));
    const [liquidationList, setLiquidationList] = useState(copy);
    const hasLiquidationList = isNotEmptyArray(liquidationList);

    // Wahe template types
	const recruitmentMethods = useSelector(state => getRecruitmentMethodsData(state));
    const wageTemplateTypesListIsFetching = useSelector(state => getRecruitmentMethodsIsFetching(state));

    //validations messages
    const methodValidationMessage = 'Debe seleccionar un Método de Contratación.';
    const salaryTemplateValidationMessage = 'Debe seleccionar una Plantilla.';
    const liquidAmountValidationMessage = 'Debe cargar el Importe Líquido.';
    const negativeGeneralTotalMessage = "El Total General no puede ser negativo.";
    const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };

    //validations objects
    const methodValidationObj = { required: methodValidationMessage };
    const salaryTemplateValidationObj = { required: salaryTemplateValidationMessage };
    const liquidAmountValidationObj = { required: liquidAmountValidationMessage, validate: value => (value <= 0) ? 'El importe debe ser mayor a cero.' : undefined };

    const negativeGeneralTotal = totalGeneral < 0;

    // Validations
    const addFigurativeValidation = () => {
        if (assetConcept && subConcept && item && code) {
            return true;
        } else return false;
    };

    const withValueLimit = (inputObj) => {
        const { value } = inputObj;
        const maxValue = 9999;
        if (value <= maxValue)
            return inputObj;
    };

    const withValueLimitImports = (inputObj) => {
        const { value } = inputObj;
        const maxValue = 999999999999.99;
        const minValue = -999999999999.99;
        if (value <= maxValue && value >= minValue || value == '-') return inputObj;
    };

    //concept iteration validation
    const conceptIterationValidation = (arrayWage, objWage) => {
        return arrayWage?.every(obj =>
            objWage?.item !== obj?.item || objWage?.concept !== obj?.concept || objWage?.subConcept !== obj?.subConcept
        );
    };

    const disabledFields = !methodId || wageTemplateIsFetching || conceptListIsFetching || wageTemplateTypesListIsFetching;

    // handle change
    const handleChangeMethod = (value) => {

        const paramsToSend = {
            ...defaultParams,
            administrativeServiceId: serviceId,
            type: value
        };

        setMethodId(value);
        setSalaryTemplate('');
        setValue('wageTemplate', '');

        dispatch(tryGetWagesTemplateDetails(paramsToSend)).then(response => {
            const records = response?.records || [];
            setWageTemplateList(records);
        });

        setLiquidationList([]);
    };

    const handleChangeSalarayTemplate = (value) => {

        let formattedData = [];
        setSalaryTemplate(value);

        const wageTemplateItemCodes = wageTemplateList?.find(item => item?.name == value)?.wageTemplateItemCodes;

        if (isNotEmptyArray(wageTemplateItemCodes)) {
            formattedData = wageTemplateItemCodes?.map((item, index) => {
                item = {
                    ...item,
                    concept: item?.concept?.description,
                    subConcept: item?.subConcept?.description,
                    changeAmount: 0,
                    periodAmount: 0
                }
                return item;
            });
        };

        setLiquidationList(formattedData);
    };

    const handleChangeConcept = (value) => {
        const conceptSelected = conceptListData.find(item => item?.id == value);
        const subConceptList = conceptSelected?.subConcept || [];
        setSubConceptArray(subConceptList);
        setAssetConcept(value);
        setSubConcept('');
    };

    const handleChangeSubConcept = (value) => {
        setSubConcept(value);
    };

    const handleChangeItem = (value) => {
        setItem(value);
    };

    const handleChangeCode = (value) => {
        setCode(value);
    };

    const handleChangeLiquidAmount = (imputObj) => {
        setLiquidAmount(imputObj?.floatValue);
    };

    //end handle change

    //calculate total
    const calculateTotal = (list) => {
        setTotalAmount(list?.map((item) => parseFloatOrUndefined(item?.subtotalAmount) || 0)
            .reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            }, 0)
        );
    };

    const calculateTotalGeneral = (list) => {

        let total = list?.map((item) => parseFloatOrUndefined(item?.subtotalAmount) || 0)
            .reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            }, 0);

        const result = ((total || 0) + (parseFloatOrUndefined(liquidAmount) || 0));

        setTotalGeneral(result);
    };

    //Submit
    const onSubmitForm = (data) => {

        const net_amount = data?.liquidAmount;
        const net_amount_format = typeof (net_amount) == "string" ? parseFloat(net_amount?.replaceAll('.', '').replace(',', '.')) : net_amount;

        const dataObj = {
            id: settlementOfAssets?.id,
            wageLiquidationType: data?.methodId,
            name: data?.wageTemplate,
            generalAmount: totalGeneral,
            netAmount: net_amount_format,
            total: totalAmount,
            wageManagerDetails: liquidationList
        };

        setAssetConcept('');
        setSubConcept('');
        setItem('');
        setCode('');

        setSettlementOfAssets({ ...dataObj });
        handleClose();
    };

    const stopPropagate = (callback) => {
        return (e) => {
            e.stopPropagation();
            callback(e);
        };
    };

    const handleCloseModal = () => {
        setAssetConcept('');
        setSubConcept('');
        setItem('');
        setCode('');
        setMethodId(settlementOfAssets?.wageLiquidationType);
        setSalaryTemplate(settlementOfAssets?.name);

        const copy = JSON.parse(JSON.stringify(settlementOfAssets?.wageManagerDetails || []));
        setLiquidationList(copy);
        setLiquidAmount(settlementOfAssets?.netAmount);

        const paramsToSend = {
            ...defaultParams,
            administrativeServiceId: serviceId,
            type: settlementOfAssets?.wageLiquidationType
        };

        dispatch(tryGetWagesTemplateDetails(paramsToSend)).then(response => {
            const records = response?.records || [];
            setWageTemplateList(records);
        });

        handleClose();
    };

    const addConcept = () => {

        const conceptObj = conceptListData?.filter((item) => item?.id == assetConcept)[0];
        const subConceptObj = subConceptArray?.filter((item) => item?.id == subConcept)[0];

        const id = Math.random().toString(30).substring(2);

        const params = {
            id: id,
            concept: conceptObj?.description,
            subConcept: subConceptObj?.description,
            item: item,
            code: code,
            periodAmount: undefined,
            changeAmount: undefined,
            subtotalAmount: 0,
            isNew: true
        };

        if (conceptIterationValidation(liquidationList, params)) {

            const copy = JSON.parse(JSON.stringify(liquidationList));
            const newConceptsList = (copy || []).concat({ ...params });

            setLiquidationList(newConceptsList);

            setAssetConcept('');
            setSubConcept('');
            setItem('');
            setCode('');
            setValue('item', '');
            setValue('code', '');
            setValue('concept', '');
            setValue('subconcept', '');
            setSubConceptArray([]);

            setValidateConcept(true);
        } else {
            setValidateConcept(false);
        };
    };

    const onClickRemoveConcepts = id => {
        const newConceptsList = (liquidationList || []).filter((item, index) => item?.id != id);
        setLiquidationList(newConceptsList);
    };

    //onChange

    const onChangeImportAmount = (imputObj, id) => {
        const copy = JSON.parse(JSON.stringify(liquidationList));
        let newConceptsList = copy.map((item) => {
            if (id == item?.id) {
                item.periodAmount = imputObj?.floatValue;
                item.subtotalAmount = ((item?.periodAmount || 0) - (item?.changeAmount || 0))?.toFixed(2);
            }
            return item;
        });
        setLiquidationList(newConceptsList);
    };

    const onChangeModificationAmount = (imputObj, id) => {
        const copy = JSON.parse(JSON.stringify(liquidationList));
        let newConceptsList = copy.map((item) => {
            if (id == item?.id) {
                item.changeAmount = imputObj?.floatValue;
                item.subtotalAmount = ((item?.periodAmount || 0) - (item?.changeAmount || 0))?.toFixed(2);
            }
            return item;
        });
        setLiquidationList(newConceptsList);
    };

    useEffect(() => {
        calculateTotal(liquidationList);
    }, [liquidationList]);

    useEffect(() => {
        calculateTotalGeneral(liquidationList);
    }, [liquidationList, liquidAmount]);

    useEffect(() => {
        dispatch(tryListConceptsSelect());
        dispatch(tryGetRecruitmentMethod());
        dispatch(tryListSubconcepts(defaultParams));

        // Edit order pay
        if (settlementOfAssets?.wageManagerDetails?.length > 0) {

            setMethodId(settlementOfAssets?.wageLiquidationType);
            setSalaryTemplate(settlementOfAssets?.name);

            const paramsToSend = {
                ...defaultParams,
                administrativeServiceId: serviceId,
                type: settlementOfAssets?.wageLiquidationType
            };

            dispatch(tryGetWagesTemplateDetails(paramsToSend)).then(response => {
                const records = response?.records || [];
                setWageTemplateList(records);
                setLiquidationList(settlementOfAssets?.wageManagerDetails);
                setLiquidAmount(settlementOfAssets?.netAmount)
            });
        };
    }, []);

    useEffect(() => {
        setSettlementOfAssets(orderPay?.wageManager || {});
        setLiquidationList([]);
        setLiquidAmount(settlementOfAssets?.netAmount || '');
        setSalaryTemplate(settlementOfAssets?.name || '');
    }, [serviceId]);

    return <>
        <Modal
            show={show}
            onHide={handleCloseModal}
            scrollable={true}
            dialogClassName="modal-xl"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Detalle de Liquidación de Haberes</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FormContext {...hookFormMethods}>
                    <Form onSubmit={stopPropagate(handleSubmit(onSubmitForm))} autocomplete='off'>

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={3}>
                                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                Método de contratación:
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control
                                    as="select"
                                    name="methodId"
                                    defaultValue={settlementOfAssets?.wageLiquidationType}
                                    className={"text-black-color"}
                                    ref={register(methodValidationObj)}
                                    onChange={(event) => handleChangeMethod(event.target.value)}
                                    disabled={orderPay}
                                >
                                    <option value={''} selected>{selectOption}</option>
                                    {
                                        recruitmentMethods?.map((item, idx) => (
                                            <option value={item} key={idx}>
                                                {item.replace(/_/g, ' ')}
                                            </option>
                                        ))
                                    }
                                </Form.Control>
                                <FormFieldError errors={errors?.methodId} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={3}>
                                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                Plantilla de Haberes:
                            </Form.Label>
                            <Col sm={4}>
                                <DropdownList
                                    placeholder='Seleccione una plantilla...'
                                    data={wageTemplateList}
                                    allowCreate='onFilter'
                                    name="wageTemplate"
                                    textField='name'
                                    valueField='name'
                                    filter='contains'
                                    value={salaryTemplate}
                                    defaultValue={settlementOfAssets?.name}
                                    className='w-100 text-black-color'
                                    onChange={value => handleChangeSalarayTemplate(value?.name)}
                                    id='wageTemplate'
                                    messages={messagesDropDown}
                                    selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
                                    searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}
                                    disabled={disabledFields || (orderPay != undefined)}
                                >
                                </DropdownList>
                                <FormFieldError errors={errors?.salaryTemplate} />
                                <Form.Control hidden ref={register(salaryTemplateValidationObj)} name='wageTemplate' value={salaryTemplate} />
                            </Col>
                        </Form.Group>

                        <hr color="black" className='' />

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={3}>
                                Agregar concepto de Haberes:
                            </Form.Label>
                            <Col sm={4}>
                                <DropdownList
                                    placeholder='Seleccione un concepto...'
                                    data={conceptListData}
                                    allowCreate='onFilter'
                                    name='concept'
                                    textField='description'
                                    valueField='id'
                                    filter='contains'
                                    className='w-100 text-black-color'
                                    id='concept'
                                    value={assetConcept}
                                    messages={messagesDropDown}
                                    onChange={(value) => handleChangeConcept(value?.id)}
                                    selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
                                    searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}
                                    disabled={!salaryTemplate || disabledFields}
                                >
                                </DropdownList>
                            </Col>
                            <Col sm={4}>
                                <DropdownList
                                    placeholder='Seleccione un subconcepto...'
                                    data={subConceptArray}
                                    allowCreate='onFilter'
                                    name='subconcept'
                                    textField='description'
                                    valueField='id'
                                    filter='contains'
                                    className='w-100 text-black-color'
                                    id='subconcept'
                                    value={subConcept}
                                    messages={messagesDropDown}
                                    onChange={(value) => handleChangeSubConcept(value?.id)}
                                    selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
                                    searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}
                                    disabled={!salaryTemplate || disabledFields}
                                >
                                </DropdownList>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={3}>
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control
                                    type="text"
                                    name="item"
                                    ref={register}
                                    placeholder="Item"
                                    onChange={(event) => handleChangeItem(event?.target?.value)}
                                    disabled={!salaryTemplate || disabledFields}
                                    maxLength={255}
                                />
                            </Col>
                            <Col sm={4}>
                                <Controller
                                    name='code'
                                    control={control}
                                    value={0}
                                    as={
                                        <NumberFormat
                                            decimalScale={0}
                                            inputMode='numeric'
                                            className="form-control form-control-sm"
                                            placeholder={'Código'}
                                            allowNegative={false}
                                            allowLeadingZeros={false}
                                            onValueChange={(event) => handleChangeCode(event?.value)}
                                            isAllowed={(inputObj) => withValueLimit(inputObj)}
                                            disabled={!salaryTemplate || disabledFields}
                                        />
                                    }
                                />
                            </Col>

                            <ActionIcon
                                size='lg'
                                toolTipText="Agregar Concepto"
                                icon={faPlus}
                                disabled={!addFigurativeValidation()}
                                onClick={addConcept}
                            />
                        </Form.Group>

                        {!validateConcept &&
                            <Alert variant='warning' className='mx-3 mb-3'>
                                <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                El item, concepto y subconcepto ya se utilizaron.
                            </Alert>
                        }

                        {
                            hasLiquidationList &&
                            <>
                                <Table striped bordered hover size='sm'>
                                    <thead>
                                        <tr>
                                            <th className='text-center' style={{ "width": "30%" }}>Concepto</th>
                                            <th className='text-center' style={{ "width": "10%" }}>Código</th>
                                            <th className='text-center' style={{ "width": "15%" }}>Importe Período</th>
                                            <th className='text-center' style={{ "width": "20%" }}>Modificaciones</th>
                                            <th className='text-center' style={{ "width": "20%" }}>Subtotal</th>
                                            <th className='text-center' style={{ "width": "5%" }}>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-black-color'>
                                        {liquidationList?.map((item, index) =>
                                        (
                                            <tr key={item?.id}>
                                                <td className='text-center'>
                                                    {item?.concept + "-" + item?.subConcept + "-" + item?.item}
                                                </td>
                                                <td className='text-center'>
                                                    {item?.code}
                                                </td>
                                                <td className='text-center'>
                                                    <div className='input-group mb-2'>
                                                        <div className='input-group-prepend'>
                                                            <div className='input-group-text'>
                                                                $
                                                            </div>
                                                        </div>
                                                        <Controller
                                                            name={`import-${item?.id}`}
                                                            id={`import-${item?.id}`}
                                                            control={control}
                                                            as={
                                                                <NumberFormat
                                                                    className='form-control text-right'
                                                                    decimalScale={2}
                                                                    thousandSeparator={'.'}
                                                                    decimalSeparator={','}
                                                                    inputMode='numeric'
                                                                    value={item?.periodAmount}
                                                                    defaultValue={item?.periodAmount} 
                                                                    allowLeadingZeros={false}
                                                                    isAllowed={(inputObj) => withValueLimitImports(inputObj)}
                                                                    onValueChange={(inputObject) => onChangeImportAmount(inputObject, item?.id)}
                                                                    required
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                                <td className='text-center'>
                                                    <div className='input-group mb-2'>
                                                        <div className='input-group-prepend'>
                                                            <div className='input-group-text'>
                                                                $
                                                            </div>
                                                        </div>
                                                        <Controller
                                                            name={`modification-${item?.id}`}
                                                            id={`modification-${item?.id}`}
                                                            control={control}
                                                            as={
                                                                <NumberFormat
                                                                    className='form-control text-right'
                                                                    decimalScale={2}
                                                                    thousandSeparator={'.'}
                                                                    decimalSeparator={','}
                                                                    inputMode='numeric'
                                                                    value={item?.changeAmount}
                                                                    defaultValue={item?.changeAmount}
                                                                    allowLeadingZeros={false}
                                                                    allowNegative={true}
                                                                    isAllowed={(inputObj) => withValueLimitImports(inputObj)}
                                                                    onValueChange={(inputObject) => onChangeModificationAmount(inputObject, item?.id)}
                                                                    required
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                                <td className={`${numberNegativeRed(item?.subtotalAmount)} text-right`}>
                                                    {formatterPeso.format(item?.subtotalAmount || 0)}
                                                </td>
                                                <td className='text-center'>
                                                    <ActionIcon
                                                        size='sm'
                                                        icon={faMinusCircle}
                                                        id='adduser'
                                                        toolTipText='Remover'
                                                        className='text-danger mr-2'
                                                        onClick={() => onClickRemoveConcepts(item?.id)}
                                                    />
                                                </td>
                                            </tr>

                                        )
                                        )
                                        }
                                    </tbody>
                                </Table>
                            </>

                        }
                        {hasLiquidationList &&
                            <div className='d-flex align-items-center mb-3'>
                                <p className='h6 text-black-color'>

                                </p>
                                <span className='ml-auto mr-0'>
                                    <span className='text-black-color mr-3'>Total: </span>
                                    <NumberFormat
                                        prefix={'$'}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        decimalScale={2}
                                        value={totalAmount}
                                        defaultValue={0}
                                        className={'text-black-color text-right'}
                                        readOnly
                                    />
                                </span>
                            </div>
                        }
                        <hr color="black" className='' />

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={3}>
                                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                <b>Importe Líquido:</b>
                            </Form.Label>
                            <Col sm={4} className='ml-0'>
                                <div className='input-group mb-2'>
                                    <div className='input-group-prepend'>
                                        <div className='input-group-text'>
                                            $
                                        </div>
                                    </div>
                                    <Controller
                                        name='liquidAmount'
                                        control={control}
                                        defaultValue={settlementOfAssets?.netAmount}
                                        as={
                                            <NumberFormat
                                                className='form-control text-right'
                                                decimalScale={2}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                inputMode='numeric'
                                                allowLeadingZeros={false}
                                                allowNegative={false}
                                                isAllowed={(inputObj) => withValueLimitImports(inputObj)}
                                                onValueChange={(inputObject) => handleChangeLiquidAmount(inputObject)}
                                                getInputRef={register(liquidAmountValidationObj)}
                                            />
                                        }
                                    />
                                </div>
                                <FormFieldError errors={errors?.liquidAmount} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col sm={3} className='d-flex mandatory-label'>
                                <span className='text-black-color'><b>Total General:</b></span>
                            </Col>
                            <Col sm={4} className='ml-0'>
                                <NumberFormat
                                    prefix={'$'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowLeadingZeros={false}
                                    decimalScale={2}
                                    value={totalGeneral}
                                    className={'form-control text-right'}
                                    readOnly
                                />
                                {
                                    negativeGeneralTotal &&
                                    <Row className='mt-2'>
                                        <Col>
                                            <Alert variant='danger' className='mb-0'>
                                                {negativeGeneralTotalMessage}
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Form.Group>
                        <div className='d-flex justify-content-around mt-4 mb-3'>
                            <Button type='button' variant='primary' size='lg' onClick={handleCloseModal}>
                                Cerrar
                            </Button>
                            <Button
                                type='submit'
                                variant='success'
                                size='lg'
                                hidden={false}
                                disabled={negativeGeneralTotal}
                            >
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </FormContext>

            </Modal.Body>
        </Modal>

    </>
};

export default ModalLiquidacionHaberes;