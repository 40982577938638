import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Container, Card, Form, Button, Spinner, Col, Row } from 'react-bootstrap';
import { useForm, FormContext } from 'react-hook-form';
import { PROVISION_ORDER_LIST } from 'src/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { newProvisionOrder, save, cancel } from 'src/utils/label';
import ProvisionOrderForm from 'src/components/forms/provisionOrder/ProvisionOrderForm';
import GoodsAndServicesModal from 'src/components/pages/ProvisionOrder/modal/GoodsAndServicesModal';
import { isNotEmptyArray } from 'src/services/validationService';
import NumberFormat from 'react-number-format';
import { parseFloatOrUndefined } from 'src/utils/utils';
import { tryPostProvisionOrder } from 'src/redux/provisionOrder/provisionOrderActionCreator';

const ProvisionOrderNewPage = () => {
  const dispatch = useDispatch();
  const hookFormMethods = useForm();
  const { handleSubmit } = hookFormMethods;
  const [goodsAndServicesList, setGoodsAndServicesList] = useState([]);
  const [totalGeneral, setTotalGeneral] = useState(0);

  // Labels
  const buttonLabel = isNotEmptyArray(goodsAndServicesList) ? "Editar Bienes y/o Servicios" : "Añadir Bienes y/o Servicios";

  //Modal settlement of assets
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const onClickSetElementAssetsModal = () => {
    handleShow(true);
  };

  //calculate total
  const calculateTotal = (list) => {
    setTotalGeneral(list?.map((item) => parseFloatOrUndefined(item?.totalAmount) || 0)
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0)
    );
  };

  // Submit
  const onSubmitForm = data => {
    const dataToSend = {
      number: data?.orderNumber,
      year: data?.fiscalYearId,
      administrativeServiceId: data?.administrativeServiceId,
      provisionOrderDate: data?.orderDate,
      initiator: data?.initiator?.trimEnd(),
      destination: data?.destination?.trimEnd(),
      deliveryLocation: data?.deliveryPlace?.trimEnd(),
      legalInstrument: {
        legalInstrumentType: {
          name: data?.legalInstrumentTypeId
        },
        number: data?.legalInstrumentNumber,
        year: data?.legalInstrumentYear
      },
      administrativeDocument: {
        year: data?.organismYear,
        number: data?.organismNumber,
        codeOrganism: data?.organismCode,
        title: "Orden de pago"
      },
      acquisitionType: data?.acquisitionType,
      adquistionNumber: 0,
      adquisitionYear: 0,
      adquisitionDate: "2023-05-31",
      deadlineAmount:  data?.deadlineAmount,
      deadlineTimeType:  data?.deadlineTimeType,
      observations: "string",
      beneficiary: {
        type: "PROVEEDOR",
        cuit: data?.cuit.replace(/-/g, ""),
        persona: {
          companyName: data?.name,
        }
      },
      provisionOrderRows: goodsAndServicesList,
    }
    dispatch(tryPostProvisionOrder(dataToSend)).then(response => {
      if (response.status == 201) {
        dispatch(push(PROVISION_ORDER_LIST));
      }
    });
  };

  useEffect(() => {
    calculateTotal(goodsAndServicesList);
  }, [goodsAndServicesList]);

  return <>
    <Container fluid>
      <Card className='mb-3'>
        <Card.Header className='h6'>
          {newProvisionOrder}
        </Card.Header>
        <Card.Body >
          <span className='text-danger d-flex mandatory-label font-weight-bold font-italic'>
            <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
            Obligatorio
          </span>
          <FormContext {...hookFormMethods}>
            <Form className='mt-4 text-black-color' onSubmit={handleSubmit(onSubmitForm)}>
              <ProvisionOrderForm />
              <GoodsAndServicesModal
                show={show}
                handleClose={handleClose}
                goodsAndServicesList={goodsAndServicesList}
                setGoodsAndServicesList={setGoodsAndServicesList}
              />
              <Form.Group as={Row} className='mt-5 mb-5'>
                <Col sm={4}></Col>
                <Col sm={4} className='paddingform d-flex justify-content-center'>
                  <Button
                    variant='success'
                    size='md'
                    className='py-1 px-4'
                    onClick={() => onClickSetElementAssetsModal()}
                  >
                    {buttonLabel}
                  </Button>
                </Col>
              </Form.Group>
              {isNotEmptyArray(goodsAndServicesList) &&
                <>
                  <Form.Group as={Row}>
                    <Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                      Total de la Orden de Provision:
                    </Form.Label>
                    <Col sm='4'>
                      <NumberFormat
                        prefix={'$'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        value={totalGeneral}
                        className={'form-control text-left'}
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </>
              }
              <div class='d-flex justify-content-around mt-4 mb-3'>
                <Button
                  variant='danger'
                  size='lg'
                  onClick={() => dispatch(push(PROVISION_ORDER_LIST))}
                >
                  Cancelar
                </Button>
                <span className={(false ? '' : 'hidden')}>
                  <Spinner className='spinner-border text-danger' animation='border' />
                </span>
                <Button
                  type='submit'
                  variant='success'
                  size='lg'
                  className='text-white-color'
                >
                  Guardar
                  <FontAwesomeIcon icon={faSave} className='ml-2 text-white-color ' />
                </Button>
              </div>
            </Form>
          </FormContext>
        </Card.Body>
      </Card>
    </Container>
  </>
}

export default ProvisionOrderNewPage;