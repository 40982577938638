import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPost, buildURLQuery, httpDelete } from './httpServices';

const API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;
const SUBCONCEPTS_ENDPOINT = '/wage-concept-items';
const CONCEPT_ENDPOINT = '/wage-concepts/concept-subconcept'
const CONCEPT_SELECT_ENDPOINT = '/wage-concepts/findall-concept'
const DELETE_CONCEPT_ENDPOINT = '/wage-concepts'
const CREATE_CONCEPT_ENDPOINT = '/wage-concepts/create-concepts-subconcepts'
const CREATE_CONCEPT_SUBCONCEPT_ENDPOINT = '/wage-concepts/create-subconcepts'

export const getConcepts = async ( accessToken, params) => {

	const setbuildURLQuery = {
		page: params?.page,
		pageSize: params?.size || 10,
		sort: "id,desc",
		conceptDescription: params?.conceptDescription,
		subConceptDescription: params?.subConceptDescription,
	};

	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${API_BASE_URI}${CONCEPT_ENDPOINT}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};

export const getConceptsList = async ( accessToken, params) => {

	const setbuildURLQuery = {
		conceptoDescription: params?.conceptoDescription
	};

	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${API_BASE_URI}${CONCEPT_SELECT_ENDPOINT}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};

export const getSubconcepts = async ( accessToken, params) => {

	const setbuildURLQuery = {
		page: params?.page || 0,
		pageSize: params?.size || 10
	};

	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${API_BASE_URI}${SUBCONCEPTS_ENDPOINT}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};

export const createConcept = async (accessToken, data) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${API_BASE_URI}${CREATE_CONCEPT_ENDPOINT}`;
	let response;

	try {
		response = await httpPost(URL, data, options);
	}
	catch (error) {
		return error;
	}

	return response;
};
export const createConceptSubConcept = async (accessToken, data) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${API_BASE_URI}${CREATE_CONCEPT_SUBCONCEPT_ENDPOINT}`;
	let response;

	try {
		response = await httpPost(URL, data, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

export const deleteConcept = async (accessToken, id) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${API_BASE_URI}${DELETE_CONCEPT_ENDPOINT}/${id}`;
	let response;

	try {
		response = await httpDelete(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
}