import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPost, buildURLQuery } from './httpServices';

const API_BASE_URI = `${config.apis.coreApi.URL}`;
const NOTIFICATION_ENDPOINT_URL = '/notifications';
const FILE_TO_NOTIFICATION_ENDPOINT_URI = '/files';

//Notification list
export const listNotification = async ( accessToken, params  ) => {
	
	const options = buildDefaultOptions(accessToken);
	const jsonFilter = params?.filter ? JSON.stringify(params?.filter) : undefined;

	const notificationURLQuery = {
        page: params?.page,
		size: params?.pageSize,
		subject: params?.subject,
		body: params?.body,
		createdDate: params?.createdAt,
		profileId: params?.profileID || ',',
		attachments: params?.attachments
	}
	
	const queryString = buildURLQuery(notificationURLQuery);
	const URL = `${API_BASE_URI}${NOTIFICATION_ENDPOINT_URL}?${queryString}`;
	
	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

// Create Notification
export const createNotification = async (accessToken, data) => {
	
	const options = buildDefaultOptions(accessToken);
	const URL = `${API_BASE_URI}${NOTIFICATION_ENDPOINT_URL}`;

	options.headers['Content-Type'] = 'multipart/form-data';

	const formData = new FormData();

	formData.append("profileId", data?.profile);
	formData.append("subject", data?.subject);
	formData.append("body", data?.message);
	formData.append("signature", data?.signature);
	if(data?.administrativeService?.length > 0){
		formData.append("serviceIds", data?.administrativeService);
	}
	formData.append("files", data?.files[0]);

	let response;

	try {
		response = await httpPost(URL, formData, options);
	}
	catch (error) {
		return error;
	}
	
	return response;
};

// GET file of notification
export const getFileOfNotification = async (accessToken, notificationId) => {

	const URL = `${API_BASE_URI}${NOTIFICATION_ENDPOINT_URL}/${notificationId}${FILE_TO_NOTIFICATION_ENDPOINT_URI}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error.response;
	}
	
	return response;
};