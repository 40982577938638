import React, { useState, useEffect } from "react";
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileExcel, faAsterisk } from "@fortawesome/free-solid-svg-icons";
import MultiSelect from "react-multi-select-component";
import "react-widgets/dist/css/react-widgets.css";
import { isNotEmptyArray } from "src/services/validationService";
import { parseIntOrUndefined, dateNeutralFormatedToShowARG } from "src/utils/utils";
import PageTitle from "src/components/general/PageTitle";
import * as LABELS from 'src/utils/label';
import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod, getReportDatesData } from "src/redux/globalData/globalDataReducer";
import { getOrganizationTypesListData, getOrganizationTypesListIsFetching, } from "src/redux/organization/organizationReducer";
import { getCharacterListData, getCharacterListIsFetching, } from "src/redux/character/characterReducer";
import { tryGetCharacterList } from "src/redux/character/characterActionCreator";
import { clearCharacterList } from "src/redux/character/characterActions";
import { tryGetReportTableTotalsCreditExecution } from "src/redux/reports/reportsActionCreactor";
import { tryGetListOrganizationType } from "src/redux/organization/organizationActionCreator";
import { getReportTableTotalsCreditExecutionIsFetching } from "src/redux/reports/reportsReducer";
import { clearReportTableTotalsCreditExecution } from "src/redux/reports/reportsActions";
import MaxDate from 'src/components/common/MaxDate';

const ReportTableTotalsCreditExecutionPage = () => {

	const [isDateTo, setIsDateTo] = useState(false);

	const dispatch = useDispatch();
	const hookFormMethods = useForm();
	// Report PreSet
	const onChangeReportPreSet = (item) => {
		switch (item) {
			//Cuadro 200
			case 1:
				{
					setSelectedReportsPreSet(item);
					// Organism Type
					let paramsOrgType = [
						{ value: 1, label: "(OD) - Organismos Descentralizados" },
						{ value: 4, label: "(AC) - Administración Central" },
					];
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = [
						{ value: 1, label: "0 - Administración Central" },
						{ value: 4, label: "1 - Organismos Descentralizados" },
						{ value: 3, label: "2 - Cuentas Especiales" },
					];
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = [
						{ value: 6, label: "6 - Total Mensual Definitivo" },
					];
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 1; //Finalidad y Función
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 7; //Económica (Sección)
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "Cuadro 200";
					setReportName(reportName);
				}
				break;

			//Cuadro 201
			case 2:
				{
					setSelectedReportsPreSet(item);
					// Organism Type
					let paramsOrgType = [
						{ value: 4, label: "(AC) - Administración Central" },
					];
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = [
						{ value: 1, label: "0 - Administración Central" },
						{ value: 3, label: "2 - Cuentas Especiales" },
					];
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = [{ value: 1, label: "1 - Crédito Original" }];
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 2; //Económica (Todos)
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 3; //Carácteres
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "Cuadro 201";
					setReportName(reportName);
				}
				break;

			//Cuadro 202
			case 3:
				{
					setSelectedReportsPreSet(item);
					// Organism Type
					let paramsOrgType = [
						{ value: 4, label: "(AC) - Administración Central" },
					];
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = [
						{ value: 1, label: "0 - Administración Central" },
					];
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = [{ value: 1, label: "1 - Crédito Original" }];
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 2; //Económica (Todos)
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 5; //Jurisdicciones
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "Cuadro 202";
					setReportName(reportName);
				}
				break;

			//Cuadro 203
			case 4:
				{
					setSelectedReportsPreSet(item);
					// Organism Type
					let paramsOrgType = [
						{ value: 4, label: "(AC) - Administración Central" },
					];
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = [
						{ value: 3, label: "2 - Cuentas Especiales" },
					];
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = [{ value: 1, label: "1 - Crédito Original" }];
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 2; //Económica (Todos)
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 5; //Jurisdicciones
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "Cuadro 203";
					setReportName(reportName);
				}
				break;

			//Cuadro 204
			case 5:
				{
					setSelectedReportsPreSet(item);
					// Organism Type
					let paramsOrgType = [
						{ value: 1, label: "(OD) - Organismos Descentralizados" },
					];
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = [
						{ value: 4, label: "1 - Organismos Descentralizados" },
						{ value: 3, label: "2 - Cuentas Especiales" },
					];
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = [{ value: 1, label: "1 - Crédito Original" }];
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 2; //Económica (Todos)
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 3; //Carácteres
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "Cuadro 204";
					setReportName(reportName);
				}
				break;

			//Cuadro 205
			case 6:
				{
					setSelectedReportsPreSet(item);
					// Organism Type
					let paramsOrgType = [
						{ value: 1, label: "(OD) - Organismos Descentralizados" },
					];
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = [
						{ value: 4, label: "1 - Organismos Descentralizados" },
					];
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = [{ value: 1, label: "1 - Crédito Original" }];
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 2; //Económica (Todos)
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 6; //Organismos
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "Cuadro 205";
					setReportName(reportName);
				}
				break;

			//Cuadro 206
			case 7:
				{
					setSelectedReportsPreSet(item);
					// Organism Type
					let paramsOrgType = [
						{ value: 1, label: "(OD) - Organismos Descentralizados" },
					];
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = [
						{ value: 3, label: "2 - Cuentas Especiales" },
					];
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = [{ value: 1, label: "1 - Crédito Original" }];
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 2; //Económica (Todos)
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 6; //Organismos
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "Cuadro 206";
					setReportName(reportName);
				}
				break;

			//Cuadro 207
			case 8:
				{
					setSelectedReportsPreSet(item);
					// Organism Type
					let paramsOrgType = [
						{ value: 2, label: "(PO) - Presupuestos Operativos" },
					];
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = [
						{ value: 4, label: "1 - Organismos Descentralizados" },
						{ value: 3, label: "2 - Cuentas Especiales" },
					];
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = [{ value: 1, label: "1 - Crédito Original" }];
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 2; //Económica (Todos)
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 3; //Carácteres
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "Cuadro 207";
					setReportName(reportName);
				}
				break;

			//Cuadro 208
			case 9:
				{
					setSelectedReportsPreSet(item);
					// Organism Type
					let paramsOrgType = [
						{ value: 2, label: "(PO) - Presupuestos Operativos" },
					];
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = [
						{ value: 4, label: "1 - Organismos Descentralizados" },
					];
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = [{ value: 1, label: "1 - Crédito Original" }];
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 2; //Económica (Todos)
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 6; //Organismos
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "Cuadro 208";
					setReportName(reportName);
				}
				break;

			//Cuadro 210
			case 10:
				{
					setSelectedReportsPreSet(item);
					// Organism Type
					let paramsOrgType = [
						{ value: 1, label: "(OD) - Organismos Descentralizados" },
					];
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = [
						{ value: 4, label: "1 - Organismos Descentralizados" },
						{ value: 3, label: "2 - Cuentas Especiales" },
					];
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = [
						{ value: 6, label: "6 - Total Mensual Definitivo" },
					];
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 1; //Finalidad y Función
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 3; //Carácteres
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "Cuadro 210";
					setReportName(reportName);
				}
				break;

			//Cuadro Personalizado
			case 11:
				{
					setSelectedReportsPreSet(item);
					// Organism Type
					let paramsOrgType = []; //Empty
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = []; //Empty
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = []; //Empty
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 1; //Finalidad y Función
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 1; //Finalidad y Función
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "Personalizado";
					setReportName(reportName);
				}
				break;

			default:
				{
					setSelectedReportsPreSet(11);
					// Organism Type
					let paramsOrgType = []; //Empty
					setSelectedOrgTypes(paramsOrgType);

					// Character Type
					let paramsCharacterType = []; //Empty
					setSelectedCharacter(paramsCharacterType);

					// Amount Type
					let paramsAmountType = []; //Empty
					setSelectedAmountTypes(paramsAmountType);

					// EjeX Type
					let paramsEjeXType = 1; //Finalidad y Función
					setSelectedEjeX(paramsEjeXType);

					// EjeY Type
					let paramsEjeYType = 1; //Finalidad y Función
					setSelectedEjeY(paramsEjeYType);

					//Report Name
					let reportName = "";
					setReportName(reportName);
				}
				break;
		}
	};

	// Idiom Multiselected Spanish
	const idiom = {
		selectSomeItems: "Seleccionar una o más opciones...",
		allItemsAreSelected: "Todos los elementos están seleccionados.",
		selectAll: "Seleccionar todo",
		search: "Buscar",
		clearSearch: "Limpiar búsqueda.",
	};

	const reportDates = useSelector( state => getReportDatesData(state) );
	// Periods
	const periodList = useSelector((state) =>
		getGlobalDataPeriodsData(state)
	)?.data?.sort((a, b) => (a.year < b.year ? 1 : -1));

	const hasPeriodList = isNotEmptyArray(periodList);

	const globalSelectedPeriod = useSelector((state) =>
		getGlobalDataSelectedPeriod(state)
	);

	// Period by Exercise
	const exercisePeriods = globalSelectedPeriod?.periods;
	const hasExercisePeriods = isNotEmptyArray(exercisePeriods);

	// Exercise by Id
	const exerciseById = periodList?.filter(
		(exercise) => exercise.id == globalSelectedPeriod?.id
	);

	let yearExerciseSelected = hasPeriodList ? exerciseById[0]?.year : undefined;

	const [dateTo, setDateTo] = useState(reportDates?.dateTo);
	const [dateHasErrors, setDateHasErrors] = useState(false);
	//Validate Data Start and End

	// Start date
	let startDateDefault = yearExerciseSelected + "-01-01";
	const [startDate, setStartDate] = useState(undefined);

	const onBlurYearExerciseSelected = () => {
		setStartDate(startDateDefault);
	}
	
	// End date
	const [endDate, setEndDate] = useState(undefined);
	
	// Ratio Button columns
	const [selectedCheckPeriodDate, setSelectedCheckPeriodDate] = useState(null);

	const onClickSelectedCheckPeriodDate = item => {

		setSelectedCheckPeriodDate(item);
		if (item == 1){
			setStartDate(startDateDefault);
			setEndDate(undefined);
			setIsDateTo(true);
		}else{
			setStartDate(undefined);
			setEndDate(undefined);
			setIsDateTo(false);
		}

	}

	const onChangeSelectedPeriod = (selectedPeriod) => {
		// Period by Id
		const periodById = globalSelectedPeriod?.periods?.filter(
			(period) => period.id == selectedPeriod
		);

		let periodStartDate = periodById[0]?.startDate;
		let periodEndDate = periodById[0]?.endDate;

		setStartDate(periodStartDate);
		setEndDate(periodEndDate);
	};

	// Characters
	const characterOptionsData = useSelector((state) =>
		getCharacterListData(state)
	)?.data?.sort((a, b) => (a.code > b.code ? 1 : -1));
	const characterIsFetching = useSelector((state) =>
		getCharacterListIsFetching(state)
	);
	//// Multiselect
	const [selectedCharacters, setSelectedCharacter] = useState([]);
	let optionsCharacterTypes = [];
	let ic = 0;
	for (ic in characterOptionsData) {
		optionsCharacterTypes.push({
			label:
				characterOptionsData[ic]?.code + " - " + characterOptionsData[ic]?.name,
			value: characterOptionsData[ic]?.id,
		});
	}

	// Load data
	const customValueRendererCharacterTypes = (
		selectedCharacters,
		_optionsCharacterTypes
	) => {
		return selectedCharacters?.length
			? selectedCharacters?.map(({ label }) => " ✔️" + label)
			: "No hay ítems seleccionados...";
	};
	const hasValueCustomValueRendererCharacterTypes =
		selectedCharacters?.length === 0;
	//// END Multiselect

	// Organization types
	const organizationTypesData = useSelector((state) =>
		getOrganizationTypesListData(state)
	);
	const organizationTypesIsFetching = useSelector((state) =>
		getOrganizationTypesListIsFetching(state)
	);
	//// Multiselect
	const [selectedOrgTypes, setSelectedOrgTypes] = useState([]);
	let optionsOrgTypes = [];
	let i = 0;
	for (i in organizationTypesData) {
		optionsOrgTypes.push({
			label:
				"(" +
				organizationTypesData[i]?.shortName +
				") - " +
				organizationTypesData[i]?.name,
			value: organizationTypesData[i]?.id,
		});
	}

	// Checkboxes columns
	const [checkedEnableFilter, setCheckedEnableFilter] = useState(false);

	// Load data
	const customValueRendererOrgTypes = (selectedOrgTypes) => {
		return selectedOrgTypes?.length
			? selectedOrgTypes?.map(({ label }) => " ✔️" + label)
			: "No hay ítems seleccionados...";
	};
	const hasValueCustomValueRendererOrgTypes = selectedOrgTypes?.length === 0;
	//// END Multiselect



	const optionsAmountTypesData = [
		{ name: "1 - Crédito Original", id: 1 },
		{ name: "2 - Modificaciones", id: 2 },
		{ name: "1 2 - Crédito Original + Modificaciones", id: 12 },
		{ name: "3 - Saldo Crédito", id: 3 },
		{ name: "4 - Total Mensual Preventivo", id: 4 },
		{ name: "5 - Acumulado - Preventivo", id: 5 },
		{ name: "6 - Total Mensual Definitivo", id: 6 },
		{ name: "7 - Acumulado - Definitivo", id: 7 },
		{ name: "8 - Total Mensual Obligaciones de Pago", id: 8 },
		{ name: "9 - Acumulado - Obligaciones de Pago", id: 9 },
		{ name: "10 - Total Mensual Pago", id: 10 },
		{ name: "11 - Acumulado Pago", id: 11 },
	];

	// amountTypes
	//// Multiselect
	const [selectedAmountTypes, setSelectedAmountTypes] = useState([]);
	let optionsAmountTypes = [];
	let im = 0;
	for (im in optionsAmountTypesData) {
		optionsAmountTypes.push({
			label: optionsAmountTypesData[im]?.name,
			value: optionsAmountTypesData[im]?.id,
		});
	}

	// Load data
	const customValueRendererAmountTypes = (
		selectedAmountTypes,
		_optionsAmountTypes
	) => {
		return selectedAmountTypes?.length
			? selectedAmountTypes?.map(({ label }) => " ✔️" + label)
			: "No hay ítems seleccionados...";
	};
	const hasValueCustomValueRendererAmountTypes =
		selectedAmountTypes?.length === 0;
	//// END Multiselect

	// Selected
	const [selectedEjeX, setSelectedEjeX] = useState(1);
	const [selectedEjeY, setSelectedEjeY] = useState(1);
	const [selectedReportsPreSet, setSelectedReportsPreSet] = useState(null);
	const [reportName, setReportName] = useState("");

	const enableCheckedFilter =
		selectedReportsPreSet == 11 || selectedReportsPreSet == null;

	const allowField =
		selectedReportsPreSet == 11 ||
		selectedReportsPreSet == null ||
		checkedEnableFilter;

	// Reports redux
	const reportIsFetching = useSelector((state) =>
		getReportTableTotalsCreditExecutionIsFetching(state)
	);
	const allowGetReport =
		hasPeriodList &&
		!reportIsFetching &&
		!hasValueCustomValueRendererOrgTypes &&
		!hasValueCustomValueRendererCharacterTypes &&
		!hasValueCustomValueRendererAmountTypes &&
		!dateHasErrors&&
		selectedCheckPeriodDate == 1 && dateTo || 
		selectedCheckPeriodDate == 2 && endDate;

	useEffect(() => {
		dispatch(clearReportTableTotalsCreditExecution());
		dispatch(clearCharacterList());
		dispatch(tryGetCharacterList());
		dispatch(tryGetListOrganizationType());
	}, [globalSelectedPeriod]);

	const getReport = (outputFormat) => {
		if (globalSelectedPeriod ) {
			const params = {
				outputFormat,
				exerciseId: globalSelectedPeriod?.id,
				x: selectedEjeX,
				y: selectedEjeY,
				organizationTypeIds: selectedOrgTypes.map((orgType) => orgType.value),
				amountTypes: selectedAmountTypes.map((AmountType) => AmountType.value),
				characterIds: selectedCharacters.map((orgChar) => orgChar.value),
				reportName,
				startDate,
				endDate: isDateTo ? dateTo : endDate,
			};

			dispatch(tryGetReportTableTotalsCreditExecution(params));
		}
	};

	const onClickPDF = () => getReport(1);

	const onClickXLS = () => getReport(2);

	const isRequired = true;

	return (
		<Container fluid>
			<Card className="mb-5">
				<PageTitle text="Reportes totales" />

				<Container fluid>
					<Card className="mb-3">
						<Card.Body>
							<Form>
								<Row>
									<Col xs={12} className='text-right font-weight-bold font-italic'>
										<span className='text-danger d-flex mandatory-label'>
											<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
										Obligatorio
										</span>
									</Col>
								</Row>
								<Row>
									<Col sm={2}></Col>
									<Col sm={8}>
										<Form.Group>
											<Form.Label className="text-black-color">
												<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
												Ejercicio
											</Form.Label>
										

										<Form.Control 
											type='number' 
											name='globalSelectedPeriod' 
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
											
										/>

										</Form.Group>

										<Row className="ml-2 mb-3 text-black-color">
											
											<Col>
												
											</Col>
										</Row>
										{!selectedCheckPeriodDate ? (
											<small className="form-text text-muted font-italic mb-3">
												<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
												Debe seleccionar una opción: Fecha hasta/Período.
											</small>
										) : null}
										<Form.Check
											className="text-black-color"
											name="checkPeriodDate"
											type="radio"
											value="1"
											label="Fecha hasta"
											id="1"
											onClick={(event) =>
												onClickSelectedCheckPeriodDate(
													parseIntOrUndefined(event.target.value)
												)
											}
										/>
										{selectedCheckPeriodDate == 1 ? (
											<FormContext {...hookFormMethods}>
												<MaxDate {...{isRequired, dateTo, setDateTo, setDateHasErrors}} />
											</FormContext>
										) : (
											<Form.Group>
												<Form.Label className='text-black-color'>
													{LABELS.upToDay}
												</Form.Label>
												<Form.Control
													disabled={true}
												/>
											</Form.Group>
										)
											
										}
										<Form.Check
											className="text-black-color"
											name="checkPeriodDate"
											type="radio"
											value="2"
											label="Por período"
											id="2"
											onClick={(event) =>
												onClickSelectedCheckPeriodDate(
													parseIntOrUndefined(event.target.value)
												)
											}
										/>
										{selectedCheckPeriodDate == 2 ? (
											<>
												<Form.Group>
													<Form.Label className="text-black-color">
													<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
														Períodos
													</Form.Label>
													<Form.Control
														as="select"
														className="text-black-color"
														disabled={!hasExercisePeriods}
														/* value={selectedPeriod} */
														onChange={(event) =>
															onChangeSelectedPeriod(
																parseIntOrUndefined(event.target.value)
															)
														}
													>
													<option	option className='text-black-color' value={''} disabled={true} selected >
													Seleccione una opción...
													</option>
														{hasExercisePeriods
															? exercisePeriods.map((period) => (
																<option value={period.id} key={period?.id}>
																	Inicio:{" "}
																	{dateNeutralFormatedToShowARG(
																		period?.startDate
																	)}{" "} - Fin:{" "}
																	{dateNeutralFormatedToShowARG(
																		period?.endDate
																	)}
																</option>
															))
															: null}
													</Form.Control>
												</Form.Group>
											</>
										) : (
											<Form.Group>
												<Form.Label className='text-black-color'>
													Períodos
												</Form.Label>
												<Form.Control
													disabled={true}
												/>
											</Form.Group>
										)
										}

										<Form.Group>
											<Form.Label className="text-black-color">
											<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
												Tipos de reportes
											</Form.Label>
											<Form.Control
												as="select"
												className="text-black-color"
												onChange={(event) =>
													onChangeReportPreSet(
														parseIntOrUndefined(event.target.value)
													)
												}
												value={selectedReportsPreSet}
											>
												<option	option className='text-black-color' value={''} disabled={true} selected >
													Seleccione una opción...
												</option>
												<option value={11}>Personalizado</option>
												<option value={1}>Cuadro 200</option>
												<option value={2}>Cuadro 201</option>
												<option value={3}>Cuadro 202</option>
												<option value={4}>Cuadro 203</option>
												<option value={5}>Cuadro 204</option>
												<option value={6}>Cuadro 205</option>
												<option value={7}>Cuadro 206</option>
												<option value={8}>Cuadro 207</option>
												<option value={9}>Cuadro 208</option>
												<option value={10}>Cuadro 210</option>
											</Form.Control>
										</Form.Group>

										{!enableCheckedFilter ? (
											<Form.Group>
												<Form.Check
													className="text-black-color"
													id="enable-filter"
													label="Habilitar personalización de filtros"
													checked={checkedEnableFilter}
													onChange={(event) =>
														setCheckedEnableFilter(event.target.checked)
													}
												/>
											</Form.Group>
										) : null}

										<Form.Group>
											<Form.Label className="text-black-color">
											<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
												Tipos de unidad
										</Form.Label>
											<MultiSelect
												className="text-black-color"
												options={optionsOrgTypes}
												value={selectedOrgTypes}
												onChange={setSelectedOrgTypes}
												labelledBy={"Select"}
												overrideStrings={idiom}
												valueRenderer={customValueRendererOrgTypes}
												hasSelectAll={true}
												isLoading={organizationTypesIsFetching}
												ClearSelectedIcon={"🧹Limpiar"}
												disabled={!allowField}
											/>
											{hasValueCustomValueRendererOrgTypes ? (
												<small className="form-text text-muted font-italic">
													Debe seleccionar al menos una opción.
												</small>
											) : null}
										</Form.Group>

										<Form.Group>
											<Form.Label className="text-black-color">
											<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
												Carácteres
											</Form.Label>
											<MultiSelect
												className="text-black-color"
												options={optionsCharacterTypes}
												value={selectedCharacters}
												onChange={setSelectedCharacter}
												labelledBy={"Select"}
												overrideStrings={idiom}
												valueRenderer={customValueRendererCharacterTypes}
												hasSelectAll={true}
												isLoading={characterIsFetching}
												ClearSelectedIcon={"🧹Limpiar"}
												disabled={!allowField}
											/>
											{hasValueCustomValueRendererCharacterTypes ? (
												<small className="form-text text-muted font-italic">
													<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
													Debe seleccionar al menos una opción.
												</small>
											) : null}
										</Form.Group>

										<Form.Group>
											<Form.Label className="text-black-color">
											<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
												Tipos de importe
											</Form.Label>

											<MultiSelect
												className="text-black-color"
												options={optionsAmountTypes}
												value={selectedAmountTypes}
												onChange={setSelectedAmountTypes}
												labelledBy={"Select"}
												overrideStrings={idiom}
												valueRenderer={customValueRendererAmountTypes}
												hasSelectAll={true}
												ClearSelectedIcon={"🧹Limpiar"}
												disabled={!allowField}
											/>
											{hasValueCustomValueRendererAmountTypes ? (
												<small className="form-text text-muted font-italic">
													<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
													Debe seleccionar al menos una opción.
												</small>
											) : null}
										</Form.Group>

										<Form.Group>
											<Form.Label className="text-black-color">
											<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
												Eje X (Filas)
											</Form.Label>
											<Form.Control
												as="select"
												className="text-black-color"
												value={selectedEjeX}
												onChange={(event) =>
													setSelectedEjeX(
														parseIntOrUndefined(event.target.value)
													)
												}
												disabled={!allowField}
											>
												<option value={1}>Finalidad y Función</option>
												<option value={2}>Económica (Todos)</option>
												<option value={7}>Económica (Sección)</option>
												<option value={3}>Carácteres</option>
												<option value={4}>Tipo Organismo</option>
												<option value={5}>Jurisdicciones</option>
												<option value={6}>Organismos</option>
											</Form.Control>
										</Form.Group>

										<Form.Group>
											<Form.Label className="text-black-color">
												<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
												Eje Y (Columnas)
											</Form.Label>
											<Form.Control
												as="select"
												className="text-black-color"
												value={selectedEjeY}
												onChange={(event) =>
													setSelectedEjeY(
														parseIntOrUndefined(event.target.value)
													)
												}
												disabled={!allowField}
											>
												<option value={1}>Finalidad y Función</option>
												<option value={2}>Económica (Todos)</option>
												<option value={7}>Económica (Sección)</option>
												<option value={3}>Carácteres</option>
												<option value={4}>Tipo Organismo</option>
												<option value={5}>Jurisdicciones</option>
												<option value={6}>Organismos</option>
											</Form.Control>
										</Form.Group>

										<Form.Group className="d-flex justify-content-between mt-4">
											<Button
												size="lg"
												onClick={onClickPDF}
												disabled={
													!allowGetReport || selectedReportsPreSet == null
												}
											>
												<FontAwesomeIcon icon={faFilePdf} className="mr-2" />
												Ver PDF
											</Button>
											{reportIsFetching ? <Spinner animation="border" /> : null}
											<div></div>
											{/* TODO: se descomentara esta linea para hacer efectiva la funcionalidad en la release v1.1.0
											<Button
												size="lg"
												onClick={onClickXLS}
												disabled={
													!allowGetReport || selectedReportsPreSet == null
												}
											>
												<FontAwesomeIcon icon={faFileExcel} className="mr-2" />
												Ver XLS
											</Button> */}
										</Form.Group>
									</Col>
									<Col sm={3}></Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Container>
			</Card>
		</Container>
	);
};

export default ReportTableTotalsCreditExecutionPage;
