import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import { faAsterisk, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useFormContext } from 'react-hook-form';
import ActionIcon from 'src/components/general/ActionIcon';
import FormFieldError from 'src/components/general/FormFieldError';
import NumberFormat from 'react-number-format';
import { tryGetPersonaNameByCuit } from 'src/redux/user/userActionCreator';
import { useDispatch } from 'react-redux';
import { getMsjAFIP } from 'src/utils/utils';

const InputCuitForm = ({ inputName, provider }) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const { orderPay, register, setValue, errors } = useFormContext()
    const [messajeValidacion, setMessajeValidacion] = useState()
    const [messajeValidacionColor, setMessajeValidacionColor] = useState('success')
    const [validarPersona, setValidarPersona] = useState(false)
    const [removeState, setRemoveState] = useState(false)
    const [providerState, setProviderState] = useState({
        type: 'PROVEEDOR',
        cuit: '',
        persona: {
            companyName: ''
        }
    })

    // Validations
    const cuitRequiredMsg = 'Debe ingresar un CUIT válido'
    const denominationRequiredMsg = 'Debe ingresar una denominación'
    
    // Form data
    const cuitValidationObj = { required: cuitRequiredMsg }
    const denominationValidationObj = { required: denominationRequiredMsg }


    const setAcreedorObject = (proveedor) => { 
        // setProveedorSelected(proveedor);
        // setValue("cuit", proveedor?.cuit);
        // setValue('acreedor_denominacion', proveedor?.name);
        // setValue('beneficiaryValidator', proveedor?.beneficiaryValidator);
        // handleCloseModalProveedor();
        // setPersonaFound(!!proveedor?.name);
        // if (proveedor) {
        //     setRemoveState(true);
        // }
    }

    const handleInputProveedor = () => {
        setProviderState({    
            type: 'PROVEEDOR',
            cuit: '',
            persona: {
                companyName: ''
            }
        })
    }

    const inputProvider = (provider) => {
        setProviderState({    
            type: provider?.type || 'PROVEEDOR',
            cuit: provider?.persona?.cuit || '',
            persona: {
                cuit: provider?.persona?.cuit || '',
                companyName: provider?.persona?.companyName || ''
            }
        })
    }

    const clearBeneficiario = () => {
        setProviderState({   
            type: providerState?.type,
            cuit: providerState?.cuit,
            persona: {
                companyName: ''
            }
        })
        setRemoveState(true);
        setValidarPersona(false);
    } 

    const handleInputChange = (event) => {
        let cuit = event.target.value.replace(/-/g, "")
        let newCuit = cuit.replace(/X/g, "");
        if (newCuit.length == 11 && !isLoading) {
            setIsLoading(true);
            dispatch(tryGetPersonaNameByCuit(newCuit)).then((response)=>{
                setProviderState({
                    type: providerState?.type,
                    cuit: response?.data?.cuit,
                    persona: {
                        companyName: response?.data?.companyName
                    }
                })
                setIsLoading(false);
                const proveedor = {
                    cuit: newCuit,
                    name: response?.data?.companyName,
                    beneficiaryValidator: response?.data?.beneficiaryValidator,
                    providerNumber: response?.data?.providerNumber
                };
                proveedor?.beneficiaryValidator ? setMessajeValidacionColor('success') : setMessajeValidacionColor('warning');
                proveedor?.name && delete errors?.acreedor_denominacion;
                proveedor ? setValidarPersona(true) : setValidarPersona(false);
                setMessajeValidacion(getMsjAFIP(proveedor?.beneficiaryValidator, proveedor?.providerNumber));                
                setAcreedorObject(proveedor);     
                if (proveedor) { setRemoveState(true) }           
            })   
        }   
    }

    useEffect(() => {
        inputProvider(provider)
    }, [provider])

    useEffect(() => {
        if (orderPay){
            const benef = {
                cuit: orderPay.beneficiaries[0].persona.cuit, 
                name: orderPay.beneficiaries[0].persona.companyName, 
                beneficiaryValidator:orderPay.beneficiaries[0].persona.beneficiaryValidator
            };
            setAcreedorObject(benef)
            setMessajeValidacion(getMsjAFIP(benef?.beneficiaryValidator, benef?.providerNumber))             
            benef ? setValidarPersona(true) : setValidarPersona(false)
            benef?.beneficiaryValidator ? setMessajeValidacionColor('success') : setMessajeValidacionColor('warning')
            setAdmServiceId(orderPay.administrativeServiceId)
        } else {
            setAcreedorObject()
        }
    }, [])

    return <>
        <Form.Group as={Row}>
            <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                {inputName}
            </Form.Label>
            <Col sm='4'>
                <div className="input-group" >
                    <NumberFormat
                        className="form-control mr-2"
                        autocomplete="off"
                        type='text'
                        name='cuit'
                        placeholder='CUIT'
                        value={providerState?.cuit}
                        onChange={handleInputChange}
                        format="##-########-#"
                        mask="X"
                        pattern="\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]"
                        getInputRef={register(cuitValidationObj)}
                    />
                    <Form.Control
                        autocomplete="off"
                        type='text'
                        name='name'
                        value={providerState?.persona?.companyName}
                        placeholder='Denominación'
                        onChange={handleInputProveedor}
                        ref={register(denominationValidationObj)}
                    />
                </div>
            </Col>
            <Col sm={1}>
                <ActionIcon 
                    size='lg' 
                    toolTipText='Quitar' 
                    icon={faTimes} 
                    className={'text-danger mt-1' + (removeState ? '' : 'd-none')} 
                    onClick={clearBeneficiario} 
                />
            </Col>
            <Col sm={4}></Col>
            <Col sm={4}>
                <FormFieldError errors={errors?.cuit} />
                <FormFieldError errors={errors?.companyName} />
                {
                    validarPersona &&
                    <Alert variant={messajeValidacionColor} className='form-field-error mb-0 py-1 mt-1'>
                        {messajeValidacion}
                    </Alert>
                }
            </Col>
        </Form.Group>
    </>
}

export default InputCuitForm;